import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fade from "react-reveal/Fade";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MetaTags from "react-meta-tags";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  smartphone: {
    position: "absolute",
    width: "280px",
    height: "700px",
    left: "auto",
    right: "auto",
    top: "0%",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      right: "144px",
      top: "100px",
      width: "245px",
      height: "600px",
    },
  },
  video: {
    position: "absolute",
    left: "auto",
    right: "auto",
    top: "75px",
    width: "360px",
    height: "540px",
    [theme.breakpoints.up("md")]: {
      left: "auto",
      right: "158px",
      top: "145px",
      width: "215px",
      height: "500px",
    },
  },
  box: {
    height: "100vh",
  },
  resize: {
    align: "right",
  },
  smartphoneWrapper: {
    marginTop: "30px",
    marginBottom: "30px",
    position: "relative",
    display: "grid",
    placeItems: "center",
  },
  item2: {
    order: 3,
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 3,
    },
  },
  cardSize: {
    maxWidth: 340,
    height: 420,
  },
  cardContent: {
    height: 300,
  },
  media: {
    height: 180,
  },
  init: {},
}));

export default function Index() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <>
      <MetaTags>
        <title>UR Partner : Aplikasi Menu Restoran Secara Digital</title>
        <meta
          name="title"
          content="UR Partner : Aplikasi Menu Restoran Secara Digital"
        />
        <meta
          name="description"
          content="UR Partner membantu mengolah bisnis anda seperti menu digital, digital payment , report dan analisi."
        />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta
          property="og:title"
          content="UR Partner : Aplikasi Menu Restoran Secara Digital"
        />
        <meta
          property="og:description"
          content="UR Partner membantu mengolah bisnis anda seperti menu digital, digital payment , report dan analisi."
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta
          property="twitter:title"
          content="UR Partner : Aplikasi Menu Restoran Secara Digital"
        />
        <meta
          property="twitter:description"
          content="UR Partner membantu mengolah bisnis anda seperti menu digital, digital payment , report dan analisi."
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box pt={isDesktop ? 20 : 10} pb={30} className={classes.hero}>
        <Container>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={9} container spacing={3}>
              <Grid item xs={12} align="center">
                <Typography
                  variant={isDesktop ? "h1" : "h2"}
                  style={{
                    color: "#f5f5f5",
                    paddingBottom: "15px",
                    marginTop: isDesktop ? "0" : "25%",
                  }}
                >
                  UR Partner
                </Typography>
                <Typography
                  variant={isDesktop ? "h5" : "h6"}
                  color="textSecondary"
                  style={{ color: "#f5f5f5" }}
                >
                  Aplikasi yang Mendukung Kebutuhan Bisnis Anda.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    href="https://register.ur-hub.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Coba Gratis
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={3} className={!isDesktop ? classes.smartphoneWrapper : classes.init}>
              <img className={classes.smartphone} src="images/smartphone-partner.png" alt="Smartphone" />
              <video className={classes.video} autoPlay playsInline muted loop id="video">
                <source src="videos/ur-video-partner.mp4" type="video/mp4" />
              </video>
            </Grid> */}
          </Grid>
        </Container>
      </Box>

      {/* <Box py={10} mt={isDesktop ? 10 : 60}>
        <Container>
          <Fade>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12} md={4} align="center" >
                <Card variant="outlined" className={classes.cardSize}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Tahunan
                      </Typography>
                  </CardContent>
                  <CardActionArea className={classes.cardContent}>
                    <CardMedia
                      className={classes.media}
                      image="images/icon_shop.png"
                      title="Basic"
                    />
                    <CardContent>
                      <Typography noWrap variant="body2" color="textSecondary" component="p">
                        ̶ ̶R̶P̶ ̶3̶5̶0̶.̶0̶0̶0̶/̶b̶u̶l̶a̶n̶ ̶
                      </Typography>
                      <Typography gutterBottom variant="h5" component="h2">
                        RP 250.000/bulan
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Anda akan mendapatkan potongan khusus jika berlangganan 1 tahun.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="secondary" href="https://register.ur-hub.com" fullWidth>
                      Coba Gratis
                        </Button>
                  </CardActions>
                </Card>
              </Grid >
              <Grid item xs={12} md={4} align="center" >
                <Card variant="outlined" className={classes.cardSize}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Bulanan
                      </Typography>
                  </CardContent>
                  <CardActionArea className={classes.cardContent}>
                    <CardMedia
                      className={classes.media}
                      image="images/icon_shop_2.png"
                      title="Bulan"
                    />
                    <CardContent>
                      <Typography noWrap variant="body2" color="textSecondary" component="p">
                        ̶ ̶R̶P̶ ̶4̶0̶0̶.̶0̶0̶0̶/̶b̶u̶l̶a̶n̶ ̶
                      </Typography>
                      <Typography gutterBottom variant="h5" component="h2">
                        RP 300.000/bulan
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        ̶Kami juga menyediakan paket perbulan yang anda bisa manfaatkan kapan saja.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="secondary" href="https://register.ur-hub.com" fullWidth>
                      Coba Gratis
                        </Button>
                  </CardActions>
                </Card>
              </Grid >
            </Grid>
          </Fade>
        </Container>
      </Box> */}

      <Fade left>
        <Box py={10} mt={5}>
          <Container>
            <Grid container justify="center" spacing={4}>
              <Grid item align="center" xs={12} md={6}>
                <img
                  src="./asset/images/Logo/icon-ur-magenta-new.png"
                  width="35%"
                  alt="ur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom color="secondary">
                  Apa itu UR Partner?
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  UR Partner adalah salah satu produk UR yang memudahkan kamu
                  untuk mengatur transaksi di restoran atau cafe yang kamu
                  miliki seperti mengatur keuangan mudah, transaksi lebih cepat,
                  mengurangi antrian, dan yang paling penting membuat konsumen
                  senang.
                </Typography>
                <Button
                  style={{ marginTop: "10px" }}
                  variant="contained"
                  color="secondary"
                  href="https://register.ur-hub.com"
                  target="_blank"
                  rel="noreferrer"
                  fullWidth
                >
                  Coba Gratis
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Fade>

      <Box py={isDesktop ? 5 : 0}>
        <Container>
          <Fade>
            <Grid
              container
              justify="center"
              spacing={4}
              style={
                isDesktop
                  ? {}
                  : {
                      height: "fit-content",
                      marginTop: "100px",
                      marginBottom: "100px",
                    }
              }
            >
              <Grid Grid item xs={12}>
                <Typography
                  variant="h3"
                  gutterBottom
                  color="secondary"
                  align="center"
                >
                  Mengapa Harus&nbsp;
                  <img
                    src="./asset/images/Logo/logo-magenta-boxless-new.png"
                    width="60"
                    alt="ur"
                  />
                  ?
                </Typography>
              </Grid>
              <Grid item xs={8} md={4} align="center">
                <img
                  className={classes.img}
                  alt="icon"
                  src="./asset/images/icons/platform.webp"
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  color="secondary"
                  style={{ paddingTop: "20px" }}
                >
                  Multi Platform
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  UR Partner memiliki aplikasi untuk mengatur transaksi dan Web
                  untuk mengatur system dari restoran anda.
                </Typography>
              </Grid>
              <Grid item xs={8} md={4} align="center">
                <img
                  className={classes.img}
                  alt="icon"
                  src="./asset/images/icons/digital-menu.webp"
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  color="secondary"
                  style={{ paddingTop: "20px" }}
                >
                  Digital Menu
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  UR Partner mempermudah restoran untuk mengatur menu, menambah
                  menu dan stock secara digital.
                </Typography>
              </Grid>
              <Grid item xs={8} md={4} align="center">
                <img
                  className={classes.img}
                  alt="icon"
                  src="./asset/images/icons/service-calling.webp"
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  color="secondary"
                  style={{ paddingTop: "20px" }}
                >
                  Service Calling System
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  UR Partner dapat mengoptimasi waktu kinerja staf restoran dan
                  pelanggan.
                </Typography>
              </Grid>
              <Grid item xs={8} md={4} align="center">
                <img
                  className={classes.img}
                  alt="icon"
                  src="./asset/images/icons/digital-payment.webp"
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  color="secondary"
                  style={{ paddingTop: "20px" }}
                >
                  Digital Payment
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  UR partner dapat melakukan pembayaran dengan Digital Payment
                  untuk mempermudah era modern.
                </Typography>
              </Grid>
              <Grid item xs={8} md={4} align="center">
                <img
                  className={classes.img}
                  alt="icon"
                  src="./asset/images/icons/report-analysis.webp"
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  color="secondary"
                  style={{ paddingTop: "20px" }}
                >
                  Reports and Analysis
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Aplikasi UR dapat membuat laporan dan analisa yang informatif
                  dan mudah dipelajari untuk restoran anda.
                </Typography>
              </Grid>
              <Grid item xs={8} md={4} align="center">
                <img
                  className={classes.img}
                  alt="icon"
                  src="./asset/images/icons/incrase-sales.webp"
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  color="secondary"
                  style={{ paddingTop: "20px" }}
                >
                  Increases Sales
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Aplikasi UR akan meningkatkan jumlah pendapatan dan jumlah
                  pesanan di restoran anda.
                </Typography>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Box>

      <Box py={isDesktop ? 5 : 0}>
        <Container>
          <Fade left>
            <Grid
              container
              justify="center"
              alignItems="center"
              alignContent="center"
              spacing={3}
              className={classes.box}
              style={
                isDesktop
                  ? {}
                  : {
                      height: "600px",
                      // margin: "30px 0px"
                    }
              }
            >
              <Grid item xs={12}>
                <Typography
                  align="center"
                  justify="center"
                  variant="h3"
                  gutterBottom
                  color="secondary"
                >
                  <img
                    src="./asset/images/Logo/logo-magenta-boxless-new.png"
                    width="60"
                    alt="partner-logo"
                  />
                  &nbsp; Features
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} align={isDesktop ? "right" : "center"}>
                <img
                  src="./asset/images/features/fitur-terima-order-new.png"
                  alt="scan-qr"
                  width={isDesktop ? 600 : 300}
                />
              </Grid>
              <Grid item xs={12} md={6} align={isDesktop ? "left" : "center"}>
                <Typography variant="h2" gutterBottom color="secondary">
                  Pesanan Masuk
                </Typography>
                <Grid item xs={10}>
                  <Typography variant="subtitle1" gutterBottom>
                    Dengan fitur Pesanan Masuk kamu mendapatkan kemudahan dalam
                    menerima pesanan.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Box>

      <Box py={isDesktop ? 5 : 0}>
        <Container>
          <Fade right>
            <Grid
              container
              justify="center"
              alignItems="center"
              alignContent="center"
              className={classes.box}
              style={
                isDesktop
                  ? {}
                  : {
                      height: "600px",
                      // margin: "30px 0px"
                    }
              }
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sm={6}
                align={isDesktop ? "right" : "center"}
                className={classes.item2}
              >
                <Typography variant="h2" gutterBottom color="secondary">
                  Order History
                </Typography>
                <Grid
                  container
                  alignItems="flex-start"
                  justify="flex-end"
                  direction="row"
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Menampilkan semua orderan yang pernah di lakukan restoran /
                    café yang kamu untuk mempermudah kamu melihat transaksi.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sm={6}
                align={isDesktop ? "left" : "center"}
                className={classes.item3}
              >
                <img
                  src="./asset/images/features/order-history-new.png"
                  alt="scan-qr"
                  width={isDesktop ? 600 : 300}
                />
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Box>

      <Box py={isDesktop ? 5 : 0}>
        <Container>
          <Fade left>
            <Grid
              container
              justify="center"
              alignItems="center"
              alignContent="center"
              className={isDesktop && classes.box}
              // style={isDesktop ? {} : {
              //   height: '300px'
              // }}
              spacing={2}
            >
              <Grid item xs={12} md={6} align={isDesktop ? "right" : "center"}>
                <video
                  width={isDesktop ? 600 : 300}
                  style={{ borderRadius: "35px" }}
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src="./asset/images/features/fitur-cetak-new.mp4"
                    type="video/mp4"
                  />
                </video>
                {/* <video src="./asset/images/features/fitur-cetak-new.mp4" alt="scan-qr" width={isDesktop ? 600 : 300} autoplay="true" loop="true" /> */}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                align={isDesktop ? "left" : "center"}
                style={
                  isDesktop
                    ? {}
                    : {
                        height: "300px",
                      }
                }
              >
                <Typography variant="h2" gutterBottom color="secondary">
                  Cetak Bill
                </Typography>
                <Grid item xs={10}>
                  <Typography variant="subtitle1" gutterBottom>
                    UR mendukung fitur cetak bill yang mana kita tidak perlu
                    tambahan aplikasi lain.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Box>
    </>
  );
}
