import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  box: {
    height: "100vh",
  },
}));

export default function Index() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  document.title = "UR | 404";

  return (
    <div>
      <Box className={classes.hero} pt={5}>
        <Container>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={9} container spacing={3}>
              <Grid item xs={12} align="center">
                <Link href="/">
                  <a>
                    <img
                      src="https://ik.imagekit.io/urhub/assets/404-not-found.png"
                      alt="404"
                      width="50%"
                      className={classes.image}
                    />
                  </a>
                </Link>
                <Typography
                  variant={isDesktop ? "h5" : "h6"}
                  style={{ paddingBottom: "15px" }}
                >
                  Maaf, Tujuanmu tidak ada.
                </Typography>
                {/* <Typography variant="subtitle1" style={{ paddingBottom: '15px' }}>Mungkin kamu salah alamat, Yuk balik lagi!</Typography> */}
                <Link href="/" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="primary">
                    <div style={{ color: "white" }}>Kembali</div>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}
