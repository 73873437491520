import React, { useEffect, useState } from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MetaTags from "react-meta-tags";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import ReactHtmlParser from "react-html-parser";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import ReactS3 from "react-s3";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getCareerSlug } from "../../action/career";
import { postApplicantToAdmin, sendEmailApplicant } from "../../action/mailing";
import { useLocation } from "react-router-dom";
import "filepond/dist/filepond.min.css";
var randomstring = require("randomstring");

registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFileRename
);

const config = {
  bucketName: "ur-hub",
  dirName: "assets/applicant",
  region: "us-west-2",
  accessKeyId: "AKIAI2XCN446J7DEJMNA",
  secretAccessKey: "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",
};

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
  tableMargin: {
    paddingTop: "10px",
    paddingRight: "120px",
    paddingBottom: "10px",
    paddingLeft: "120px",
    textAlign: "left",
  },
}));

const initialFileState = {
  file: [
    {
      name: "",
    },
  ],
};

export default function Karir() {
  const classes = useStyles();
  const [dataCareer, setDataCareer] = useState({});
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [noTlp, setNoTlp] = useState("");
  const [perusahaan, setPerusahaan] = useState("");
  const [active, setActive] = useState(true);
  const { register, handleSubmit, errors, setError, clearError } = useForm();
  const [filesCV, setFilesCV] = useState(initialFileState);
  const [filesCL, setFilesCL] = useState(initialFileState);
  const [namaActive, setNamaActive] = useState(true);
  const [emailActive, setEmailActive] = useState(true);
  const [noActive, setNoActive] = useState(true);

  const [open, setOpen] = useState(false);
  const [openTime, setOpenTime] = useState(false);

  const location = useLocation();
  const str = location.pathname;
  const slug = str.slice(7);
  // console.log(slug)

  useEffect(() => {
    getCareerSlug(slug).then((result) => {
      if (result.success === 1) {
        setDataCareer(result.careers);
        // console.log(result)
      } else {
        const timer = setTimeout(() => {
          // setIsLoading(false)
          // setNoData(true)
        }, 2000);
        return () => clearTimeout(timer);
      }
    });
  }, [slug]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseTime = () => {
    setOpenTime(false);
  };

  const onSubmit = (data, e) => {
    var formData = new FormData();
    formData.append("name", nama);
    formData.append("email", email);
    formData.append("phone", noTlp);
    formData.append("current_company", perusahaan);
    formData.append("cv", filesCV.file[0].name);
    formData.append("cover_letter", filesCL.file[0].name);
    formData.append("career_id", dataCareer.id);
    formData.append("career_title", dataCareer.title);
    formData.append("career_category", dataCareer.categories);

    setOpenTime(true);

    // console..log(filesCV.file[0].name)

    postApplicantToAdmin(formData).then((result) => {
      if (result.success === 1) {
        // console.log(result)
        setOpenTime(false);

        ReactS3.uploadFile(filesCL.file[0], config).catch((err) => {
          alert(err);
        });

        ReactS3.uploadFile(filesCV.file[0], config).catch((err) => {
          alert(err);
        });

        sendEmailApplicant(formData).then((result) => {
          // console.log(result)

          setOpen(true);
        });
      } else {
        alert(data.msg);
      }
    });

    e.target.reset();
    setNamaActive(true);
    setNoActive(true);
    setEmailActive(true);
    setActive(true);
  };

  // console.log(dataCareer.title)
  const handleEnableButton = (namaActive, emailActive, noActive) => {
    if (namaActive === false && emailActive === false && noActive === false) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  return (
    <>
      <MetaTags>
        <title> {dataCareer.title} | UR - Self Order & POS App</title>
      </MetaTags>
      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                {dataCareer.title}
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="https://ik.imagekit.io/urhub/assets/misc/join-us.png"
                  alt="promo"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box pt={15}>
          <Grid container justify="center" className={classes.box}>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                Responsibilities:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {ReactHtmlParser(dataCareer.description || "Loading..")}
              </Typography>
              <Typography variant="h6" gutterBottom>
                What do we need from you :
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {ReactHtmlParser(dataCareer.requirement || "Loading..")}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4">Submit Your Application</Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputRef={register({ required: "This is required" })}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      clearError("name");
                      setNama(value);
                      setNamaActive(false);
                      handleEnableButton(false, emailActive, noActive);
                    } else {
                      setError("name", "required", "this field can be empty");
                      setNamaActive(true);
                      handleEnableButton(false, emailActive, noActive);
                    }
                  }}
                  label="Nama Lengkap"
                  name="name"
                />
                {errors.name && (
                  <small className="text-danger">{errors.name.message}</small>
                )}
                <TextField
                  variant="outlined"
                  fullWidth
                  inputRef={register({ required: "This is required" })}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.match(/^[0]+\d{9,11}$/)) {
                      clearError("phone");
                      setNoTlp(value);
                      setNoActive(false);
                      handleEnableButton(namaActive, emailActive, false);
                    } else if (!value) {
                      setNoTlp(value);
                      setNoActive(false);
                      handleEnableButton(namaActive, emailActive, false);
                    } else {
                      setError("phone", "required", "Invalid phone format");
                      setNoActive(true);
                      handleEnableButton(namaActive, emailActive, true);
                    }
                  }}
                  label="No. Telp"
                  name="phone"
                  margin="normal"
                />
                {errors.phone && (
                  <small className="text-danger">{errors.phone.message}</small>
                )}
                <TextField
                  variant="outlined"
                  fullWidth
                  inputRef={register({ required: "This is required" })}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                      clearError("email");
                      setEmail(value);
                      setEmailActive(false);
                      handleEnableButton(namaActive, false, noActive);
                    } else {
                      setError("email", "required", "Invalid email format");
                      setEmailActive(false);
                      handleEnableButton(namaActive, false, noActive);
                    }
                  }}
                  label="E-Mail"
                  name="email"
                  margin="normal"
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => {
                    const value = e.target.value;

                    setPerusahaan(value);
                  }}
                  label="Perusahaan Saat Ini"
                  name="perusahaan"
                />

                <Typography variant="subtitle2" color="textSecondary">
                  Curriculum Vitae
                </Typography>
                <FilePond
                  allowReplace
                  checkValidity
                  maxFiles={1}
                  allowFileSizeValidation
                  allowFileTypeValidation
                  maxFileSize="500KB"
                  labelMaxFileSizeExceeded="File is too large"
                  acceptedFileTypes={[
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ]}
                  labelFileTypeNotAllowed="File of invalid type"
                  required={true}
                  fileRenameFunction={(file) => {
                    return `${"CV_" + randomstring.generate()}${
                      file.extension
                    }`;
                  }}
                  onupdatefiles={(fileItems) => {
                    setFilesCV({
                      file: fileItems.map((fileItem) => fileItem.file),
                    });
                  }}
                />
                <small>
                  *File berupa docx atau pdf dan Size di bawah 500KB*
                </small>
                <br />

                <Typography variant="subtitle2" color="textSecondary">
                  Cover Letter atau Surat Pengantar
                </Typography>

                <FilePond
                  allowReplace
                  checkValidity
                  maxFiles={1}
                  allowFileSizeValidation
                  allowFileTypeValidation
                  maxFileSize="500KB"
                  labelMaxFileSizeExceeded="File is too large"
                  acceptedFileTypes={[
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ]}
                  labelFileTypeNotAllowed="File of invalid type"
                  required={true}
                  fileRenameFunction={(file) => {
                    return `${"CL_" + randomstring.generate()}${
                      file.extension
                    }`;
                  }}
                  onupdatefiles={(fileItems) => {
                    // console.log("SAD")

                    setFilesCL({
                      file: fileItems.map((fileItem) => fileItem.file),
                    });
                  }}
                />
                <small>
                  *File berupa docx atau pdf dan Size di bawah 500KB*
                </small>
                <br />
                <br />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon style={{ color: "white" }} />}
                  disabled={active}
                >
                  <div style={{ color: "white" }}>Kirim</div>
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openTime}
          onClose={handleCloseTime}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Mohon menunggu Data sedang di kirim...
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Terima kasih telah mengirim lamaran kepada kami. Kami akan
              menghubung lewat e-mail.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
