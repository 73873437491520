import React from "react";
import {
  makeStyles,
  useTheme,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MetaTags from "react-meta-tags";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "250px",
    zIndex: "1",
  },

  card_active: {
    backgroundColor: theme.palette.secondary.main,
    color: "#f5f5f5",
  },
  scrollBox: {
    height: "455px",
    overflow: "scroll",
  },
  avatar_active: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
  },
  avatar: {
    // color: theme.palette.text.secondary,
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
  },
}));

function PusatBantuanPartner() {
  const classes = useStyles();
  const theme = useTheme();

  const [currentProsesPesanan, setCurrentProsesPesanan] = React.useState(0);
  const [currentSelesaiPesanan, setCurrentSelesaiPesanan] = React.useState(0);
  const [currentBatalOrder, setCurrentBatalOrder] = React.useState(0);
  const [currentHistoryPesanan, setCurrentHistoryPesanan] = React.useState(0);
  const [currentMelihatPesanan, setCurrentMelihatPesanan] = React.useState(0);
  const [currentAntriPesanan, setCurrentAntriPesanan] = React.useState(0);
  const [currentTambahMenu, setCurrentTambahMenu] = React.useState(0);
  const [currentKurangMenu, setCurrentKurangMenu] = React.useState(0);
  const [currentUpdateMenu, setCurrentUpdateMenu] = React.useState(0);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const prosesPesanan = [
    {
      text: 'Klik tab "Pesanan" pada halaman aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-1.png",
    },
    {
      text: "Klik pesanan yang akan diterima.",
      image: "../../asset/images/PusatBantuanPartner/order-3.png",
    },
    {
      text: 'Klik tombol "Terima Pembayaran".',
      image: "../../asset/images/PusatBantuanPartner/order-4.png",
    },
    {
      text: 'Setelah memilih "Terima Pembayaran" maka akan keluar pesan bahwa pesanan telah di proses.',
      image: "../../asset/images/PusatBantuanPartner/order-5.png",
    },
  ];

  const selesaiPesanan = [
    {
      text: 'Klik tab "Pesanan" pada halaman aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-2.png",
    },
    {
      text: "Klik pesanan yang akan diterima.",
      image: "../../asset/images/PusatBantuanPartner/order-3.png",
    },
    {
      text: 'Klik tombol "Proses Selesai".',
      image: "../../asset/images/PusatBantuanPartner/order-6.png",
    },
    {
      text: 'Setelah memilih "Pesanan Selesai" maka sistem akan menampilkan pemberitahuan bahwa pesanan telah selesai.',
      image: "../../asset/images/PusatBantuanPartner/order-7.png",
    },
  ];

  const batalOrder = [
    {
      text: 'Klik tab "Pesanan" pada halaman aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-2.png",
    },
    {
      text: "Klik pesenan yang akan diterima.",
      image: "../../asset/images/PusatBantuanPartner/order-3.png",
    },
    {
      text: 'Klik tombol "Batalkan Pesanan" .',
      image: "../../asset/images/PusatBantuanPartner/order-8.png",
    },
    {
      text: 'Setelah memilih "Batalkan Pesanan" maka sistem akan menampilkan pemberitahuan bahwa pesanan telah di batalkan.',
      image: "../../asset/images/PusatBantuanPartner/order-15.png",
    },
  ];

  const historyPesanan = [
    // {
    //   'text': 'Klik tombol Stock pada halaman utama aplikasi.',
    //     'image': '../../asset/images/PusatBantuanPartner/order-9.png',
    // },
    {
      text: 'Klik tombol "Riwayat Pesanan" pada halaman Stock.',
      image: "../../asset/images/PusatBantuanPartner/order-10.png",
    },
    {
      text: 'Selamat "Riwayat pemesanan" berhasil ditampilkan.',
      image: "../../asset/images/PusatBantuanPartner/order-11.png",
    },
  ];

  const melihatPesanan = [
    {
      text: 'Klik tab "Pesanan" pada halaman aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-1.png",
    },
    {
      text: "Pilih pesanan yang ingin diketahui nama, nomor telepon pemesan serta nomor meja pada pesan yang masuk.",
      image: "../../asset/images/PusatBantuanPartner/order-3.png",
    },
    {
      text: "Sistem akan menampilkan nama, nomor telepon pemesan serta nomor meja pada pesan yang masuk.",
      image: "../../asset/images/PusatBantuanPartner/order-13.png",
    },
  ];

  const antriPesanan = [
    {
      text: 'Klik tab "Pesanan" pada halaman aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-1.png",
    },
    {
      text: "Pilih pesanan yang ingin diketahui nama, nomor telepon pemesan serta nomor meja pada pesan yang masuk.",
      image: "../../asset/images/PusatBantuanPartner/order-3.png",
    },
    {
      text: "Sistem akan menampilkan nama pemesan serta nomor antrian pada order yang masuk.",
      image: "../../asset/images/PusatBantuanPartner/order-14.png",
    },
  ];

  const tambahMenu = [
    {
      text: 'Klik tombol "Menu" pada halaman utama aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-9.png",
    },
    {
      text: 'Klik tombol Add Menu pada halaman "Menu".',
      image: "../../asset/images/PusatBantuanPartner/menu-1.png",
    },
    {
      text: "Masukan nama, harga menu, deskripsi, kategori (wajib) serta dapat memasukan gambar menu (opsional).",
      image: "../../asset/images/PusatBantuanPartner/menu-2.png",
    },
    {
      text: "Klik Add untuk menyimpan menu ke restoran.",
      image: "../../asset/images/PusatBantuanPartner/menu-3.png",
    },
  ];

  const kurangMenu = [
    {
      text: 'Klik tombol "Menu" pada halaman utama aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-9.png",
    },
    {
      text: "Pilih Menu yang akan di hapus.",
      image: "../../asset/images/PusatBantuanPartner/menu-4.png",
    },
    {
      text: "Klik tombol Delete Menu pada halaman Stock.",
      image: "../../asset/images/PusatBantuanPartner/menu-5.png",
    },
    {
      text: "Setelah memilih Delete Menu maka sistem akan menampilkan pemberitahuan bahwa menu telah dihapus.",
      image: "../../asset/images/PusatBantuanPartner/menu-6.png",
    },
  ];

  const updateMenu = [
    {
      text: 'Klik tombol "Menu" pada halaman utama aplikasi.',
      image: "../../asset/images/PusatBantuanPartner/order-9.png",
    },
    {
      text: "Pilih Menu yang akan ditambahkan Stock",
      image: "../../asset/images/PusatBantuanPartner/menu-4.png",
    },
    {
      text: "Tekan tombol Plus (+) atau Minus (-) untuk menambahkan atau mengurangi stock menu. Anda juga dapat menekan jumlah stock untuk memasukan stok secara manual.",
      image: "../../asset/images/PusatBantuanPartner/menu-9.png",
    },
    {
      text: "Tekan tombol Update untuk menyimpan stock yang telah diubah.",
      image: "../../asset/images/PusatBantuanPartner/menu-10.png",
    },
    {
      text: "Setelah memilih tombol Update maka sistem akan menampilkan pemberitahuan bahwa stok menu telah di update.",
      image: "../../asset/images/PusatBantuanPartner/menu-11.png",
    },
  ];
  // document.title = "Pusat Bantuan Partner | UR - Self Order & POS App"
  return (
    <div>
      <MetaTags>
        <title>Pusat Bantuan Partner | UR - Self Order & POS App</title>
        <meta name="title" content="Pusat Bantuan Partner" />
        <meta name="description" content="Males antri? Kami solusinya" />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Pusat Bantuan Partner" />
        <meta property="og:description" content="Males antri? Kami solusinya" />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Pusat Bantuan Partner" />
        <meta
          property="twitter:description"
          content="Males antri? Kami solusinya"
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>
      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Pusat Bantuan Partner
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/pb_partner.png"
                  alt="icon partner ur"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Menerima Pembayaran
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              {/* <img className={classes.imgGuide} src={prosesPesanan[currentProsesPesanan].image} alt={prosesPesanan[currentProsesPesanan].text} width={isDesktop? 600 : 600} /> */}
              <img
                className={classes.imgGuide}
                src={prosesPesanan[currentProsesPesanan].image}
                alt={prosesPesanan[currentProsesPesanan].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {prosesPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentProsesPesanan === index
                          ? classes.card_active
                          : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentProsesPesanan(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentProsesPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Menyelesaikan Pesanan
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={selesaiPesanan[currentSelesaiPesanan].image}
                alt={selesaiPesanan[currentSelesaiPesanan].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {selesaiPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentSelesaiPesanan === index
                          ? classes.card_active
                          : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentSelesaiPesanan(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentSelesaiPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Membatalkan Pesanan
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={batalOrder[currentBatalOrder].image}
                alt={batalOrder[currentBatalOrder].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {batalOrder.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentBatalOrder === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentBatalOrder(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentBatalOrder === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Melihat Pesanan History Aplikasi UR Partner
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={historyPesanan[currentHistoryPesanan].image}
                alt={historyPesanan[currentHistoryPesanan].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {historyPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentHistoryPesanan === index
                          ? classes.card_active
                          : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentHistoryPesanan(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentHistoryPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Melihat Nama, Nomor Telepon dan Meja Pemesan pada Aplikasi
                UR Partner
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={melihatPesanan[currentMelihatPesanan].image}
                alt={melihatPesanan[currentMelihatPesanan].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {melihatPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentMelihatPesanan === index
                          ? classes.card_active
                          : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentMelihatPesanan(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentMelihatPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Melihat Nama dan Nomor Antrian pada Aplikasi UR Partner
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={antriPesanan[currentAntriPesanan].image}
                alt={antriPesanan[currentAntriPesanan].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {antriPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentAntriPesanan === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentAntriPesanan(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentAntriPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Menambahkan Item Menu ke Aplikasi UR Partner pada Aplikasi
                UR Partner
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={tambahMenu[currentTambahMenu].image}
                alt={tambahMenu[currentTambahMenu].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {tambahMenu.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentTambahMenu === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentTambahMenu(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentTambahMenu === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Menghapus Item Menu ke Aplikasi UR Partner
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={kurangMenu[currentKurangMenu].image}
                alt={kurangMenu[currentKurangMenu].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {kurangMenu.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentKurangMenu === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentKurangMenu(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentKurangMenu === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container style={{ marginBottom: 70 }}>
        <Box pt={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="secondary"
                gutterBottom
                align="center"
              >
                Cara Update Stock Menu ke Aplikasi UR Partner
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={updateMenu[currentUpdateMenu].image}
                alt={updateMenu[currentUpdateMenu].text}
                width={isDesktop ? 600 : 300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {/* <div className={classes.scrollBox}> */}
              {updateMenu.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentUpdateMenu === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentUpdateMenu(index)}
                      >
                        <CardContent>
                          <Grid alignItems="center" container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentUpdateMenu === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
              {/* </div> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default PusatBantuanPartner;
