import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Link } from "react-router-dom";
import RegisterCTA from "../../../components/buttons/RegisterCTA";
import SalesCTA from "../../../components/buttons/SalesCTA";

const useStyles = makeStyles((props) => ({
  hero: {
    display: "flex",
    objectFit: "contain",
    alignItems: "left",
    backgroundImage: `url(./asset/images/banner/ending.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    margin: "none",
  },
  cta: {
    color: "white",
    backgroundColor: "#de148c",
    fontWeight: "bold",
    borderColor: "#de148c",
    borderRadius: "30px",
    border: "3px solid",
    "&:hover": {
      color: "white",
      backgroundColor: "#de148c",
      fontWeight: "bold",
      borderColor: "#de148c",
      border: "3px solid",
      borderRadius: "30px",
    },
  },
}));

export default function Hero(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <>
      <Box className={classes.hero} style={{ height: "100vh" }}>
        <div style={isDesktop ? { marginLeft: "5%" } : {}}>
          <Grid
            container
            alignItems={isDesktop ? "left" : "center"}
            justify={isDesktop ? "flex-start" : "center"}
            direction="column"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                align={!isDesktop ? 'center' : 'left'}
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  marginTop: isDesktop ? "15%" : "35%",
                  fontWeight: "600",
                }}
              >
                POS&emsp;&
              </Typography>
              <div style={{ marginTop: "2.5vh" }}></div>
              <Typography
                align={!isDesktop ? 'center' : 'left'}
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                }}
              >
                Self Order
              </Typography>
              <div style={{ marginTop: "2.5vh" }}></div>
              <Typography
                align={!isDesktop ? 'center' : 'left'}
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: isDesktop ? 24 : 22,
                  // marginTop: "50px",
                  margin: !isDesktop ? '0px 5%' : ''
                }}
              >
                Aplikasi POS & Self Order terlengkap yang membuat bisnismu mudah
                dipantau. <br />
                Ekosistem UR membuat kamu dan pelanggan menjadi akrab
              </Typography>
            </Grid>

            <Grid
              container
              xs={12}
              alignItems={isDesktop ? "left" : "center"}
              justify={isDesktop ? "flex-start" : "center"}
              direction="column"
              spacing={0}
              style={{
                marginTop: "5vh",
              }}
            >
              <Grid item xs={12} md={12}>
                <RegisterCTA width="35vh" height="6vh" fontSize="18" />
                {/* <p>test</p> */}
                {isDesktop ? " " : <div style={{ marginTop: "2.5vh" }}></div>}
                <SalesCTA width="35vh" height="6vh" fontSize="18" />
              </Grid>
              {/* </div> */}
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}
