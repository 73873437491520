import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import NavigationGeneral from './NavigationComponent/NavigationGeneral'
// import NavigationMobile from './NavigationMobile'
import useMediaQuery from '@material-ui/core/useMediaQuery'





function Navigation() {

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md', 'lg'), {
    defaultMatches: true
  })

  // if (isDesktop === true) {
  return <NavigationGeneral />
  // } else {
  // console.log("mobile")
  // return <NavigationMobile />
  // }


}

export default Navigation