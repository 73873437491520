import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RegisterCTA from "../../../components/buttons/RegisterCTA";
import RegularButton from "../../../components/buttons/RegularButton";
import * as React from "react";
const useStyles = makeStyles((props) => ({
  hero: {
    // backgroundImage: `url(./asset/images/banner/selfOrderBg.jpg)`,
    backgroundColor: "#E8E8E8",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    margin: "none",
  },
  learnMore: {
    color: "black",
    fontWeight: "bold",
    borderRadius: "15px",
    "&:hover": {
      color: "black",
      backgroundColor: "#de148c",
      fontWeight: "bold",
      borderRadius: "15px",
    },
  },
}));

export default function OwnerApp(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <>
      <Box
        className={classes.hero}
        style={{
          height: isDesktop ? "100vh" : "100%",
        }}
      >
        <Grid
          container
          alignItems={isDesktop ? "left" : "center"}
          justify={isDesktop ? "flex-start" : "center"}
          spacing={2}
          direction={isDesktop ? "row" : "column-reverse"}
        >
          <Grid item xs={12} md={8}>
            <div
              style={{
                marginLeft: isDesktop ? "5%" : "0",
              }}
            >
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#000000",
                  marginTop: isDesktop ? "15%" : "35%",
                  fontWeight: "600",
                  textAlign: isDesktop ? "left" : "center",
                }}
              >
                Aplikasi Owner
              </Typography>
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: isDesktop ? 36 : 32,
                  textAlign: isDesktop ? "left" : "center",
                }}
              >
                Terima laporan dan kelola bisnis kamu dalam satu genggaman.
                Dimana saja, kapan saja.
              </Typography>
            </div>
            <div style={{ marginTop: "30%" }}></div>
            <Grid
              container
              xs={12}
              alignItems={isDesktop ? "left" : "center"}
              justify={isDesktop ? "flex-start" : "center"}
              direction={isDesktop ? "row" : "column"}
              spacing={1}
              style={{
                marginTop: "20vh",
                marginLeft: isDesktop ? "5%" : "0",
              }}
            >
              <Grid item>
                <RegisterCTA width="35vh" height="6vh" fontSize="18" />
              </Grid>
              <Grid item>
                <Button
                  style={{
                    height: "6vh",
                    padding: props.padding ?? "30px",
                  }}
                  className={classes.learnMore}
                  href="/partner-features"
                >
                  <div
                    style={{
                      padding: "15px",
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "white",
                    }}
                  >
                    Pelajari Lebih Lanjut
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src="./asset/images/banner/urOwner.png"
              height={600}
              style={{ marginTop: "15%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
