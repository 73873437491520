import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import RegisterCTA from "../../buttons/RegisterCTA";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  appBarTransparent: {
    backgroundColor: "rgba(0, 0, 0,0.2)",
  },
  appBarSolid: {
    backgroundColor: "rgba(0, 0, 0,0.6)",
  },
  root: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(2),
    color: "white",
    fontWeight: "bold",
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
  },

  logo: {
    maxWidth: 45,
    margin: theme.spacing(2),
    textAlign: "center",
  },
  toolbar: {
    display: "flex",
  },
  paper: {
    flexGrow: 1,
    backgroundColor: "white",
    opacity: "0.95",
  },
  paper_secondary: {
    backgroundColor: theme.palette.secondary.main,
  },

  cta: {
    color: "white",
    backgroundColor: "#de148c",
    fontWeight: "bold",
    borderRadius: "25px",
    "&:hover": {
      color: "white",
      backgroundColor: "#de148c",
      fontWeight: "bold",
      borderRadius: "25px",
    },
  },
}));

function Navigation() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [openGabung, setOpenGabung] = useState(false);
  const [ChangeColor, setChangeColor] = useState(classes.paper);
  const anchorRef = useRef(null);
  const anchorRefMore = useRef(null);
  const anchorRefGabung = useRef(null);
  const theme = useTheme();
  const [navBackground, setNavBackground] = useState("appBarTransparent");
  const navRef = React.useRef();
  navRef.current = navBackground;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md", "lg"), {
    defaultMatches: true,
  });
  useEffect(() => {
    setOpenNav(isDesktop ? true : false);
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (show) {
        setNavBackground("appBarSolid");
      } else {
        setNavBackground("appBarTransparent");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isDesktop]);
  // useEffect(() => {
  //     // console.log(location.pathname);
  //     switch (location.pathname) {
  //         case '/pusat-bantuan/partner': return setChangeColor(classes.paper_secondary)
  //         case '/partner': return setChangeColor(classes.paper_secondary)
  //         // case '/agent': return setChangeColor(classes.paper_third)
  //         default:
  //             return setChangeColor(classes.paper)
  //     }
  // }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // useEffect(() => {
  //     setOpenNav(isDesktop ? true : false)
  // }, [isDesktop])

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggleGabung = () => {
    setOpenGabung((prevOpenGabung) => !prevOpenGabung);
  };

  const handleCloseGabung = (event) => {
    if (
      anchorRefGabung.current &&
      anchorRefGabung.current.contains(event.target)
    ) {
      return;
    }

    setOpenGabung(false);
  };

  function handleListKeyDownGabung(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenGabung(false);
    }
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes[navRef.current]} elevation={0}>
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6} md={1}>
              <Link style={{ textDecoration: "none" }} to="/">
                <a href="/">
                  <img
                    src="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
                    className={classes.logo}
                    alt="logo"
                  />
                </a>
              </Link>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={6} align="right">
                <IconButton
                  aria-label="delete"
                  style={{ color: "white" }}
                  onClick={() => setOpenNav(!openNav)}
                >
                  {openNav ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={9} style={{ textAlign: "center" }}>
              <Collapse in={openNav}>
                <nav>
                  {/* <Button variant="text" className={classes.link} href="/">
                                        Beranda
                                    </Button> */}
                  <Button
                    variant="text"
                    className={classes.link}
                    href="/pricing"
                  >
                    Harga
                  </Button>
                  <Button variant="text" className={classes.link} href="/blog">
                    Blog
                  </Button>

                  <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.link}
                  >
                    Pusat Bantuan <ArrowDropDownIcon fontSize="small" />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 2000 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper className={ChangeColor}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                              color="secondary"
                            >
                              <Link
                                style={{ textDecoration: "none" }}
                                to="/pusat-bantuan/user"
                              >
                                <MenuItem
                                  style={{ color: "#333332" }}
                                  onClick={handleClose}
                                >
                                  Pengguna
                                </MenuItem>
                              </Link>
                              <Link
                                style={{ textDecoration: "none" }}
                                to="/pusat-bantuan/partner"
                              >
                                <MenuItem
                                  style={{ color: "#333332" }}
                                  onClick={handleClose}
                                >
                                  Partner
                                </MenuItem>
                              </Link>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <Button
                    ref={anchorRefGabung}
                    aria-controls={openGabung ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleGabung}
                    className={classes.link}
                  >
                    Gabung <ArrowDropDownIcon fontSize="small" />
                  </Button>
                  <Popper
                    open={openGabung}
                    anchorEl={anchorRefGabung.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 2000 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper className={ChangeColor}>
                          <ClickAwayListener onClickAway={handleCloseGabung}>
                            <MenuList
                              autoFocusItem={openGabung}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDownGabung}
                            >
                              <Link
                                style={{ textDecoration: "none" }}
                                to="/partner"
                              >
                                <MenuItem
                                  style={{ color: "#333332" }}
                                  onClick={handleCloseGabung}
                                >
                                  Partner
                                </MenuItem>
                              </Link>
                              {/* <Link style={{ textDecoration: "none" }} to="/agent">
                                                            <MenuItem style={{ color: '#F5F5F5' }} onClick={handleCloseGabung}>Agent</MenuItem>
                                                        </Link> */}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <Button
                    variant="text"
                    className={classes.link}
                    href="/contact"
                  >
                    Kontak
                  </Button>

                  {/* <Button
                                    ref={anchorRefMore}
                                    aria-controls={openMore ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggleMore}
                                    className={classes.link}
                                >
                                    Lainnya <ArrowDropDownIcon fontSize="small" />
                                </Button>
                                <Popper open={openMore} anchorEl={anchorRefMore.current} role={undefined} transition disablePortal style={{ zIndex: 2000 }}>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper className={ChangeColor}>
                                                <ClickAwayListener onClickAway={handleCloseMore}>
                                                    <MenuList autoFocusItem={openMore} id="menu-list-grow" onKeyDown={handleListKeyDownMore}>
                                                        <Link style={{ textDecoration: "none" }} to="/karir">
                                                            <MenuItem style={{ color: '#F5F5F5' }} onClick={handleCloseMore}>Karir</MenuItem>
                                                        </Link>
                                                        <Link style={{ textDecoration: "none" }} to="/kebijakan-privasi">
                                                            <MenuItem style={{ color: '#F5F5F5' }} onClick={handleCloseMore}>Kebijakan Privasi</MenuItem>
                                                        </Link>
                                                        <Link style={{ textDecoration: "none" }} to="/syarat-dan-ketentuan">
                                                            <MenuItem style={{ color: '#F5F5F5' }} onClick={handleCloseMore}>Syarat dan Ketentuan</MenuItem>
                                                        </Link>
                                                        <Link style={{ textDecoration: "none" }} to="/faqs">
                                                            <MenuItem style={{ color: '#F5F5F5' }} onClick={handleCloseMore}>FAQs</MenuItem>
                                                        </Link>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper> */}
                </nav>
              </Collapse>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
                <RegisterCTA padding="5px" navBar="true" />
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navigation;
