import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
}));

function SyaratDanKetentuanPartner() {
  const classes = useStyles();
  const [openDataIntegrity, setOpenDataIntegrity] = React.useState(false);
  const [openPrivacyPoilcy, setOpenPrivacyPoilcy] = React.useState(false);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [openChoiceOfLaw, setOpenChoiceOfLaw] = React.useState(false);
  const [tnc5, setTnc5] = React.useState(false);
  const [tnc6, setTnc6] = React.useState(false);
  const [tnc7, setTnc7] = React.useState(false);
  const [tnc8, setTnc8] = React.useState(false);

  return (
    <>
      <MetaTags>
        <title>Syarat dan Ketentuan Partner | UR - Self Order & POS App</title>
        <meta name="title" content="Syarat dan Ketentuan Partner" />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Syarat dan Ketentuan Partner" />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Syarat dan Ketentuan Partner" />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Syarat dan Ketentuan Partner
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/tc.png"
                  alt="tc"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box pt={15}>
          <Grid container justify="center" className={classes.box}>
            <Grid item xs={10}>
              {/* <Typography variant="subtitle1" gutterBottom>
                This website is own and managed by PT. Rahmat Tuhan Lestari, and
                this terms and conditions (“Terms”, “Agreement”) apply to
                visitors and users of UR The term “us”, “we” or “our”) refers to
                UR, and the term “User”, “you” or “your” refers to the user who
                accept this Terms and Conditions. The use of this website shall
                indicate your acceptance to these conditions, do not use this
                site if you do not agree to be bound by these terms.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                If you are below 18 years old: you must obtain consent from your
                parent(s) or legal guardian(s), their acceptance of these Terms
                and Conditions and their agreement to take responsibility for:
                your actions, any charges associated with your use of any of the
                Services or purchase of Products; and your acceptance and
                compliance with these Terms of Use. If you do not have consent
                from your parent(s) or legal guardian(s), you must stop
                using/accessing this website and using the Servicesx
              </Typography> */}

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenDataIntegrity(!openDataIntegrity)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Definisi" />
                  {openDataIntegrity ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openDataIntegrity} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">
                            PT. RAHMAT TUHAN LESTARI adalah suatu perseroan
                            terbatas yang menjalankan kegiatan usaha jasa web
                            portal{" "}
                            <a class="webUR" href="https://ur-hub.com/">
                              www.ur-hub.com
                            </a>{" "}
                            dan Aplikasi UR POS AND SELF ORDER, yakni situs dan
                            aplikasi pencarian toko dan Barang yang dijual oleh
                            penjual terdaftar. Selanjutnya disebut UR.
                          </li>
                          <li class="subJudul1">
                            Situs UR adalah{" "}
                            <a class="webUR" href="https://ur-hub.com/">
                              www.ur-hub.com
                            </a>
                            .
                          </li>
                          <li class="subJudul1">
                            Syarat & ketentuan adalah perjanjian antara Pengguna
                            dan UR yang berisikan seperangkat peraturan yang
                            mengatur hak kewajiban, tanggung jawab pengguna dan
                            UR, serta tata cara penggunaan sistem layanan UR.
                          </li>
                          <li class="subJudul1">
                            Pengguna adalah pihak yang menggunakan layanan UR,
                            termasuk namun tidak terbatas pada pembeli, penjual
                            maupun pihak lain yang sekedar berkunjung ke Situs
                            UR.
                          </li>
                          <li class="subJudul1">
                            Pembeli adalah Pengguna terdaftar yang melakukan
                            permintaan atas Barang yang dijual oleh Penjual di
                            Situs UR.
                          </li>
                          <li class="subJudul1">
                            Penjual adalah Pengguna terdaftar yang melakukan
                            tindakan buka toko dan/atau melakukan penawaran atas
                            suatu Barang kepada para Pengguna Situs UR.
                          </li>
                          <li class="subJudul1">
                            Barang adalah benda yang berwujud / memiliki fisik
                            Barang yang dapat diantar / memenuhi kriteria
                            pengiriman oleh perusahaan jasa pengiriman Barang.
                          </li>
                          <li class="subJudul1">
                            Biaya Layanan adalah besaran biaya Layanan atas
                            Pembelian Layanan sebagaimana ditentukan dan
                            disepakati antara Partner dengan Penyedia Layanan.
                          </li>
                          <li class="subJudul1">
                            Formulir Partner adalah formulir pendaftaran Layanan
                            yang wajib disetujui dan/atau ditandatangani oleh
                            Partner, termasuk namun tidak terbatas pada form
                            pemesanan dan/atau formulir-formulir lainnya, baik
                            yang berbentuk fisik maupun elektronik, yang berisi
                            mengenai keterangan, data dan informasi atas
                            Partner, termasuk namun tidak terbatas pada besaran
                            Biaya Layanan yang disepakati oleh Para Pihak, yang
                            disampaikan kepada Penyedia Layanan yang merupakan
                            Afiliasi dari PT. RAHMAT TUHAN LESTARI (sebagaimana
                            relevan) dan merupakan satu kesatuan yang tidak
                            terpisahkan dengan Ketentuan Pembelian UR ini dan
                            Syarat dan Ketentuan Khusus.
                          </li>
                          <li class="subJudul1">
                            Konten Pihak Ketiga adalah setiap informasi, data,
                            atau berita aktual, yang dibuat, disusun,
                            dikembangkan, dan/atau dikelola sendiri oleh
                            Penyedia Konten Pihak Ketiga atau diperoleh Penyedia
                            Konten Pihak Ketiga dari pihak ketiga lain, dimana
                            Penyedia Konten Pihak Ketiga bertanggung jawab untuk
                            memperoleh setiap perizinan yang diperlukan untuk
                            menampilkan Konten Pihak Ketiga tersebut, termasuk
                            namun tidak terbatas pada teks atau tulisan, gambar,
                            quotes atau kutipan, foto. ilustrasi, animasi,
                            video. rekaman suara atau musik, judul, dan/atau
                            deskripsi untuk ditampilkan pada Aplikasi UR,
                            termasuk setiap tautan yang menghubungkan kepadanya.
                          </li>
                          <li class="subJudul1">
                            Laporan adalah keluhan, pertanyaan, sanggahan, klaim
                            atau permintaan yang diajukan oleh Partner terkait
                            dengan transaksi.
                          </li>
                          <li class="subJudul1">
                            Manajemen Konten adalah informasi, data dan/atau
                            konten apapun milik Partner, yang dimasukkan dan
                            ditampilkan dalam Aplikasi UR, dimana dapat
                            diperbaharui sewaktu-waktu oleh Partner dengan
                            mengikuti ketentuan yang diatur oleh PT. RAHMAT
                            TUHAN LESTARI.
                          </li>
                          <li class="subJudul1">
                            Metode Pembayaran adalah uang tunai, sedangkan uang
                            elektronik (E-wallet), fasilitas pinjaman, kartu
                            debit atau kredit atau metode pembayaran lain yang
                            dapat diakses dan digunakan melalui Aplikasi UR
                            lewat payment gateway yang dipilih UR. Pihak payment
                            gateway yang menjadi penanggung jawab atas arus uang
                            dan keuangan ewallet (sesuai ketentuan di
                            Indonesia). Pihak UR hanya penyelenggara applikasi
                            tidak bertanggungjawab atas tindakan payment gate
                            way. Adapun biaya yang dikenakan 1.5% dari omset
                            untuk payment gatway XENDIT dan 2% dari omset untuk
                            payment gateway MIDTRANS, untuk biaya transfer
                            berkala sebesar Rp.5.000,-/1kali tranfer, biaya akun
                            partner dipayment gateway Rp.25.000/ bulan,
                            sedangkan untuk ppn sesuai ketentuan berlaku. Untuk
                            pencairan dana ewallet 2 sd 3 hari sesuai aturan
                            yang diterapkan pihak payment gateway.
                          </li>
                          <li class="subJudul1">
                            Promosi adalah kegiatan dan/atau program penjualan,
                            promosi, dan/atau marketing yang bertujuan untuk
                            menawarkan kepada Partner suatu layanan, jasa atau
                            manfaat lain yang dilakukan oleh Penyedia Layanan
                            kepada Partner melalui Aplikasi UR.
                          </li>
                          <li class="subJudul1">
                            Penyedia Konten Pihak Ketiga adalah PT. RAHMAT TUHAN
                            LESTARI, Penyedia Layanan, Afiliasi dari PT. RAHMAT
                            TUHAN LESTARI dan/atau pihak ketiga yang bekerja
                            sama dengan PT. RAHMAT TUHAN LESTARI untuk
                            menyediakan Konten Pihak Ketiga.
                          </li>
                          <li class="subJudul1">
                            Penyedia Layanan adalah PT. RAHMAT TUHAN LESTARI,
                            Afiliasi dari PT. RAHMAT TUHAN LESTARI dan/atau
                            pihak ketiga independen yang setuju menjadi penyedia
                            layanan di Aplikasi UR dengan skema kemitraan atau
                            skema kerjasama lainnya, dimana Penyedia Layanan
                            berhak mengenakan Biaya Layanan kepada Partner.
                          </li>
                          <li class="subJudul1">
                            Penyedia Metode Pembayaran adalah Metode Pembayaran
                            elektronik, yang disediakan oleh Afiliasi dari PT.
                            RAHMAT TUHAN LESTARI maupun pihak ketiga independen
                            (seperti bank, penerbit uang elektronik, dll) yang
                            tersedia di Aplikasi UR.
                          </li>
                          <li class="subJudul1">
                            Penyedia Promo mmemiliki arti sebagaimana dimaksud
                            dalam Pasal III Ayat D dari Syarat dan Ketentuan UR
                            ini.
                          </li>
                          <li class="subJudul1">
                            Pedoman Operasi Standar atau SOP adalah prosedur
                            operasi standar Pembelian Layanan yang sebagaimana
                            ditetapkan oleh PT. RAHMAT TUHAN LESTARI dan dapat
                            diubah atau ditambahkan dari waktu ke waktu.
                          </li>
                          <li class="subJudul1">
                            Saldo UR adalah fitur pencatatan dana milik Partner
                            (bukan fasilitas penyimpanan dana ataupun uang
                            elektronik) yang disediakan oleh PT. RAHMAT TUHAN
                            LESTARI untuk memberikan informasi dana transaksi
                            Layanan dan/atau Biaya Layanan yang digunakan
                            dan/atau diakses oleh Partner melalui Aplikasi UR.
                          </li>
                          <li class="subJudul1">
                            Syarat dan Ketentuan Khusus adalah syarat dan
                            ketentuan masing-masing Layanan yang dibuat dan
                            disepakati antara Partner dan Penyedia Layanan.
                          </li>
                          <li class="subJudul1">
                            Afiliasi adalah (i) setiap perusahaan atau badan
                            lainnya yang memiliki kendali atas salah satu Pihak,
                            (ii) setiap perusahaan atau badan lainnya yang mana
                            salah satu Pihak memiliki kendali atas perusahaan
                            atau badan lainnya tersebut, atau (iii) setiap
                            perusahaan atau badan lainnya yang berada di bawah
                            kendali yang sama dengan salah satu Pihak.
                          </li>
                          <ol>
                            <li class="subJudul2">
                              Dokumen Administratif adalah dokumen yang
                              menunjukkan legalitas Partner.
                            </li>
                            <li class="subJudul2">
                              Fitur berbayar di dalam UR yang dapat Partner
                              pilih sesuai dengan kebutuhan Partner.
                            </li>
                            <li class="subJudul2">
                              Formulir Partner adalah formulir pendaftaran
                              Layanan dan/atau formulir-formulir lainnya dalam
                              bentuk elektronik, yang berisi mengenai keterangan
                              data dan informasi Partner, termasuk namun tidak
                              terbatas pada nama, alamat dan nomor telepon
                              Partner yang merupakan satu kesatuan yang tidak
                              terpisahkan dengan Ketentuan Pembelian ini.
                            </li>
                            <li class="subJudul2">
                              Gerai adalah gerai yang dimiliki, dikelola dan
                              didaftarkan oleh Partner di dalam Platform UR
                              sebagaimana dapat diubah dan ditambah dari waktu
                              ke waktu oleh Partner.
                            </li>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenPrivacyPoilcy(!openPrivacyPoilcy)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ketentuan Umum" />
                  {openPrivacyPoilcy ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openPrivacyPoilcy} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">
                            Partner UR hanya dapat digunakan oleh Pembeli yang
                            telah mendaftarkan akun, menyetujui Syarat dan
                            Ketentuan ini serta yang sudah diverifikasi sesuai
                            kebijakan dari UR.
                          </li>
                          <li class="subJudul1">
                            Partner UR dengan ini menyatakan bahwa Partner UR
                            adalah orang yang cakap dan PT. Rahmat Tuhan
                            Lestaripu untuk mengikatkan dirinya dalam sebuah
                            perjanjian yang sah menurut hukum.
                          </li>
                          <li class="subJudul1">
                            UR tidak memungut biaya selama menggunakan Applikasi
                            UR Partner versi standard, untuk biaya bergabung di
                            atur <a href="">DISINI</a>{" "}
                          </li>
                          <li class="subJudul1">
                            UR berhak untuk menggunakan data Partner UR untuk
                            penelusuran indikasi manipulasi, pelanggaran maupun
                            pemanfaatan fitur UR untuk keuntungan pribadi
                            Partner UR, maupun indikasi kecurangan atau
                            pelanggaran Syarat dan Ketentuan ini, Ketentuan
                            Situs, dan/atau ketentuan hukum yang berlaku di
                            wilayah negara Indonesia.
                          </li>
                          <li class="subJudul1">
                            UR berhak, tanpa pemberitahuan sebelumnya, melakukan
                            tindakan-tindakan yang diperlukan termasuk namun
                            tidak terbatas pada penghentian Pembelian
                            Situs/Aplikasi, membatalkan transaksi, menahan dana,
                            menurunkan reputasi, menutup akun, serta hal-hal
                            lainnya jika ditemukan adanya manipulasi,
                            pelanggaran maupun pemanfaatan untuk keuntungan
                            pribadi Partner UR, maupun indikasi kecurangan atau
                            pelanggaran Syarat dan Ketentuan ini, Ketentuan
                            Situs, dan/atau ketentuan hukum yang berlaku di
                            wilayah negara Indonesia.
                          </li>
                          <li class="subJudul1">
                            UR berhak melakukan perubahan atas Syarat dan
                            Ketentuan ini.
                          </li>
                          <li class="subJudul1">
                            Ketentuan Pembelian UR ini adalah perjanjian antara
                            Partner dan (“PT. RAHMAT TUHAN LESTARI”) yang
                            mengatur akses dan Pembelian Partner atas aplikasi,
                            situs web, konten dan produk yang disediakan oleh
                            PT. RAHMAT TUHAN LESTARI (selanjutnya, secara
                            bersama-sama disebut sebagai (“Aplikasi UR”), serta
                            pemesanan, pembayaran atau Pembelian layanan yang
                            tersedia pada Aplikasi UR (“Layanan”).
                          </li>
                          <li class="subJudul1">
                            PT. RAHMAT TUHAN LESTARI berhak, atas diskresi kami.
                            untuk mengubah, menambahkan, atau mencabut
                            bagian-bagian dari Ketentuan Pembelian UR ini pada
                            setiap saat dengan memberikan notifikasi pada
                            Aplikasi UR atau dengan cara lain yang PT. RAHMAT
                            TUHAN LESTARI tentukan. Partner berkewajiban untuk
                            meninjau dan membaca seluruh perubahan tersebut
                            sesuai dengan notifikasi yang PT. RAHMAT TUHAN
                            LESTARI berikan dan dengan melakukan atau tetap
                            menggunakan Aplikasi UR setelah perubahan tersebut
                            Partner dianggap sebagai setuju terhadap perubahan
                            Ketentuan Pembelian UR tersebut.
                          </li>
                          <li class="subJudul1">
                            Dengan memperhatikan seluruh ketentuan dalam
                            Ketentuan Pembelian UR, Para Pihak menyetujui
                            Ketentuan Pembelian UR sebagai berikut:
                          </li>
                          <li class="subJudul1">
                            UR adalah suatu perusahaan yang bergerak di bidang
                            aktivitas pengolahan data, portal web dan/atau
                            platform digital dengan tujuan komersial serta
                            penyediaan layanan aplikasi sistem kasir dengan
                            merek dagang “UR”.
                          </li>
                          <li class="subJudul1">
                            Partner adalah suatu badan hukum dan/atau perorangan
                            yang bergerak di bidang perdagangan barang dan/atau
                            jasa, bertindak secara independen, menjalankan
                            bisnisnya dengan kebijakan sendiri dan bermaksud
                            untuk menggunakan layanan UR.l. Ketentuan Pembelian
                            ini mengatur akses dan Pembelian Partner atas
                            Aplikasi UR, situs web www.partner.ur-hub.com
                            beserta konten dan produk-produk yang UR sediakan
                            didalamnya (selanjutnya secara bersama-sama disebut
                            sebagai “Platform UR”).
                          </li>
                          <li class="subJudul1">
                            Ketentuan Pembelian ini mengatur akses dan Pembelian
                            Partner atas Aplikasi UR, situs web
                            www.partner.ur-hub.com beserta konten dan
                            produk-produk yang UR sediakan didalamnya
                            (selanjutnya secara bersama-sama disebut sebagai
                            “Platform UR”).
                          </li>
                          <li class="subJudul1">
                            Dengan menyetujui Ketentuan Pembelian ini, Partner
                            juga menyetujui Ketentuan Pembelian tambahan,
                            termasuk Ketentuan Pembelian pada masing-masing
                            Layanan, beserta setiap perubahannya yang merupakan
                            bagian yang tidak terpisahkan dari Ketentuan
                            Pembelian ini (selanjutnya, Ketentuan Pembelian,
                            Ketentuan Pembelian tambahan dan setiap perubahannya
                            secara bersama-sama disebut sebagai “Ketentuan
                            Pembelian”). Meskipun merupakan satu kesatuan,
                            Ketentuan Pembelian tambahan akan berlaku dalam hal
                            terdapat perbedaan dengan Ketentuan Pembelian.
                          </li>
                          <li class="subJudul1">
                            Ketentuan Pembelian ini juga mencakup Syarat dan
                            Ketentuan Pembelian Layanan UR yang menjadi bagian
                            yang tidak terpisahkan dari Ketentuan Pembelian ini
                            dan hanya akan berlaku dan mengikat Partner dalam
                            hal Partner mengaktivasi dan menggunakan Layanan UR.
                          </li>
                          <li class="subJudul1">
                            Sehubungan dengan hal tersebut di atas, Partner
                            setuju atas syarat dan ketentuan seperti sebagaimana
                            tercantum di bawah ini:
                          </li>
                          <li class="subJudul1">Pengajuan Partner UR</li>
                          <ol>
                            <li class="subJudul2">
                              Untuk dapat menjadi Parner UR, Pembeli harus
                              mengajukan permohonan melalui formulir daring yang
                              telah disediakan oleh UR.
                            </li>
                            <li class="subJudul2">
                              Proses verifikasi akan dilakukan oleh UR dalam
                              jangka waktu 2x24 jam atau jangka waktu lain
                              sesuai dengan kebijakan UR.
                            </li>
                            <li class="subJudul2">
                              Parner UR menjamin dan bertanggungjawab atas
                              keabsahan informasi dan dokumen yang diberikan
                              dalam mengajukan permohonan untuk mendaftar
                              sebagai Parner UR.
                            </li>
                            <li class="subJudul2">
                              Parner UR menyetujui bahwa informasi dan dokumen
                              yang diberikan pada saat melakukan pendaftaran
                              dapat dibagikan dan/atau disampaikan oleh UR
                              kepada pihak ketiga untuk proses pelaksanaan
                              verifikasi atau kegiatan lainnya sehubungan dengan
                              pendaftaran Parner UR.
                            </li>
                            <li class="subJudul2">
                              Parner UR menyetujui dan memberikan wewenang
                              kepada UR untuk mengumpulkan dan mengolah
                              informasi yang terdapat dalam formulir laman
                              Parner UR pada Situs/Aplikasi kepada Partner.
                            </li>
                            <li class="subJudul2">
                              Pembeli menyetujui dan memberikan wewenang kepada
                              UR untuk memberikan informasi dan/atau data
                              relevan lainnya pada Situs/Aplikasi yang
                              dibutuhkan oleh Partner.
                            </li>
                            <li class="subJudul2">
                              PARNER HANYA MENJUAL BARANG BARANG YANG LEGAL DAN
                              SESUAI KETENTUAN YANG BERLAKU DI INDONESIA, JIKA
                              TERJADI PELANGGARAN SEPENUHNYA MENJADI TANGGUNG
                              JAWAB PARNER DAN MEMBEBASKAN PIHAK PT.RAHMAT TUHAN
                              LESTARI DARI SEGALA TUNTUTAN DAN RESIKO APAPUN.
                            </li>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setOpenPurchase(!openPurchase)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Penggunaan Aplikasi" />
                  {openPurchase ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openPurchase} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">GENERAL</li>
                          <ol>
                            <li class="subJudul2">
                              Partner mempunyai kebebasan penuh untuk memilih
                              menggunakan Aplikasi UR atau aplikasi lain,
                              menggunakan Layanan yang tersedia pada Aplikasi UR
                              atau tidak, atau berhenti menggunakan Aplikasi UR.
                            </li>
                            <li class="subJudul2">
                              Selanjutnya, jika Penyedia Layanan menerima
                              pesanan Partner, PT. RAHMAT TUHAN LESTARI akan
                              menginformasikan status pesanan Partner melalui
                              Aplikasi UR.
                            </li>
                            <li class="subJudul2">Perangkat Lunak UR</li>
                            <ol>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI hanya menyediakan
                                perangkat lunak Aplikasi UR yang resmi pada
                                pasar digital resmi, seperti Google Play Store
                                atau Apple App Store, dan untuk digunakan hanya
                                pada perangkat telepon genggam atau tablet.
                                Mengunduh Aplikasi UR dari tempat lain selain
                                pasar digital resmi dan/atau ke dalam perangkat
                                lain selain telepon genggam atau tablet
                                merupakan pelanggaran terhadap Ketentuan
                                Penggunaan UR ini dan terhadap hak kekayaan
                                intelektual PT. RAHMAT TUHAN LESTARI.
                              </li>
                            </ol>
                          </ol>
                          <li class="subJudul1">AKUN</li>
                          <ol>
                            <li class="subJudul2">
                              Pembukaan dan Pengaksesan AKUN
                            </li>
                            <ol>
                              <li class="subJudul3">
                                Sebelum mengakses dan menggunakan Platform UR,
                                Partner harus menyetujui Ketentuan Penggunaan
                                ini dan Kebijakan Privasi, dan mendaftarkan diri
                                dengan menyelesaikan pengisian Formulir Partner
                                serta mengunggah seluruh Dokumen Administratif
                                yang dimintakan di dalam Platform UR selama
                                proses pendaftaran. Partner lebih lanjut dapat
                                mengubah informasi data Partner yang telah
                                dilengkapi pada fitur pengaturan dalam Platform
                                UR.
                              </li>
                              <li class="subJudul3">
                                Setelah melakukan pendaftaran, sistem UR akan
                                menghasilkan kode verifikasi (One Time Password/
                                OTP) secara otomatis dan mengirim kode
                                verifikasi tersebut melalui pesan singkat ke
                                nomor telepon Partner yang terdaftar. Partner
                                perlu melakukan verifikasi dengan memasukan kode
                                verifikasi tersebut pada halaman pendaftaran di
                                Platform UR. Kode verifikasi dihasilkan secara
                                otomatis oleh sistem UR. UR tidak mengetahui dan
                                tidak pernah meminta kode verifikasi Partner.
                                Jangan pernah memberitahukan kode verifikasi
                                Partner kepada siapapun bahkan kepada UR atau
                                pihak lain yang mengatasnamakan UR.
                              </li>
                              <li class="subJudul3">
                                Setelah melakukan verifikasi, sistem UR akan
                                membuatkan akun untuk Partner yang dapat
                                digunakan untuk mengakses UR dan menggunakan
                                Layanan yang tersedia di dalamnya (“Akun”).
                                Alamat surat elektronik yang telah Partner
                                daftarkan akan melekat pada Akun Partner
                                sehingga Partner tidak bisa membuat Akun baru
                                dengan alamat surat elektronik yang sudah
                                didaftarkan. Dalam hal Partner telah keluar dari
                                Akun, maka Partner perlu memasukkan alamat surat
                                elektronik yang telah didaftarkan.
                              </li>
                              <li class="subJudul3">
                                UR dapat menolak proses pendaftaran Partner
                                dengan alasan-alasan bahwa Partner diketahui,
                                dicurigai, atau terindikasi sebagai berikut,
                                termasuk namun tidak terbatas pada:
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  Tidak memiliki legalitas usaha yang valid;
                                </li>
                                <li class="subJudul4">
                                  MENJUAL BARANG / JASA ATAU KEGIATAN YANG TIDAK
                                  SESUAI KETENTUAN DAN HUKUM YANG BELAKU DI
                                  INDONESIA.
                                </li>
                                <li class="subJudul4">
                                  Terlibat tindakan kriminal atau melanggar
                                  norma hukum, sosial, agama dan moral;
                                </li>
                                <li class="subJudul4">
                                  Terlibat dalam kelompok atau organisasi
                                  terlarang; dan/atau
                                </li>
                                <li class="subJudul4">
                                  Pertimbangan jenis lain yang ditentukan oleh
                                  UR dan/atau Parner Pihak Ketiga Penyedia
                                  Layanan yang bekerja sama dengan UR.
                                </li>
                              </ol>
                            </ol>
                            <li class="subJudul2">Pembekuan Akun</li>
                            <ol>
                              <li class="subJudul3">
                                Partner dapat menghapus Aplikasi UR dari telepon
                                genggam dan/atau tablet Partner setiap saat. UR
                                tidak memiliki kewajiban apapun kepada Partner
                                terhadap hal-hal yang timbul sejak penghapusan
                                Aplikasi UR, pembekuan sementara atau pembekuan
                                permanen Akun. Akan tetapi, Partner tetap
                                bertanggung jawab untuk menyelesaikan
                                kewajiban-kewajiban Partner yang telah timbul,
                                termasuk namun tidak terbatas pada, setiap
                                kewajiban yang mungkin timbul akibat adanya
                                sengketa, tuntutan, maupun tindakan hukum
                                lainnya yang telah ada, sebelum tanggal
                                penghapusan Aplikasi UR, pembekuan sementara
                                atau pembekuan permanen Akun Partner.
                              </li>
                              <li class="subJudul3">
                                Akun Partner dapat dibekukan karena hal-hal,
                                termasuk namun tidak terbatas pada, sebagai
                                berikut:
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  Melakukan pelanggaran atas Ketentuan
                                  Penggunaan ini dan/atau Kebijakan Privasi;
                                </li>
                                <li class="subJudul4">
                                  Laporan Partner bahwa Akun digunakan atau
                                  diduga digunakan atau disalahgunakan oleh
                                  pihak ketiga;
                                </li>
                                <li class="subJudul4">
                                  Laporan Partner bahwa telepon genggam atau
                                  tablet pribadi Partner hilang, dicuri atau
                                  diretas;
                                </li>
                                <li class="subJudul4">
                                  UR mengetahui atau mempunyai alasan yang cukup
                                  untuk menduga bahwa Akun telah dialihkan atau
                                  digunakan oleh pihak lain tanpa persetujuan
                                  terlebih dahulu dari UR;
                                </li>
                                <li class="subJudul4">
                                  UR mengetahui atau dengan alasan yang cukup
                                  menduga bahwa telah terjadi hal-hal yang
                                  menurut pandangan UR telah atau dapat
                                  merugikan UR, Parner Pihak Ketiga Penyedia
                                  Layanan, Penyedia Penawaran, atau pihak
                                  lainnya;
                                </li>
                                <li class="subJudul4">
                                  Sistem UR mendeteksi adanya tindakan yang
                                  tidak wajar dari Penggunaan Akun atau adanya
                                  kewajiban berdasarkan Ketentuan Penggunaan
                                  dan/atau Kebijakan Privasi yang tidak dipenuhi
                                  oleh Partner;
                                </li>
                                <li class="subJudul4">
                                  Partner tidak melakukan pembayaran atas biaya
                                  berlangganan selama jangka waktu yang
                                  ditentukan di dalam Platform UR;
                                </li>
                                <li class="subJudul4">
                                  Partner telah meninggal dunia, ditempatkan di
                                  bawah perwalian atau pengampuan atau lainnya
                                  yang menjadikan Partner tidak cakap hukum
                                  berdasarkan peraturan perundang-undangan yang
                                  berlaku
                                </li>
                                <li class="subJudul4">
                                  Dalam hal Partner mengajukan atau diajukan
                                  oleh pihak lain permohonan kepada instansi
                                  yang berwenang untuk dinyatakan pailit atau
                                  untuk diberikan penundaan membayar
                                  hutang-hutang atau telah dinyatakan berada
                                  dalam keadaan pailit atau diberikan penundaan
                                  membayar hutang-hutang oleh instansi yang
                                  berwenang;
                                </li>
                              </ol>
                              <li class="subJudul3">
                                Penggunaan Platform UR atau Layanan oleh Partner
                                atau pihak lain yang terotorisasi (yang
                                menggunakan Akun) dengan cara yang bertentangan
                                dengan Ketentuan Penggunaan ini, Kebijakan
                                Privasi atau peraturan perundang-undangan yang
                                berlaku; dan/atau
                              </li>
                              <li class="subJudul3">
                                Perintah untuk pembekuan Akun yang diterbitkan
                                oleh institusi pemerintah atau moneter terkait
                                atau berdasarkan perintah pengadilan yang
                                diterbitkan sesuai dengan peraturan
                                perundang-undangan yang berlaku.
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  Jika Akun dibekukan dan Partner memiliki bukti
                                  yang jelas bahwa Akun seharusnya tidak
                                  dibekukan, Partner dapat membuat Laporan
                                  kepada UR untuk menyampaikan bukti-bukti
                                  tersebut. Setelah melakukan pemeriksaan lebih
                                  lanjut terhadap Laporan Partner, UR akan, atas
                                  kebijakan UR sepenuhnya, menentukan untuk
                                  mengakhiri atau melanjutkan pembekuan terhadap
                                  Akun Partner. Pembekuan tidak akan diteruskan
                                  secara tidak wajar apabila UR memutuskan bahwa
                                  hal-hal yang mengakibatkan terjadinya
                                  pembekuan telah diselesaikan.
                                </li>
                                <li class="subJudul4">
                                  Pengakhiran Penggunaan Platform UR
                                </li>
                                <li class="subJudul4">
                                  Akses dan/atau Penggunaan Partner atas
                                  Platform UR berakhir jika Akun Partner
                                  dibekukan seperti sebagaimana dimaksud di
                                  atas.
                                </li>
                                <li class="subJudul4">
                                  Apabila pada saat berakhirnya Ketentuan
                                  Penggunaan masih terdapat hak dan kewajiban
                                  yang belum Partner selesaikan, maka
                                  ketentuan-ketentuan dalam Ketentuan Penggunaan
                                  tetap berlaku dan mengikat sampai dengan
                                  Partner menyelesaikan sisa hak dan kewajiban
                                  tersebut.
                                </li>
                                <li class="subJudul4">
                                  UR, berdasarkan pertimbangan dan kebijakannya
                                  sendiri, berhak untuk secara sepihak
                                  mengakhiri Ketentuan Penggunaan ini (baik
                                  seluruhnya maupun sebagian) untuk seketika dan
                                  secara langsung.
                                </li>
                                <li class="subJudul4">
                                  Para Pihak untuk mengesampingkan berlakunya
                                  ketentuan Pasal 1266 Kitab Undang-Undang Hukum
                                  Perdata, sehingga pengakhiran yang dimaksud
                                  dapat dilakukan tanpa memerlukan putusan
                                  pengadilan dan cukup dilakukan dengan
                                  pemberitahuan tertulis.
                                </li>
                              </ol>
                            </ol>
                            <li class="subJudul2">Ketentuan Akun</li>
                            <ol>
                              <li class="subJudul3">
                                Dalam hal ini, Partner mengetahui dan sepakat
                                bahwa PT. RAHMAT TUHAN LESTARI hanya menyediakan
                                Aplikasi UR sebagaimana adanya dan PT. RAHMAT
                                TUHAN LESTARI tidak menyatakan atau menjamin
                                maupun bertanggung jawab bahwa keandalan,
                                ketepatan waktu, kualitas, kesesuaian,
                                ketersediaan, akurasi, kelengkapan atau keamanan
                                dari Aplikasi UR dapat memenuhi kebutuhan dan
                                akan sesuai dengan ekspektasi Partner, termasuk
                                namun tidak terbatas pada Layanan, Konten Pihak
                                Ketiga, Promosi dan Metode Pembayaran yang
                                sepenuhnya menjadi tanggung jawab Penyedia
                                Layanan, Penyedia Konten Pihak Ketiga, Penyedia
                                Promosi dan Penyedia Metode Pembayaran.
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI tidak berkewajiban
                                untuk mengawasi akses atau Penggunaan Partner
                                atas Aplikasi UR. Namun, PT. RAHMAT TUHAN
                                LESTARI tetap melakukan pengawasan untuk tujuan
                                memastikan kelancaran Penggunaan Aplikasi UR dan
                                untuk memastikan kepatuhan terhadap Ketentuan
                                Penggunaan UR ini, peraturan perundang-undangan
                                yang berlaku, putusan pengadilan, dan/atau
                                ketentuan lembaga administratif atau badan
                                pemerintahan lainnya.
                              </li>
                              <li class="subJudul3">
                                Partner wajib memberitahukan PT. RAHMAT TUHAN
                                LESTARI jika Partner mengetahui atau menduga
                                bahwa Akun UR Partner telah digunakan tanpa
                                sepengetahuan dan persetujuan Partner. PT.
                                RAHMAT TUHAN LESTARI akan melakukan tindakan
                                yang PT. RAHMAT TUHAN LESTARI anggap perlu dan
                                dapat PT. RAHMAT TUHAN LESTARI lakukan terhadap
                                Penggunaan tanpa persetujuan tersebut termasuk
                                namun tidak terbatas pada menghentikan akses
                                terhadap Akun UR, bekerjasama dengan Penyedia
                                Layanan untuk tidak memfasilitasi atau
                                memberikan Layanan apapun.
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI tidak mempunyai
                                kewajiban apapun, termasuk untuk mengambil
                                tindakan lebih jauh atau tindakan hukum yang
                                dianggap perlu oleh Partner, Penyedia Layanan,
                                Penyedia Konten Pihak Ketiga, Penyedia Promosi
                                atau Penyedia Metode Pembayaran, terhadap setiap
                                permasalahan atau perselisihan yang timbul
                                antara Partner dan Penyedia Layanan, Penyedia
                                Konten Pihak Ketiga, Penyedia Promosi atau
                                Penyedia Metode Pembayaran.
                              </li>
                              <li class="subJudul3">
                                Partner memahami dan setuju bahwa atas
                                Penggunaan Akun UR diatur lebih lanjut dalam
                                Syarat dan Ketentuan Penunjukan Peran Akun UR
                                yang merupakan bagian tidak terpisahkan dari
                                Ketentuan Penggunaan UR ini.
                              </li>
                              <li class="subJudul3">
                                Akun hanya dapat digunakan oleh Partner dan/atau
                                karyawan Gerai yang telah diberikan otorisasi
                                oleh Partner untuk mengakses Akun dan tidak bisa
                                dialihkan kepada pihak ketiga lainnya yang tidak
                                terotorisasi dengan alasan apapun. UR berhak
                                menolak untuk memfasilitasi Penggunaan Platform
                                UR dan/atau Layanan jika UR mengetahui atau
                                mempunyai alasan yang cukup untuk menduga bahwa
                                Partner telah mengalihkan atau membiarkan Akun
                                digunakan oleh pihak ketiga lainnya yang tidak
                                terotorisasi oleh Partner.
                              </li>
                              <li class="subJudul3">
                                Keamanan dan kerahasiaan Akun, termasuk namun
                                tidak terbatas pada nama terdaftar, alamat surat
                                elektronik terdaftar, password, nomor telepon
                                genggam terdaftar, rincian pembayaran dan Fitur
                                Modular yang Partner pilih, serta kode
                                verifikasi yang dihasilkan dan dikirim oleh
                                sistem UR sepenuhnya merupakan tanggung jawab
                                pribadi Partner. Semua kerugian dan risiko yang
                                ada akibat kelalaian Partner menjaga keamanan
                                dan kerahasiaan sebagaimana disebutkan
                                ditanggung oleh Partner sendiri. Dalam hal
                                demikian, UR akan menganggap setiap Penggunaan
                                yang dilakukan melalui Akun Partner sebagai
                                permintaan yang sah dari Partner.
                              </li>
                              <li class="subJudul3">
                                Partner memahami bahwa Partner memiliki waktu 14
                                (empat belas) Hari Kalender untuk mengakses dan
                                melakukan percobaan Penggunaan Layanan tanpa
                                dipungut biaya terhitung sejak tanggal UR
                                menerbitkan Akun (“Masa Percobaan”). Partner
                                lebih lanjut memahami bahwa selama dan setelah
                                Masa Percobaan terdapat beberapa Layanan yang
                                tidak bisa Partner akses tanpa berlangganan
                                terlebih dahulu dan/atau memenuhi kriteria
                                tertentu.
                              </li>
                              <li class="subJudul3">
                                UR menyediakan Layanan tambahan yang mencakup
                                tetapi tidak terbatas pada layanan UR Connect,
                                UR Capital, UR Store dan/atau Layanan lainnya
                                yang UR kembangkan dari waktu ke waktu. Partner
                                memahami bahwa Akun yang terdaftar di Platform
                                UR akan digunakan pula untuk mengakses
                                masing-masing Layanan tambahan tersebut apabila
                                Partner mengaktifkan Layanan tambahan yang
                                bersangkutan
                              </li>
                              <li class="subJudul3">
                                Partner perlu memahami bahwa UR atau petugas UR
                                tidak akan pernah meminta detail informasi
                                log-in Partner dengan cara apapun dan untuk
                                alasan apapun, termasuk namun tidak terbatas
                                pada username, password maupun kode verifikasi,
                                oleh karena itu UR menghimbau Partner agar tidak
                                memberikan data tersebut kepada pihak ketiga
                                manapun termasuk pihak yang mengatasnamakan UR.
                              </li>
                              <li class="subJudul3">
                                Segera beritahukan UR jika Partner mengetahui
                                atau menduga bahwa Akun telah digunakan tanpa
                                sepengetahuan dan persetujuan Partner. UR akan
                                melakukan tindakan yang UR anggap perlu dan
                                dapat UR lakukan terhadap Penggunaan tanpa
                                persetujuan tersebut.
                              </li>
                              <li class="subJudul3">
                                Parner dapat mendaftarkan diri sebagai PARTNER
                                VIP, yaitu layanan Aplikasi UR yang memberikan
                                kemudahan bagi Penjual untuk mengelola toko
                                beragam fasilitas eksklusif yang disediakan oleh
                                UR dan fasilitas lainnya yang secara detail
                                dapat dilihat di halaman <a href="">DISINI</a>
                              </li>
                              <li class="subJudul3">
                                Status, simbol, logo, nama atau hal-hal lainnya
                                yang terkait dengan parner vip merupakan
                                kewenangan UR dan hanya diberikan oleh pihak UR
                                kepada pelanggan parner vip. Pembeli tidak
                                diperkenankan untuk meniru atau menggunakan
                                hal-hal tersebut pada Nama Toko, Deskripsi Toko,
                                Nama Produk, Foto Produk, dan lain sebagainya.
                              </li>
                              <li class="subJudul3">
                                Dalam menggunakan fitur TopAds, Pembeli memahami
                                dan menyetujui bahwa setiap iklan yang Pembeli
                                unggah di situs UR adalah tanggung jawab Pembeli
                                secara pribadi dan melepaskan UR dari segala
                                permasalahan yang mungkin timbul daripadanya.
                              </li>
                              <li class="subJudul3">
                                UR tanpa pemberitahuan terlebih dahulu kepada
                                Pembeli, memiliki kewenangan untuk melakukan
                                tindakan penghapusan atas setiap konten iklan
                                yang melanggar Syarat & ketentuan UR dan/atau
                                hukum yang berlaku. Pembeli dapat melihat syarat
                                dan ketentuan mengenai TopAds secara detail{" "}
                                <a href="">DISINI</a>
                              </li>
                              <li class="subJudul3">
                                UR berwenang untuk melakukan (i) penghapusan
                                iklan: (ii) penutupan toko: (iii) penutupan
                                akun: dalam hal diduga dan/atau terdapat
                                kegiatan Pembeli yang melanggar ketentuan hukum
                                dan/atau syarat ketentuan UR. Dengan demikian,
                                Pembeli memahami dan menyetujui bahwa setiap
                                kerugian yang timbul akibat dari tindakan
                                tersebut diatas bukanlah tanggung jawab UR,
                                termasuk namun tidak terbatas pada kehilangan
                                kredit TopAds.
                              </li>
                            </ol>
                            <li class="subJudul2">SALDO UR</li>
                            <ol>
                              <li class="subJudul3">
                                Saldo UR adalah fitur pencatatan dana milik
                                Partner (bukan fasilitas penyimpanan dana
                                ataupun uang elektronik) yang disediakan oleh
                                PT. RAHMAT TUHAN LESTARI untuk memberikan
                                informasi dana transaksi Layanan dan/atau Biaya
                                Layanan yang digunakan atau diakses oleh Partner
                                melalui Aplikasi UR.
                              </li>
                              <li class="subJudul3">
                                Saldo UR hanya berasal dari hasil transaksi
                                Layanan yang dikelola melalui Aplikasi UR dan
                                tidak bisa dilakukan penambahan secara sendiri
                                (top-up).
                              </li>
                              <li class="subJudul3">
                                Saldo UR yang tercatat pada Aplikasi UR hanya
                                dapat digunakan/dilakukan pencairan dana
                                (withdrawai/ settlemenf) ke rekening bank
                                sebagaimana telah ditentukan atau diinstruksikan
                                oleh Partner kepada atau melalui Aplikasi UR
                                untuk disampaikan kepada Penyedia Layanan
                                sebagaimana relevan.
                              </li>
                              <li class="subJudul3">
                                Atas pengelolaan pencatatan Saldo UR Partner,
                                PT. RAHMAT TUHAN LESTARI hanya bertindak sebagai
                                perantara antara Partner dan Penyedia Layanan
                                dan PT. RAHMAT TUHAN LESTARI akan bekerja
                                berdasarkan instruksi yang diberikan oleh
                                Partner atas dana yang dimiliki oleh Partner
                                sebagaimana tercatat dalam Saldo UR untuk
                                disampaikan kepada Penyedia Layanan sebagaimana
                                relevan.
                              </li>
                              <li class="subJudul3">
                                Parner setuju dengan biaya2 layanan pihak ketiga
                                yang timbul atas pengelolaan transaksi yang
                                terjadi maupun biaya2 yang timbul akibat
                                instruksi yang diberikan parner ke UR untuk
                                biaya layanan bias dilihat <a href="">DISINI</a>
                                .
                              </li>
                            </ol>
                            <li class="subJudul2">
                              Keamanan dan Perlindungan Informasi
                            </li>
                            <ol>
                              <li class="subJudul3">
                                Kecuali diatur lain secara tegas dalam Ketentuan
                                Penggunaan UR ini. setiap pengumpulan,
                                penyimpanan, pengolahan, Penggunaan dan
                                pembagian data dan/atau informasi pribadi dan
                                sensitif Partner (“Data Partner”), termasuk
                                namun tidak terbatas pada nama, alamat, tanggal
                                lahir, pekerjaan, nomor telepon seluler, nomor
                                fax, alamat surat elektronik. Nomor rekening dan
                                rincian kartu kredit, gender, identitas
                                (termasuk KTP, SIM, atau paspor) atau identitas
                                lainnya yang dikeluarkan oleh pemerintah, yang
                                diberikan oleh Partner, akan tunduk pada
                                Kebijakan Privasi UR <a href="">DISINI</a>
                              </li>
                              <li class="subJudul3">
                                Tanpa membatasi ketentuan pada ketentuan ganti
                                rugi yang berlaku umum, sejauh dimungkinkan
                                berdasarkan hukum dan peraturan
                                perundang-undangan yang berlaku, Partner setuju
                                untuk melepaskan dan membebaskan PT. RAHMAT
                                TUHAN LESTARI dari klaim dan tuntutan apapun
                                yang timbul sehubungan dengan virus, kerusakan,
                                gangguan, atau bentuk lain dari gangguan sistem,
                                termasuk akses tanpa otorisasi oleh pihak ketiga
                                yang tidak berwenang. Partner wajib untuk
                                memberitahukan PT. RAHMAT TUHAN LESTARI sesegera
                                mungkin apabila Partner mengalami gangguan
                                sistem apapun sebagaimana disebutkan di atas
                                sehingga PT. RAHMAT TUHAN LESTARI dapat
                                melakukan upaya terbaiknya dalam memperbaiki
                                gangguan tersebut.
                              </li>
                              <li class="subJudul3">
                                Partner bertanggung jawab dan menjamin bahwa
                                Partner tidak akan melakukan hal-hal sebagai
                                berikut:
                              </li>
                              <li class="subJudul3">
                                Menghapus setiap pemberitahuan hak cipta, merek
                                dagang atau pemberitahuan hak milik lainnya yang
                                terkandung dalam sistem, aplikasi maupun data
                                milik PT. RAHMAT TUHAN LESTARI;
                              </li>
                              <li class="subJudul3">
                                Menyalin, memodifikasi. mengadaptasi,
                                menerjemahkan, membuat karya turunan dari,
                                mendistribusikan, memberikan lisensi, menjual,
                                mengalihkan, menampilkan di muka umum baik
                                sebagian maupun seluruhnya, merekayasa balik
                                (reverse engineering). mentransmisikan,
                                memindahkan, menyiarkan, menguraikan, atau
                                membongkar bagian manapun dari atau dengan cara
                                lain mengeksploitasi sistem, aplikasi maupun
                                data milik PT. RAHMAT TUHAN LESTARI;
                              </li>
                              <li class="subJudul3">
                                Memberikan lisensi, mensublisensikan, menjual,
                                menjual kembali, memindahkan, mengalihkan,
                                mendistribusikan atau mengeksploitasi secara
                                komersial atau membuat tersedia kepada pihak
                                lain Aplikasi UR ataupun piranti lunak
                                (software) maupun perangkat keras (hardware)
                                milik PT. RAHMAT TUHAN LESTARI dan/atau
                                Afiliasinya dengan cara menciptakan tautan
                                (link) internet ke sistem, aplikasi maupun data
                                milik PT. RAHMAT TUHAN LESTARI atau "frame" atau
                                "mirror" setiap piranti lunak pada server lain
                                atau perangkat nirkabel atau yang berbasis
                                internet;
                              </li>
                              <li class="subJudul3">
                                Meluncurkan program otomatis atau script,
                                termasuk, namun tidak terbatas pada, web
                                spiders, web crawlers, web robots, web ants, web
                                indexers, bots, virus atau worm, atau segala
                                program apapun yang mungkin membuat beberapa
                                permintaan server per detik, menciptakan beban
                                berat atau menghambat operasi dan/atau kinerja
                                sistem maupun aplikasi milik PT. RAHMAT TUHAN
                                LESTARI;
                              </li>
                              <li class="subJudul3">
                                Menggunakan aplikasi pencarian atau pengambilan
                                kembali situs, perangkat manual atau otomatis
                                lainnya untuk mengambil (scraping), indeks
                                (indexing), survei (surveying), tambang data
                                (data mining), atau dengan cara apapun
                                memperbanyak atau menghindari struktur navigasi
                                atau presentasi dari sistem, aplikasi maupun
                                data milik PT. RAHMAT TUHAN LESTARI, atau
                                isinya;
                              </li>
                              <li class="subJudul3">
                                Menerbitkan, mendistribusikan atau memperbanyak
                                dengan cara apapun materi yang dilindungi hak
                                cipta, merek dagang. atau informasi lain yang
                                PT. RAHMAT TUHAN LESTARI miliki tanpa
                                persetujuan tertulis dan tegas terlebih dahulu
                                dari PT. RAHMAT TUHAN LESTARI atau pemilik hak
                                yang melisensikan hak-nya kepada PT. RAHMAT
                                TUHAN LESTARI;
                              </li>
                              <li class="subJudul3">
                                Menggunakan dan/atau mengakses secara tidak
                                resmi sistem, aplikasi maupun data milik PT.
                                RAHMAT TUHAN LESTARI untuk (a) merusak,
                                melemahkan atau membahayakan setiap aspek dari
                                aplikasi dan/atau sistem dan jaringan terkait,
                                dan/atau (b) membuat produk atau layanan
                                tandingan serupa menggunakan ide, fitur, fungsi
                                atau grafik menyerupai sistem, aplikasi maupun
                                data milik PT. RAHMAT TUHAN LESTARI;
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  Melakukan hal-hal yang mengakibatkan kerusakan
                                  terhadap sistem, aplikasi maupun data milik
                                  PT. RAHMAT TUHAN LESTARI dengan sengaja; atau
                                </li>
                                <li class="subJudul4">
                                  Melakukan hal-hal yang bertujuan untuk mencuri
                                  data PT. RAHMAT TUHAN LESTARI dan/atau
                                  Pelanggan.
                                </li>
                                <li class="subJudul4">
                                  Partner bertanggung jawab dan menjamin bahwa
                                  Partner tidak akan meminta data dan/atau
                                  informasi kepada pelanggan atau pihak lain
                                  melalui sarana apapun dengan mengatasnamakan
                                  PT. RAHMAT TUHAN LESTARI tanpa persetujuan
                                  tertulis terlebih dahulu dari PT. RAHMAT TUHAN
                                  LESTARI.
                                </li>
                              </ol>
                              <li class="subJudul3">
                                Partner bertanggung jawab dan menjamin
                                sepenuhnya atas kebenaran data dan/atau
                                keterangan dan/atau dokumen lainnya yang
                                diserahkan oleh Partner kepada PT. RAHMAT TUHAN
                                LESTARI berkaitan dengan penyediaan Layanan
                                berdasarkan Perjanjian Layanan, dan Partner
                                dengan ini membebaskan dan melepaskan PT. RAHMAT
                                TUHAN LESTARI dari segala klaim, gugatan dan
                                tuntutan hukum dalam bentuk apapun baik di dalam
                                maupun di luar pengadilan yang timbul dari pihak
                                manapun termasuk dari Partner sehubungan dengan
                                setiap informasi dan/atau data yang disampaikan
                                oleh Partner kepada PT. RAHMAT TUHAN LESTARI.
                              </li>
                            </ol>
                            <li class="subJudul2">Kerahasiaan</li>
                            <ol>
                              <li class="subJudul3">
                                Salah satu Pihak dapat memberikan Informasi
                                Rahasia kepada Pihak lainnya dalam Ketentuan
                                Penggunaan UR ini. Para Pihak sepakat bahwa
                                pemberian, penerimaan dan Penggunaan Informasi
                                Rahasia tersebut dilakukan sesuai dengan
                                ketentuan yang diatur dalam pasal ini.
                              </li>
                              <li class="subJudul3">
                                Seluruh Informasi Rahasia dan data terkait
                                dengan Ketentuan UR ini yang diterima oleh Para
                                Pihak harus dijaga kerahasiaannya dan Para Pihak
                                sepakat untuk tidak memberitahukan dan/atau
                                memberikan data sebagian ataupun seluruhnya
                                kepada pihak ketiga manapun juga atau
                                menggunakan informasi dan data tersebut untuk
                                kepentingan Para Pihak yang tidak berhubungan
                                dengan Ketentuan Penggunaan UR ini, kecuali:
                              </li>
                              <li class="subJudul3">
                                Atas persetujuan tertulis dari Pihak lainnya;
                              </li>
                              <li class="subJudul3">
                                Informasi yang diungkapkan oleh suatu Pihak
                                kepada pegawainya, banknya, konsultan
                                finansialnya, konsultan hukumnya, atau konsultan
                                lainnya sehubungan dengan Ketentuan Penggunaan
                                UR ini;
                              </li>
                              <li class="subJudul3">
                                Data tersebut sudah merupakan informasi milik
                                umum, bukan karena kesalahan Pihak yang menerima
                                informasi;
                              </li>
                              <li class="subJudul3">
                                Berdasarkan ketetapan pengadilan atau arbitrase;
                                dan/atau
                              </li>
                              <li class="subJudul3">
                                Diberikan oleh PT. RAHMAT TUHAN LESTARI kepada
                                Afiliasinya, Penyedia Layanan dan/atau pihak
                                yang memiliki hubungan kerjasama dengan PT.
                                RAHMAT TUHAN LESTARI untuk pelaksanaan
                                penyediaan Aplikasi UR dan/atau Layanan dan juga
                                untuk dapat memberikan penawaran produk atau
                                layanan lainnya dari PT. RAHMAT TUHAN LESTARI.
                              </li>
                              <li class="subJudul3">
                                Partner sepakat bahwa PT. RAHMAT TUHAN LESTARI
                                berhak untuk (1) memberitahukan segala data dan
                                Informasi Rahasia kepada vendor, subkontraktor,
                                agen, konsultan semata-mata untuk keperluan
                                pelaksanaan Ketentuan Penggunaan UR ini:
                                dan/atau (2) mengolah dan menggunakan data
                                Partner untuk (a) meningkatkan layanan PT.
                                RAHMAT TUHAN LESTARI dan/atau Afilasinya
                                dan/atau Penyedia Layanan kepada Partner
                                (termasuk untuk sistem fraud, fraud rules dan
                                keperluan audit korporasi): serta (b) untuk
                                melakukan penawaran produk dan/atau layanan PT.
                                RAHMAT TUHAN LESTARI dan/atau Afiliasinya
                                dan/atau Penyedia Layanan lainnya kepada
                                Partner.
                              </li>
                            </ol>
                            <li class="subJudul2">Penambahan Gerai</li>
                            <ol>
                              <li class="subJudul3">
                                Partner dapat melakukan penambahan Gerai secara
                                langsung melalui fitur penambahan Gerai yang
                                terdapat di dalam Platform UR sesuai dengan
                                kebutuhan Partner. Partner memahami bahwa
                                Partner wajib untuk memberikan detail informasi
                                Gerai yang akan Partner tambahkan ke dalam
                                Platform UR, termasuk namun tidak terbatas pada
                                nama Gerai, alamat Gerai dan nomor telepon
                                Gerai. Parner memahami penambahan gerai
                                mengikuti aturan main dari UR dapat dilihat
                                DISINI.
                              </li>
                              <li class="subJudul3">Saldo Partner</li>
                              <ol>
                                <li class="subJudul4">
                                  Limit maksimum untuk isi ulang Saldo Parner
                                  dalam 1 (satu) kali pengisian adalah sebesar
                                  Rp 10.000.000,- (sepuluh juta rupiah) dan
                                  Saldo Parner dapat menampung saldo hingga Rp
                                  100.000.000,- (seratus juta rupiah) dalam satu
                                  waktu.
                                </li>
                                <li class="subJudul4">
                                  Limit maksimum transaksi menggunakan Saldo
                                  Parner adalah sebesar Rp100.000.000,- (seratus
                                  juta rupiah) dalam 1 (satu) kali transaksi.
                                </li>
                                <li class="subJudul4">
                                  Parner UR tidak dapat mengalihkan,
                                  mentransfer, memberikan dan melakukan tindakan
                                  pengalihan dalam bentuk apapun atas jumlah
                                  dana dalam Saldo Parner kepada pihak lainnya
                                  baik yang berkaitan maupun tidak dengan Parner
                                  UR.
                                </li>
                                <li class="subJudul4">
                                  Parner UR dapat melakukan withdraw Saldo
                                  Parner dengan ketentuan <a href="">DISINI</a>.
                                </li>
                                <li class="subJudul4">
                                  Dalam hal ditemukan adanya dugaan pelanggaran
                                  terhadap Syarat dan Ketentuan UR, kecurangan,
                                  manipulasi atau kejahatan, Parner UR memahami
                                  dan menyetujui bahwa UR berhak melakukan
                                  tindakan pemeriksaan, penundaan dan/atau
                                  pembatalan terhadap penarikan dana yang
                                  dilakukan oleh Parner UR.
                                </li>
                                <li class="subJudul4">
                                  Pemeriksaan dan/atau penundaan penarikan dana
                                  sebagaimana dimaksud dalam huruf D di atas
                                  dapat dilakukan dalam jangka waktu yang
                                  sepenuhnya ditentukan oleh UR.
                                </li>
                              </ol>
                            </ol>
                          </ol>
                          <li class="subJudul1">TRANSAKSI</li>
                          <ol>
                            <li class="subJudul2">
                              Pembeli wajib bertransaksi melalui prosedur
                              transaksi yang telah ditetapkan oleh UR.
                            </li>
                            <li class="subJudul2">
                              Parner UR melakukan pembayaran dengan menggunakan
                              Saldo Parner, dan kemudian UR akan meneruskan dana
                              kepada Partner apabila tahapan transaksi jual beli
                              pada sistem UR telah selesai.
                            </li>
                            <li class="subJudul2">
                              Saat melakukan Penggunaan Barang Fisik dan/atau
                              Barang Digital, Parner UR menyetujui bahwa:
                            </li>
                            <li class="subJudul2">
                              Parner UR bertanggung jawab untuk membaca,
                              memahami, dan menyetujui informasi/deskripsi
                              keseluruhan Barang Fisik dan/atau Barang Digital
                              (termasuk didalamnya namun tidak terbatas pada
                              warna, kualitas, fungsi, dan lainnya) sebelum
                              membuat tawaran atau komitmen untuk membeli.
                            </li>
                            <li class="subJudul2">
                              Parner UR mengakui bahwa warna sebenarnya dari
                              Barang Fisik sebagaimana terlihat di
                              Situs/Aplikasi tergantung pada perangkat
                              elektronik Parner UR. UR telah melakukan upaya
                              terbaik untuk memastikan warna dalam foto-foto
                              yang ditampilkan di Situs/Aplikasi muncul seakurat
                              mungkin, tetapi tidak dapat menjamin bahwa
                              penampilan warna pada Situs/Aplikasi akan akurat.
                            </li>
                            <li class="subJudul2">
                              Parner UR masuk ke dalam kontrak yang mengikat
                              secara hukum untuk membeli Barang Fisik dan/atau
                              Barang Digital ketika Parner UR membeli suatu
                              barang.
                            </li>
                            <li class="subJudul2">
                              Parner UR tidak dapat mengajukan pengembalian
                              Barang Fisik setelah menandatangani
                              dokumen-dokumen terkait pengiriman Barang Fisik
                              dari Partner dan melewati proses guality control
                              oleh Parner UR.
                            </li>
                            <li class="subJudul2">
                              UR tidak mengalihkan kepemilikan secara hukum atas
                              Barang Fisik dan/atau Barang Digital dari Partner
                              kepada Parner UR.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami dan menyetujui bahwa ketersediaan
                              stok Barang Fisik merupakan tanggung jawab Partner
                              yang menawarkan Barang Fisik tersebut.
                              Ketersediaan stok Barang Fisik dapat berubah
                              sewaktu-waktu, sehingga dalam keadaan stok Barang
                              Fisik tidak sesuai atau kurang dari jumlah yang
                              dipesan oleh Parner UR, maka Partner akan tetap
                              melakukan pengiriman sesuai dengan stok yang
                              dimilikinya, dimana selisih pembayaran akibat
                              perbedaan jumlah stok dan pengiriman tersebut
                              dikembalikan kepada Parner UR melalui Saldo
                              Parner.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami sepenuhnya dan menyetujui bahwa
                              segala transaksi yang dilakukan antara Parner UR
                              dan Partner selain melalui Situs/Aplikasi dan/atau
                              tanpa sepengetahuan UR (melalui fasilitas/jaringan
                              pribadi, pengiriman pesan, pengaturan transaksi
                              khusus diluar Situs/Aplikasi atau upaya lainnya)
                              adalah merupakan tanggung jawab pribadi dari
                              Pembeli.
                            </li>
                            <li class="subJudul2">
                              UR memiliki kewenangan sepenuhnya untuk menolak
                              pembayaran tanpa pemberitahuan terlebih dahulu.
                            </li>
                            <li class="subJudul2">
                              Pembeli menyetujui untuk tidak memberitahukan atau
                              menyerahkan bukti pembayaran dan/atau data
                              pembayaran kepada pihak lain selain UR. Dalam hal
                              terjadi kerugian akibat pemberitahuan atau
                              penyerahan bukti pembayaran dan/atau data
                              pembayaran oleh Pembeli kepada pihak lain, maka
                              hal tersebut akan menjadi tanggung jawab Pembeli
                              secara pribadi.
                            </li>
                            <li class="subJudul2">
                              Parner UR wajib melakukan konfirmasi penerimaan
                              Barang Fisik, dengan cara menandatangani segala
                              dokumen terkait penerimaan Barang Fisik yang
                              disediakan oleh penyedia jasa layanan pengiriman
                              dan/atau Partner, setelah Parner UR menerima
                              pengiriman Barang Fisik yang dibeli.
                            </li>
                            <li class="subJudul2">
                              Parner UR memahami dan menyetujui bahwa Parner UR
                              hanya dapat melakukan penerimaan Barang secara
                              keseluruhan, apabila Parner UR tidak bersedia
                              menerima sebagian dari jumlah Barang yang
                              dikirimkan dikarenakan alasan tertentu, maka
                              Parner UR harus menolak penerimaan Barang secara
                              keseluruhan dimana penolakan dan alasannya akan
                              disebutkan dalam dokumen terkait pengiriman Barang
                              Fisik yang harus ditandatangani oleh Parner UR.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami dan menyetujui bahwa setiap klaim
                              yang dilayangkan setelah ditandatanganinya dokumen
                              penerimaan Barang Fisik oleh Parner UR, adalah
                              bukan menjadi tanggung jawab UR melainkan menjadi
                              tanggung jawab Parner UR secara pribadi.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami dan menyetujui bahwa setiap
                              masalah pengiriman Barang Fisik yang disebabkan
                              keterlambatan pembayaran adalah merupakan tanggung
                              jawab dari Pembeli.
                            </li>
                            <li class="subJudul2">
                              Pengembalian dana dari UR kepada Parner UR, baik
                              sebagian maupun seluruhnya, hanya dapat dilakukan
                              jika dalam keadaan-keadaan tertentu berikut ini:
                            </li>
                            <li class="subJudul2">
                              Parner UR menolak penerimaan seluruh Barang Fisik
                              yang dikirimkan oleh Partner, dikarenakan alasan
                              tertentu yang disebutkan dalam dokumen terkait
                              pengiriman Barang Fisik yang harus ditandatangani
                              oleh Parner UR.
                            </li>
                            <li class="subJudul2">
                              Masalah pengiriman Barang Fisik telah
                              teridentifikasi secara jelas dari Partner yang
                              mengakibatkan pesanan Barang Fisik tidak sampai,
                            </li>
                            <li class="subJudul2">
                              Partner tidak bisa menyanggupi pesanan karena
                              kehabisan stok Barang Fisik, maupun penyebab
                              lainnya,
                            </li>
                            <li class="subJudul2">
                              Partner sudah menyanggupi pengiriman Barang Fisik,
                              tetapi Partner tidak mengirimkan Barang Fisik
                              hingga batas waktu yang telah ditentukan.
                            </li>
                            <li class="subJudul2">
                              Penyelesaian permasalahan melalui Live Chat Parner
                              UR berupa keputusan untuk pengembalian dana kepada
                              Parner UR atau hasil keputusan dari UR.
                            </li>
                            <li class="subJudul2">
                              Apabila terjadi proses pengembalian dana, maka
                              pengembalian akan dilakukan melalui Saldo Parner
                              yang akan bertambah sesuai dengan jumlah
                              pengembalian dana, dimana pengembalian dana
                              tersebut akan dilakukan dalam jangka waktu
                              maksimal 1x24 jam sejak pengajuan atau jangka
                              waktu lainnya yang ditentukan oleh UR.
                            </li>
                            <li class="subJudul2">
                              UR berwenang memfasilitasi dan menentukan solusi
                              atas permasalahan- permasalahan transaksi yang
                              belum terselesaikan akibat tidak adanya
                              kesepakatan penyelesaian, baik antara Partner dan
                              Parner UR, dengan melihat bukti-bukti yang ada.
                              Keputusan UR adalah keputusan akhir yang tidak
                              dapat diganggu gugat dan mengikat Partner dan
                              Parner UR untuk mematuhinya.
                            </li>
                            <li class="subJudul2">
                              Parner UR wajib melakukan pembayaran dengan
                              nominal yang sesuai dengan jumlah tagihan beserta
                              kode unik (apabila ada) yang tertera pada halaman
                              pembayaran. UR tidak bertanggung jawab atas
                              kerugian yang dialami Parner UR apabila melakukan
                              pembayaran yang tidak sesuai dengan jumlah tagihan
                              yang tertera pada halaman pembayaran.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami sepenuhnya dan menyetujui bahwa
                              invoice yang diterbitkan adalah atas nama Partner.
                            </li>
                            <li class="subJudul2">Komisi</li>
                            <ol>
                              <li class="subJudul3">
                                UR tidak mengenakan komisi untuk transaksi yang
                                dilakukan melalui Situs UR, kecuali apabila
                                Pembeli menggunakan fitur atau layanan tertentu
                                yang dikenakan biaya layanan.
                              </li>
                              <li class="subJudul3">
                                Apabila dikemudian hari akan diberlakukan sistem
                                komisi dalam transaksi melalui Situs UR, maka
                                akan diberitahukan kepada Pembeli yang terkena
                                dampaknya melalui Situs UR.
                              </li>
                            </ol>
                            <li class="subJudul2">Harga</li>
                            <ol>
                              <li class="subJudul3">
                                Harga Barang yang terdapat dalam situs UR adalah
                                harga yang ditetapkan oleh Penjual. Penjual
                                dilarang memanipulasi harga barang dengan cara
                                apapun.
                              </li>
                              <li class="subJudul3">
                                Penjual dilarang menetapkan harga yang tidak
                                wajar pada Barang yang ditawarkan melalui Situs
                                UR. UR berhak untuk melakukan tindakan berupa
                                memindahkan Barang ke gudang. pemeriksaan.
                                penundaan. atau penurunan konten serta tindakan
                                lainnya berdasarkan penilaian sendiri dari UR
                                atas dasar penetapan harga yang tidak wajar.
                              </li>
                              <li class="subJudul3">
                                Pembeli memahami dan menyetujui bahwa kesalahan
                                keterangan harga dan informasi lainnya yang
                                disebabkan tidak terbaharuinya halaman situs UR
                                dikarenakan browser/ISP yang dipakai Pembeli
                                adalah tanggung jawab Pembeli.
                              </li>
                              <li class="subJudul3">
                                Penjual memahami dan menyetujui bahwa kesalahan
                                ketik yang menyebabkan keterangan harga atau
                                informasi lain menjadi tidak benar/sesuai adalah
                                tanggung jawab Penjual. Perlu diingat dalam hal
                                ini, apabila terjadi kesalahan pengetikan
                                keterangan harga Barang yang tidak disengaja,
                                Penjual berhak menolak pesanan Barang yang
                                dilakukan oleh pembeli.
                              </li>
                              <li class="subJudul3">
                                Pembeli memahami dan menyetujui bahwa setiap
                                masalah dan/atau perselisihan yang terjadi
                                akibat ketidaksepahaman antara Penjual dan
                                Pembeli tentang harga bukanlah merupakan
                                tanggung jawab UR.
                              </li>
                              <li class="subJudul3">
                                Dengan melakukan pemesanan melalui UR, Pembeli
                                menyetujui untuk membayar total biaya yang harus
                                dibayarkan sebagaimana tertera dalam halaman
                                pembayaran, yang terdiri dari harga barang.
                                ongkos kirim, dan biaya-biaya lain yang mungkin
                                timbul dan akan diuraikan secara tegas dalam
                                halaman pembayaran. Pembeli setuju untuk
                                melakukan pembayaran melalui metode pembayaran
                                yang telah dipilih sebelumnya oleh Pembeli.
                              </li>
                              <li class="subJudul3">
                                Batasan harga maksimal satuan untuk Barang yang
                                dapat ditawarkan adalah Rp. 100.000.000,-
                              </li>
                              <li class="subJudul3">
                                Situs UR untuk saat ini hanya melayani transaksi
                                jual beli Barang dalam mata uang Rupiah.
                              </li>
                              <li class="subJudul3 tulisanMerah">
                                Pembeli tidak berhak untuk menggunakan platform
                                UR sebagai platform untuk melakukan tindak
                                penipuan dengan tidak memberikan produk sesuai
                                dengan yang di specifikasi oleh penjual kepada
                                pembeli.
                              </li>
                            </ol>
                          </ol>
                          <li class="subJudul1">
                            KONTEN, INFORMASI DAN PROMOSI
                          </li>
                          <ol>
                            <li class="subJudul2">Konten Pihak Ketiga</li>
                            <ol>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI atau Penyedia Konten
                                Pihak Ketiga, tidak bertanggung jawab atas
                                bagian apapun dari isi Konten Pihak Ketiga.
                                Akses atau Penggunaan Partner terhadap Konten
                                Pihak Ketiga tersebut merupakan bentuk
                                persetujuan Partner untuk tunduk pada syarat dan
                                ketentuan yang ditetapkan oleh PT. RAHMAT TUHAN
                                LESTARI atau Penyedia Konten Pihak Ketiga
                                termasuk terhadap Kebijakan Privasi atau
                                kebijakan maupun syarat dan ketentuan yang
                                ditentukan oleh Penyedia Konten Pihak Ketiga.
                              </li>
                            </ol>
                            <li class="subJudul2">Manajemen Konten</li>
                            <ol>
                              <li class="subJudul3">
                                Dalam menggunakan UR, Partner wajib tunduk pada
                                pedoman Manajemen Konten yang dapat diperbaharui
                                dari waktu ke waktu sesuai dengan yang diatur
                                oleh PT. RAHMAT TUHAN LESTARI. Setiap
                                pengunggahan dan/atau perubahan atas Manajemen
                                Konten tersebut merupakan bentuk persetujuan
                                Partner untuk tunduk pada syarat dan ketentuan
                                yang ditetapkan oleh PT. RAHMAT TUHAN LESTARI,
                                termasuk terhadap Kebijakan Privasi PT. RAHMAT
                                TUHAN LESTARI atau pedoman terhadap Manajemen
                                Konten.
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI tidak bertanggung jawab
                                atas bagian apapun dari Manajemen Konten dan
                                Partner dengan ini membebaskan PT. RAHMAT TUHAN
                                LESTARI dari segala klaim apapun dari pihak
                                ketiga lainnya serta bertanggung jawab secara
                                penuh atas seluruh konten atau substansi yang
                                diunggahnya termasuk namun tidak terbatas pada
                                laporan atau pengaduan mengenai konten yang
                                diunggah oleh Partner pada Aplikasi UR.
                              </li>
                            </ol>
                            <li class="subJudul2">Penawaran Promosi</li>
                            <ol>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI, Penyedia Layanan,
                                Penyedia Konten Pihak Ketiga ataupun pihak
                                ketiga lainnya dapat melakukan penawaran Promosi
                                (“Penyedia Promo") dimana atas Promo tersebut
                                Partner dapat menukar dengan barang, Layanan
                                atau manfaat lain terkait dengan Penggunaan
                                Aplikasi UR. Dalam hal Penyedia Promo disediakan
                                oleh pihak selain PT. RAHMAT TUHAN LESTARI, PT.
                                RAHMAT TUHAN LESTARI tidak bertanggung jawab
                                atas bagian apapun dari isi Promosi tersebut.
                                Akses atau Penggunaan Partner terhadap Promosi
                                merupakan bentuk persetujuan Partner untuk
                                tunduk pada syarat dan ketentuan yang ditetapkan
                                oleh PT. RAHMAT TUHAN LESTARI atau Penyedia
                                Promo, termasuk terhadap Kebijakan Privasi atau
                                Penyedia Promo.
                              </li>
                              <li class="subJudul3">
                                Semua informasi, Promosi yang terdapat pada
                                Aplikasi UR hanya dimaksudkan untuk memberikan
                                Partner pengalaman terbaik ketika menggunakan
                                Aplikasi UR atau Layanan. Partner tidak boleh
                                menyalahgunakan Promosi yang Partner terima
                                selama Penggunaan Aplikasi UR
                              </li>
                              <li class="subJudul3">
                                Partner setuju untuk menggunakan Promosi
                                tersebut sesuai dengan maksud dari pemberian
                                Promosi serta syarat. ketentuan yang diatur oleh
                                PT. RAHMAT TUHAN LESTARI dan/ atau Penyedia
                                Layanan dan Partner tidak akan menyalahgunakan,
                                menggandakan, menguangkan, mengalihkan,
                                menggunakan untuk kepentingan komersial atau
                                mengambil keuntungan dengan tidak wajar dari
                                Promosi tersebut dengan bentuk atau cara apapun.
                              </li>
                              <li class="subJudul3">
                                Partner memahami bahwa Promosi tidak dapat
                                ditukar dengan uang tunai, memiliki masa
                                keberlakuan yang terbatas dan tunduk pada
                                ketentuan yang berlaku untuk setiap Promosi
                                tersebut.
                              </li>
                              <li class="subJudul3">
                                UR dengan ini memberikan Partner hak untuk
                                mengunggah dan/atau mengubah data dan/atau
                                informasi mengenai Gerai, termasuk namun tidak
                                terbatas pada katalog produk, harga produk, foto
                                produk dan nama produk, yang ditampilkan pada
                                Platform UR. Partner bertanggung jawab secara
                                penuh atas seluruh konten atau substansi yang
                                diunggahnya dan laporan atau pengaduan mengenai
                                konten yang diunggah oleh Partner tersebut.
                              </li>
                              <li class="subJudul3">
                                Ketika Partner mengunggah suatu konten ke dalam
                                Layanan, Partner dengan ini memberikan UR suatu
                                hak non-eksklusif di seluruh dunia yang berlaku
                                secara terus menerus, tidak dapat dibatalkan,
                                bebas royalti dan dapat disublisensikan untuk
                                keperluan pelaksanaan setiap dan semua semua hak
                                cipta, publisitas, merek dagang, hak basis data
                                dan Hak Kekayaan Intelektual yang Partner miliki
                                dalam konten, di media manapun yang dikenal
                                sekarang atau di masa depan. Selanjutnya, untuk
                                sepenuhnya diizinkan oleh hukum yang berlaku,
                                Partner mengesampingkan hak moral dan berjanji
                                untuk tidak menuntut hak-hak tersebut kepada UR.
                              </li>
                              <li class="subJudul3">
                                UR atau pihak lain yang bekerja sama dengan UR
                                dapat memberikan penawaran atau promosi
                                (“Penawaran”) yang dapat ditukar dengan barang,
                                Layanan atau manfaat lain terkait dengan
                                Penggunaan Platform UR. Dalam hal Penawaran
                                disediakan oleh pihak lain yang bekerja sama
                                dengan UR (“Penyedia Penawaran”), UR tidak
                                bertanggung jawab atas bagian apapun dari isi
                                Penawaran tersebut. Akses atau Penggunaan
                                Partner terhadap Penawaran merupakan bentuk
                                persetujuan Partner untuk tunduk pada syarat dan
                                ketentuan yang ditetapkan oleh UR atau Penyedia
                                Penawaran, termasuk terhadap Kebijakan Privasi
                                atau Penyedia Penawaran.
                              </li>
                              <li class="subJudul3">
                                Semua informasi dan Penawaran yang terdapat di
                                Platform UR hanya dimaksudkan untuk memberikan
                                Partner pengalaman terbaik ketika menggunakan
                                Platform UR dan/atau Layanan. Partner tidak
                                boleh menyalahgunakan Penawaran yang Partner
                                terima selama Penggunaan Platform UR dan/atau
                                Layanan.
                              </li>
                              <li class="subJudul3">
                                Partner setuju untuk menggunakan Penawaran
                                tersebut sesuai dengan syarat, ketentuan, dan
                                maksud dari pemberian Penawaran dan tidak akan
                                menyalahgunakan, menguangkan, mengalihkan,
                                menggunakan untuk kepentingan komersial atau
                                mengambil keuntungan dengan tidak wajar dari
                                Penawaran tersebut dengan bentuk atau cara
                                apapun.
                              </li>
                              <li class="subJudul3">
                                Partner memahami bahwa Penawaran tidak dapat
                                ditukar dengan uang tunai, memiliki masa
                                keberlakuan yang terbatas dan tunduk pada
                                ketentuan yang berlaku untuk setiap Penawaran
                                tersebut.
                              </li>
                            </ol>
                            <li class="subJudul2">Jenis Barang dan Jasa</li>
                            <ol>
                              <li class="subJudul3">
                                Berikut ini adalah daftar jenis Barang dan Jasa
                                yang dilarang dan/atau dibatasi untuk
                                diperdagangkan oleh Penjual pada Situs UR:Segala
                                jenis obat-obatan maupun zat-zat lain yang
                                dilarang ataupun dibatasi peredarannya menurut
                                ketentuan hukum yang berlaku, termasuk namun
                                tidak terbatas pada ketentuan Undang-Undang
                                Narkotika, Undang-Undang Psikotropika, dan
                                Undang-Undang Kesehatan. Termasuk pula dalam
                                ketentuan ini adalah obat keras, obat-obatan
                                yang memerlukan resep dokter, obat bius dan
                                sejenisnya, atau obat yang tidak memiliki izin
                                edar dari Badan Pengawas Obat dan Makanan
                                (BPOM).
                              </li>
                              <li class="subJudul3">
                                Kosmetik dan makanan minuman yang membahayakan
                                keselamatan Pembelinya, ataupun yang tidak
                                mempunyai izin edar dari Badan Pengawas Obat dan
                                Makanan (BPOM).
                              </li>
                              <li class="subJudul3">
                                Bahan yang diklasifikasikan sebagai Bahan
                                Berbahaya menurut Peraturan Menteri Perdagangan
                                yang berlaku.
                              </li>
                              <li class="subJudul3">
                                Jenis Produk tertentu yang wajib memiliki:
                              </li>
                              <ol>
                                <li class="subJudul4">SNI</li>
                                <li class="subJudul4">
                                  Petunjuk Penggunaan dalam Bahasa Indonesia;
                                  atau
                                </li>
                                <li class="subJudul4">
                                  Label dalam Bahasa Indonesia.
                                </li>
                              </ol>
                              <li class="subJudul3">
                                Sementara yang diperjualbelikan tidak
                                mencantumkan hal-hal tersebut.
                              </li>
                              <li class="subJudul3">
                                Barang-barang lain yang kepemilikannya ataupun
                                peredarannya melanggar ketentuan hukum yang
                                berlaku di Indonesia.
                              </li>
                              <li class="subJudul3">
                                Barang yang merupakan hasil pelanggaran Hak
                                Cipta, termasuk namun tidak terbatas dalam media
                                berbentuk buku, CD/DVD/VCD, informasi dan/atau
                                dokumen elektronik, serta media lain yang
                                bertentangan dengan Undang-Undang Hak Cipta.
                              </li>
                              <li class="subJudul3">
                                Barang dewasa yang bersifat seksual berupa obat
                                perangsang, alat bantu seks yang mengandung
                                konten pornografi, serta obat kuat dan
                                obat-obatan dewasa, baik yang tidak memiliki
                                izin edar BPOM maupun yang peredarannya dibatasi
                                oleh ketentuan hukum yang berlaku:
                              </li>
                              <ol>
                                <li class="subJudul4">Minuman beralkohol.</li>
                                <li class="subJudul4">
                                  Iklan dan segala bentuk tulisan yang dapat
                                  berpengaruh negatif terhadap pemakaian situs
                                  ini.
                                </li>
                                <li class="subJudul4">Pakaian dalam bekas.</li>
                                <li class="subJudul4">
                                  Senjata api, senjata tajam, senapan angin, dan
                                  segala macam senjata.
                                </li>
                                <li class="subJudul4">
                                  Dokumen pemerintahan dan perjalanan
                                </li>
                                <li class="subJudul4">Seragam pemerintahan.</li>
                                <li class="subJudul4">Badgian/Organ manusia</li>
                                <li class="subJudul4">
                                  Mailing list dan informasi pribadi.
                                </li>
                                <li class="subJudul4">
                                  Barang-Barang yang melecehkan pihak/ras
                                  tertentu atau dapat merendahkan martabat orang
                                  lain.
                                </li>
                                <li class="subJudul4">Pestisida.</li>
                                <li class="subJudul4">Atribut kepolisian.</li>
                                <li class="subJudul4">
                                  Barang hasil tindak pencurian.
                                </li>
                                <li class="subJudul4">
                                  Pembuka kunci dan segala aksesori penunjang
                                  tindakan perampokan/pencurian.
                                </li>
                                <li class="subJudul4">
                                  Barang yang dapat dan atau mudah meledak,
                                  menyala atau terbakar sendiri.
                                </li>
                                <li class="subJudul4">
                                  Barang cetakan/rekaman yang isinya dapat
                                  mengganggu keamanan & ketertiban serta
                                  stabilitas nasional.
                                </li>
                                <li class="subJudul4">Hewan</li>
                                <li class="subJudul4">
                                  Uang tunai termasuk valuta asing kecuali
                                  Penjual memiliki dan dapat mencantumkan izin
                                  sebagai Penyelenggara Kegiatan Usaha Penukaran
                                  Valuta Asing Bukan Bank berdasarkan Peraturan
                                  Bank Indonesia No.18/20/PBI/2016 dan/atau
                                  peraturan lainnya yang terkait dengan
                                  penukaran valuta asing.
                                </li>
                                <li class="subJudul4">Materai</li>
                                <li class="subJudul4">
                                  Pengacak sinyal, penghilang sinyal, dan/atau
                                  alat-alat lain yang dapat mengganggu sinyal
                                  atau jaringan telekomunikasi
                                </li>
                                <li class="subJudul4">
                                  Perlengkapan dan peralatan judi.
                                </li>
                                <li class="subJudul4">
                                  Jimat-jimat, benda-benda yang diklaim
                                  berkekuatan gaib dan memberi ilmu kesaktian.
                                </li>
                                <li class="subJudul4">
                                  Tiket pertunjukan, termasuk namun tidak
                                  terbatas pada tiket konser, baik fisik maupun
                                  non fisik.
                                </li>
                                <li class="subJudul4">
                                  Dokumen-dokumen resmi seperti Sertifikat
                                  Toefl, Ijazah, Surat Dokter, Kwitansi, dan
                                  lain sebagainya.
                                </li>
                                <li class="subJudul4">
                                  Segala jenis Jasa kecuali untuk penawaran yang
                                  berasal dari UR dan afiliasinya termasuk namun
                                  tidak terbatas pada jasa print, jasa
                                  kebersihan, jasa wedding dan parenting.
                                </li>
                                <li class="subJudul4">
                                  Segala jenis Barang yang isinya tidak pasti,
                                  bersifat acak dan/atau undian, termasuk namun
                                  tidak terbatas pada Produk Kotak Misteri.
                                  Ketentuan tersebut di kecualikan untuk
                                  Official Store dan Afiliasi UR.
                                </li>
                              </ol>
                            </ol>
                          </ol>
                          <li class="subJudul1">TANGGUNG JAWAB</li>
                          <ol>
                            <li class="subJudul2">
                              Hak dan kewajiban PT. RAHMAT TUHAN LESTARI
                            </li>
                            <ol>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI wajib menyediakan akses
                                kepada Partner dalam hal Partner telah melalui
                                dan lolos proses pendaftaran sebagaimana diatur
                                dalam Ketentuan Penggunaan UR;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI wajib dengan upaya
                                terbaiknya menjaga kerahasiaan data dan
                                informasi termasuk Manajemen Konten yang diunduh
                                ke dalam Aplikasi UR dengan tunduk pada
                                Ketentuan Penggunaan UR dan Kebijakan Privasi;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI wajib dengan upaya
                                terbaiknya menjaga sistem Aplikasi UR agar tetap
                                stabil dan dapat digunakan oleh Partner;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak mendapatkan dan
                                akan menampilkan Manajemen Konten yang diunduh
                                dan dimasukkan oleh Partner ke Aplikasi UR,
                                termasuk apabila diminta secara tertulis oleh
                                Partner;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak menambahkan
                                dan/atau mengurangi fitur-fitur yang terdapat di
                                dalam Aplikasi UR dari waktu ke waktu untuk
                                meningkatkan kualitas pelayanan Aplikasi UR;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak menggunakan Hak
                                Kekayaan Intelektual milik Partner yang dapat
                                digunakan PT. RAHMAT TUHAN LESTARI dan/atau
                                Afiliasinya terkait dengan tampilan pada
                                Aplikasi UR maupun fitur-fitur di dalamnya atau
                                untuk tujuan pemasaran sehubungan dengan
                                Ketentuan Penggunaan UR ini;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak menempatkan
                                materi pemasaran Promosi ke dalam Aplikasi UR;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak menetapkan SOP
                                untuk kebutuhan operasional yang dapat diubah
                                atau ditambahkan dari waktu ke waktu sebagaimana
                                disampaikan kepada Partner;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak menyediakan
                                layanan Partner Call Unit (MCU) untuk melayani
                                pertanyaan Partner terkait Penggunaan Aplikasi
                                UR;
                              </li>
                              <li class="subJudul3">
                                PT. RAHMAT TUHAN LESTARI berhak menolak atau
                                membatasi akses ke Akun UR karena alasan
                                tertentu sebagaimana diatur dalam Ketentuan
                                Penggunaan UR ini; dan
                              </li>
                              <li class="subJudul3">
                                Hak dan kewajiban lain yang dinyatakan di dalam
                                SOP dan Kebijakan Privasi yang merupakan satu
                                kesatuan dari Ketentuan Penggunaan UR ini.
                              </li>
                              <li class="subJudul3">
                                Hak dan Kewajiban Partner
                              </li>
                              <li class="subJudul3">
                                Partner berhak untuk menerima edukasi terkait
                                Penggunaan Aplikasi UR;
                              </li>
                              <li class="subJudul3">
                                Partner wajib melakukan pembayaran atas setiap
                                Layanan yang digunakan sesuai dengan ketentuan
                                yang disepakati dan diatur oleh Syarat dan
                                Ketentuan Khusus masing-masing Layanan;
                              </li>
                              <li class="subJudul3">
                                Partner wajib memberikan informasi yang
                                diperlukan dan diminta oleh PT. RAHMAT TUHAN
                                LESTARI ataupun Penyedia Jasa, Penyedia Promo
                                dan/ atau Penyedia Konten Pihak Ketiga dalam
                                Penggunaan Aplikasi UR, Layanan, Promosi maupun
                                Konten Pihak Ketiga;
                              </li>
                              <li class="subJudul3">
                                Partner wajib mengirimkan pemberitahuan kepada
                                PT. RAHMAT TUHAN LESTARI apabila terdapat
                                perubahan terhadap informasi yang dicantumkan
                                dalam Aplikasi UR maupun informasi atau data
                                yang diberikan kepada PT. RAHMAT TUHAN LESTARI;
                              </li>
                              <li class="subJudul3">
                                Partner wajib memberikan pemberitahuan dalam hal
                                terdapat perubahan pemberian akses kepada pihak
                                terkait yang tercantum dalam LOA dimana Partner
                                wajib melakukan pemberitahuan paling lambat 3
                                (tiga) Hari Kerja sebelum terjadinya perubahan
                                dan Partner wajib menandatangani LOA kembali
                                atas perubahan tersebut;
                              </li>
                              <li class="subJudul3">
                                Partner wajib bertanggung jawab atas Penggunaan
                                dan akses atas Aplikasi UR, termasuk namun tidak
                                terbatas pada pemilihan fitur, Penggunaan
                                Layanan dan Konten Pihak Ketiga, serta
                                penerimaan Promosi dan Penggunaan Metode
                                Pembayaran;
                              </li>
                              <li class="subJudul3">
                                Partner wajib memastikan bahwa setiap informasi
                                yang diterima dari PT. RAHMAT TUHAN LESTARI
                                hanya digunakan untuk keperluan Penggunaan
                                Layanan dan tidak dipergunakan untuk tujuan lain
                                selain yang ditentukan dalam Ketentuan
                                Penggunaan UR dan Syarat dan Ketentuan Khusus
                                lainnya yang berlaku atas Penggunaan Laynaan
                                yang dipilih oleh Partner dan tidak dipergunakan
                                untuk tujuan lain;
                              </li>
                              <li class="subJudul3">
                                Partner wajib untuk melaporkan kepada PT. RAHMAT
                                TUHAN LESTARI apabila terdapat indikasi
                                penyalahgunaan Aplikasi UR;
                              </li>
                              <li class="subJudul3">
                                Partner wajib mematuhi ketentuan mengenai
                                pelaksanaan dan prosedur yang berkaitan dengan
                                Aplikasi UR dan Layanan masing-masing, baik yang
                                tercantum di dalam Ketentuan Penggunaan UR
                                maupun Syarat dan Ketentuan Khusus maupun
                                dokumen-dokumen pendukung lainnya, termasuk
                                namun tidak terbatas pada SOP yang dapat
                                disampaikan oleh PT. RAHMAT TUHAN LESTARI atau
                                Penyedia Layanan dari waktu ke waktu;
                              </li>
                              <li class="subJudul3">
                                Partner wajib menjaga kerahasiaan Informasi
                                Rahasia dan sepakat untuk tidak memberitahukan
                                dan/atau memberikan Informasi Rahasia, baik
                                sebagian ataupun seluruhnya. kepada pihak ketiga
                                manapun juga. Untuk menghindari keraguan.
                                Ketentuan kerahasiaan sebagaimana diatur dalam
                                Ayat ini akan berlaku dan mengikat sejak
                                Ketentuan Penggunaan UR ini berlaku efektif dan
                                akan tetap bertahan dan berlaku sampai dengan 5
                                (lima) tahun setelah Ketentuan Penggunaan UR ini
                                berakhir
                              </li>
                              <li class="subJudul3">
                                Hak dan kewajiban lain yang dinyatakan di dalam
                                Ketentuan Penggunaan UR, SOP dan Kebijakan
                                Privasi yang merupakan satu kesatuan dari Syarat
                                dan Ketentuan UR ini.
                              </li>
                              <li class="subJudul3">
                                Berikut merupakan kewajiban dari PT. RAHMAT
                                TUHAN LESTARI dan Partner berdasarkan Ketentuan
                                Penggunaan UR ini:
                              </li>
                              <li class="subJudul3">
                                Menerima informasi secara lengkap dan jelas jika
                                terjadi kendala dan/atau masalah pada sistem
                                yang dimiliki oleh masing-masing Pihak.
                              </li>
                              <li class="subJudul3">
                                Melaksanakan kewajiban-kewajibannya berdasarkan
                                Syarat dan Ketentuan Khusus sesuai dengan
                                Layanan yang dipilih, termasuk memastikan bahwa
                                seluruh karyawan dan/atau perwakilannya akan
                                mematuhi seluruh ketentuan yang diatur dalam
                                Ketentuan Penggunaan UR ini.
                              </li>
                              <li class="subJudul3">
                                Menaati peraturan perundang-undangan yang
                                berlaku di Negara Republik Indonesia.
                              </li>
                              <li class="subJudul3">
                                Menjaga baik, reputasi, dan citra masing-masing
                                pihak.
                              </li>
                            </ol>
                            <li class="subJudul2">Batasan Tanggung Jawab</li>
                            <ol>
                              <li class="subJudul3">
                                Partner bertanggung jawab secara penuh atas
                                setiap kerugian dan/atau klaim yang timbul dari
                                Penggunaan Aplikasi UR, Layanan, Konten Pihak
                                Ketiga, Penawaran atau Metode Pembayaran yang
                                dipilih, diakses maupun digunakan oleh Akun UR
                                Partner, baik oleh Partner atau pihak lain yang
                                menggunakan Akun UR Partner, baik dengan cara
                                yang sesuai maupun yang bertentangan dengan
                                ketentuan dalam Formulir Partner, Ketentuan
                                Penggunaan UR ini, Syarat dan Ketentuan Khusus,
                                Kebijakan Privasi, termasuk syarat dan ketentuan
                                khusus masing-masing dan kebijakan privasi yang
                                ditentukan oleh Penyedia Layanan, Penyedia
                                Konten Pihak Ketiga, Penyedia Promosi dan
                                Penyedia Metode Pembayaran, atau peraturan
                                perundang-undangan yang berlaku, termasuk namun
                                tidak terbatas untuk tujuan anti pencucian uang,
                                anti pendanaan terorisme, aktivitas kriminal,
                                penipuan dalam bentuk apapun (termasuk namun
                                tidak terbatas pada kegiatan phishing dan/atau
                                social engineering), pelanggaran hak kekayaan
                                intelektual, dan/atau aktivitas lain yang
                                merugikan publik dan/atau pihak lain manapun
                                atau yang dapat atau dianggap dapat merusak
                                reputasi PT. RAHMAT TUHAN LESTARI maupun
                                Afiliasinya.
                              </li>
                              <li class="subJudul3">
                                Partner memahami dan menyetujui bahwa seluruh
                                resiko yang timbul dari Penggunaan Aplikasi UR,
                                Layanan, Konten Pihak Ketiga, Penawaran dan
                                Metode Pembayaran sepenuhnya menjadi tanggung
                                jawab Partner dan Partner dengan ini setuju
                                untuk melepaskan PT. RAHMAT TUHAN LESTARI dari
                                segala tuntutan apapun sehubungan dengan
                                kerusakan, gangguan, atau bentuk lain dari
                                gangguan sistem yang disebabkan oleh akses tidak
                                resmi oleh pihak lain.
                              </li>
                              <li class="subJudul3">
                                Partner secara tegas membebaskan PT. RAHMAT
                                TUHAN LESTARI, termasuk namun tidak terbatas
                                pada, pejabat, direktur, komisaris, karyawan dan
                                agen PT. RAHMAT TUHAN LESTARI, dari dari setiap
                                dan semua kewajiban, konsekuensi, kerugian baik
                                materiil atau immateriil, tuntutan, biaya-biaya
                                (termasuk biaya advokat) atau tanggung jawab
                                hukum lainnya yang timbul atau mungkin timbul
                                akibat pelanggaran Partner terhadap Ketentuan
                                Penggunaan UR, Syarat dan Ketentuan Khusus
                                dan/atau syarat dan ketentuan lain yang
                                ditentukan oleh Penyedia Layanan, Penyedia
                                Konten Pihak Ketiga, Penyedia Promosi atau
                                Penyedia Metode Pembayaran.
                              </li>
                              <li class="subJudul3">
                                Partner bertanggung jawab penuh atas keputusan
                                yang Partner buat untuk menggunakan atau
                                mengakses Platform UR, Layanan dan Penawaran.
                                Partner bertanggung jawab secara penuh atas
                                setiap kerugian dan/atau klaim yang timbul dari
                                Penggunaan Platform UR, Layanan dan/atau
                                Penawaran melalui Akun, baik oleh Partner atau
                                pihak ketiga lainnya yang Partner otorisasi
                                untuk menggunakan Akun, dengan cara yang
                                bertentangan dengan Ketentuan Penggunaan,
                                Kebijakan Privasi, Ketentuan Penggunaan
                                tambahan, termasuk syarat dan ketentuan dan
                                kebijakan privasi yang ditentukan Parner Pihak
                                Ketiga Penyedia Layanan, Penyedia Penawaran dan
                                peraturan perundang-undangan yang berlaku,
                                termasuk namun tidak terbatas untuk tujuan anti
                                pencucian uang, anti pendanaan terorisme,
                                aktivitas kriminal, penipuan dalam bentuk apapun
                                (termasuk namun tidak terbatas pada kegiatan
                                phishing dan/atau social engineering),
                                pelanggaran Hak Kekayaan Intelektual, dan/atau
                                aktivitas lain yang merugikan publik dan/atau
                                pihak lain manapun atau yang dapat atau dianggap
                                dapat merusak reputasi UR.
                              </li>
                              <li class="subJudul3">
                                Partner memahami dan menyetujui bahwa UR dapat
                                meminta tambahan Dokumen Administratif selama
                                Partner menggunakan Platform UR dan Layanan.
                                Partner wajib untuk memberikan Dokumen
                                Administratif tersebut dengan cara mengunggahnya
                                ke dalam Platform UR dan/atau dengan cara lain
                                yang diinformasikan oleh UR.
                              </li>
                              <li class="subJudul3">
                                Dalam menggunakan Platform UR dan Layanan,
                                Partner wajib untuk memiliki seluruh lisensi dan
                                perizinan yang diperlukan untuk terlibat dalam
                                kegiatan usaha penyediaan barang dan/atau jasa
                                sesuai dengan bidang usaha Partner. Partner
                                lebih lanjut bertanggung jawab penuh apabila
                                dikemudian hari kegiatan usaha Partner
                                dinyatakan melanggar hukum, peraturan
                                perundang-undangan dan ketertiban umum.
                              </li>
                              <li class="subJudul3">
                                Partner bertanggung jawab sepenuhnya atas
                                Penggunaan Platform UR dan Layanan dalam cara
                                yang wajar dan setuju untuk tidak meminta
                                pertanggungjawaban dari UR atas segala tuntutan,
                                kerugian, dan/atau kehilangan sehubungan dengan
                                Penggunaan Platform UR dan Layanan dalam cara
                                yang tidak wajar atau tidak sesuai dengan
                                Ketentuan Penggunaan ini dan peraturan
                                perundang-undangan yang berlaku. Partner setuju
                                bahwa Partner tidak akan mengaitkan Platform UR
                                dan Layanan dengan aktivitas apapun yang mungkin
                                dianggap, termasuk namun tidak terbatas,
                                sebagai:
                              </li>
                              <li class="subJudul3">
                                Melanggar Hak Kekayaan Intelektual pihak ketiga
                                manapun;
                              </li>
                              <li class="subJudul3">
                                Melanggar hak publikasi atau hak privasi pihak
                                ketiga manapun;
                              </li>
                              <li class="subJudul3">
                                Ilegal atau mendorong atau sehubungan dengan,
                                baik secara langsung maupun tidak langsung,
                                tindakan ilegal apapun;
                              </li>
                              <li class="subJudul3">
                                Menjual Penggunaan, akses, atau mereproduksi
                                Platform UR dan Layanan kepada pihak ketiga
                                manapun; dan/atau
                              </li>
                              <li class="subJudul3">
                                Suatu penipuan, memperdaya, atau menyesatkan
                                dengan cara apapun.
                              </li>
                              <li class="subJudul3">
                                UR menyediakan Platform UR sebagaimana adanya
                                dan UR tidak menyatakan atau menjamin bahwa
                                keandalan, ketepatan waktu, kualitas,
                                kesesuaian, ketersediaan, akurasi, kelengkapan
                                atau keamanan dari Platform UR dapat memenuhi
                                kebutuhan dan akan sesuai dengan harapan
                                Partner, termasuk namun tidak terbatas pada
                                Layanan dan Penawaran yang sepenuhnya menjadi
                                tanggung jawab Parner Pihak Ketiga Penyedia
                                Layanan dan Penyedia Penawaran. UR tidak
                                bertanggung jawab atas setiap kerugian atau
                                kerusakan yang disebabkan oleh setiap kegagalan
                                atau kesalahan yang dilakukan oleh Parner Pihak
                                Ketiga Penyedia Layanan dan Penyedia Penawaran
                                ataupun kegagalan atau kesalahan Partner dalam
                                mematuhi Ketentuan Penggunaan UR, Parner Pihak
                                Ketiga Penyedia Layanan dan Penyedia Penawaran.
                              </li>
                              <li class="subJudul3">
                                Platform UR dapat mengalami keterbatasan,
                                penundaan, dan masalah-masalah lain yang
                                terdapat dalam Penggunaan internet dan
                                komunikasi elektronik, termasuk namun tidak
                                terbatas pada telepon genggam, tablet dan/atau
                                perangkat komputer Partner, Parner Pihak Ketiga
                                Penyedia Layanan dan/atau Penyedia Penawaran
                                rusak, tidak terhubung dengan internet, berada
                                di luar jangkauan, dimatikan atau tidak
                                berfungsi. UR tidak bertanggung jawab atas
                                keterlambatan, kegagalan pengiriman, kerusakan
                                atau kerugian yang diakibatkan oleh
                                masalah-masalah tersebut.
                              </li>
                              <li class="subJudul3">
                                UR akan berusaha semaksimal mungkin untuk
                                mengelola dan merawat Platform UR dan Layanan
                                agar tetap lancar serta atas kebijakannya dapat
                                memberikan Partner rekomendasi perangkat keras
                                dan konfigurasi jaringan yang sesuai dengan
                                Platform UR dan Layanan milik UR. UR dengan ini
                                bertanggung jawab untuk memberikan bantuan
                                teknis dan/atau operasional kepada Partner dalam
                                hal terjadi gangguan yang masih termasuk dalam
                                batas kewajaran sesuai dengan standar servis UR,
                                termasuk memberikan edukasi terkait Penggunaan
                                Platform UR dan Layanan kepada Partner.
                              </li>
                              <li class="subJudul3">
                                Partner mengakui bahwa UR dapat menggunakan jasa
                                pihak ketiga untuk menyediakan perangkat keras,
                                perangkat lunak, jaringan, sambungan, teknologi
                                dan/atau jasa lainnya yang bertujuan untuk
                                menunjang Penggunaan Platform UR dan/atau
                                Layanan. Tindakan dan kelalaian atas jasa pihak
                                ketiga adalah di luar kendali UR, dan UR tidak
                                menerima tanggung jawab atas kerugian atau
                                kerusakan yang diderita sebagai akibat dari
                                segala tindakan atau kelalaian pihak ketiga
                                tersebut.
                              </li>
                              <li class="subJudul3">
                                UR tidak berkewajiban untuk mengawasi akses atau
                                Penggunaan Partner atas Platform UR. Namun dari
                                waktu ke waktu UR dapat melakukan pengawasan
                                untuk memastikan kelancaran Penggunaan Platform
                                UR dan untuk memastikan kepatuhan terhadap
                                Ketentuan Penggunaan ini, peraturan
                                perundang-undangan yang berlaku, putusan
                                pengadilan, dan/atau ketentuan lembaga
                                administratif atau badan pemerintahan lainnya.
                              </li>
                              <li class="subJudul3">
                                UR tidak mempunyai kewajiban apapun, termasuk
                                untuk mengambil tindakan lebih jauh atau
                                tindakan hukum yang dianggap perlu oleh Partner,
                                Parner Pihak Ketiga Penyedia Layanan, atau
                                Penyedia Penawaran, terhadap setiap permasalahan
                                atau perselisihan yang timbul antara Partner dan
                                Parner Pihak Ketiga Penyedia Layanan, atau
                                Penyedia Penawaran. Tetapi, UR akan menjembatani
                                Partner dan Parner Pihak Ketiga Penyedia Layanan
                                atau Penyedia Penawaran atas setiap permasalahan
                                atau perselisihan yang timbul antara Partner dan
                                Parner Pihak Ketiga Penyedia Layanan atau
                                Penyedia Penawaran dengan upaya wajar yang
                                diperlukan. Ketika UR memfasilitasi penyelesaian
                                permasalahan atau perselisihan antara Partner
                                dan Parner Pihak Ketiga Penyedia Layanan, atau
                                Penyedia Penawaran, UR tidak bertindak sebagai
                                mediator dan hal tersebut tidak menimbulkan
                                kewajiban lebih jauh apapun terhadap UR.
                              </li>
                              <li class="subJudul3">
                                UR selalu berupaya untuk menjaga Layanan UR
                                aman, nyaman, dan berfungsi dengan baik, tapi
                                kami tidak dapat menjamin Operasi terus-menerus
                                atau akses ke Layanan kami dapat selalu
                                sempurna. Informasi dan data dalam situs UR
                                memiliki kemungkinan tidak terjadi secara real
                                time.
                              </li>
                              <li class="subJudul3">
                                Sejauh diizinkan oleh hukum yang berlaku, UR
                                (termasuk Induk Perusahaan. direktur. dan
                                karyawan) adalah tidak bertanggung jawab, dan
                                Anda setuju untuk tidak menuntut UR bertanggung
                                jawab, atas segala kerusakan atau kerugian
                                (termasuk namun tidak terbatas pada hilangnya
                                uang, reputasi, keuntungan, atau kerugian tak
                                berwujud lainnya) yang diakibatkan secara
                                langsung atau tidak langsung dari:
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  Harga, Pengiriman atau petunjuk lain yang
                                  tersedia dalam layanan UR.
                                </li>
                                <li class="subJudul4">
                                  Keterlambatan atau gangguan dalam Layanan UR.
                                </li>
                                <li class="subJudul4">
                                  Pelanggaran Hak atas Kekayaan Intelektual.
                                </li>
                                <li class="subJudul4 backgroundTulisanKuning">
                                  Setiap penyalahgunaan Barang yang sudah dibeli
                                  pihak Pembeli.
                                </li>
                                <li class="subJudul4">
                                  Virus atau perangkat lunak berbahaya lainnya
                                  (bot, script. automation tool selain fitur
                                  Power Merchant, Power Merchant Pro, hacking
                                  tool) yang diperoleh dengan mengakses, atau
                                  menghubungkan ke layanan UR.
                                </li>
                                <li class="subJudul4">
                                  Gangguan. bug. kesalahan atau ketidakakuratan
                                  apapun dalam Layanan UR.
                                </li>
                                <li class="subJudul4">
                                  Kerusakan pada perangkat keras Anda dari
                                  Penggunaan setiap Layanan UR.
                                </li>
                                <li class="subJudul4">
                                  Isi, tindakan, atau tidak adanya tindakan dari
                                  pihak ketiga. termasuk terkait dengan Produk
                                  yang ada dalam situs UR yang diduga palsu.
                                </li>
                                <li class="subJudul4">
                                  Tindak penegakan yang diambil sehubungan
                                  dengan akun Pembeli.
                                </li>
                                <li class="subJudul4">
                                  Adanya tindakan peretasan yang dilakukan oleh
                                  pihak ketiga kepada akun Pembeli.
                                </li>
                                <li class="subJudul4">Ganti Rugi</li>
                                <ol>
                                  <li class="subJudul5">
                                    Pembeli akan melepaskan UR dari tuntutan
                                    ganti rugi dan menjaga UR (termasuk Induk
                                    Perusahaan, direktur, dan karyawan) dari
                                    setiap klaim atau tuntutan, termasuk biaya
                                    hukum yang wajar. yang dilakukan oleh pihak
                                    ketiga yang timbul dalam hal Anda melanggar
                                    Perjanjian ini, Penggunaan Layanan UR yang
                                    tidak semestinya dan/ atau pelanggaran Anda
                                    terhadap hukum atau hak-hak pihak ketiga.
                                  </li>
                                </ol>
                              </ol>
                            </ol>
                            <li class="subJudul2">Pernyataan Nasabah</li>
                            <ol>
                              <li class="subJudul3">
                                Nasabah merupakan Warga Negara Indonesia dan
                                Nasabah hanya merupakan wajib pajak di Negara
                                Kesatuan Republik Indonesia serta tidak termasuk
                                kriteria sebagai berikut:
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  berkewarganegaraan Amerika Serikat (AS);
                                </li>
                                <li class="subJudul4">
                                  pemegang kartu penduduk tetap AS (Green Card);
                                  dan/atau
                                </li>
                                <li class="subJudul4">
                                  memiliki kewajiban pelaporan pajak kepada
                                  Pemerintah AS dan/atau negara lain.
                                </li>
                              </ol>
                              <li class="subJudul3">
                                Nasabah bertanggung jawab sepenuhnya atas
                                kebenaran data/keterangan yang diberikan dan
                                membebaskan UR dari segala tuntutan, gugatan,
                                dan/atau tindakan hukum lainnya dalam bentuk apa
                                pun dan dari pihak mana pun sehubungan dengan
                                pemberian data/keterangan tersebut.
                              </li>
                              <li class="subJudul3">
                                Nasabah dengan ini menyatakan bahwa informasi
                                yang disampaikan kepada UR adalah benar, masih
                                berlaku, terkini, dan lengkap. Jika terdapat
                                perubahan atas data/keterangan yang telah
                                diberikan kepada UR, maka Nasabah akan melakukan
                                perubahan data secara mandiri melalui blu dalam
                                jangka waktu selambat-lambatnya 30 (tiga puluh)
                                hari kalender terhitung sejak terjadinya
                                perubahan tersebut atau dalam jangka waktu lain
                                yang akan diberitahukan oleh UR dalam bentuk dan
                                melalui media/sarana yang tersedia pada UR
                                sesuai ketentuan hukum yang berlaku.
                              </li>
                              <li class="subJudul3">
                                Nasabah merupakan satu-satunya pemilik manfaat
                                (ultimate beneficiary) dari rekening yang dibuka
                                dan memahami bahwa Nasabah tidak akan membuka
                                rekening untuk kepentingan orang lain,
                                memperjualbelikan rekening kepada pihak lain,
                                dan/atau menggunakan rekening dengan tujuan
                                dan/atau cara-cara yang melanggar hukum.
                              </li>
                              <ol>
                                <li class="subJudul4">
                                  Nasabah memahami dan menyetujui bahwa
                                  pemberian akses terhadap media penyimpanan
                                  (storage), kamera, GPS, galeri, kontak, dan
                                  device ID Nasabah adalah untuk meningkatkan
                                  kenyamanan dan keamanan nasabah dalam
                                  menggunakan blu.
                                </li>
                                <li class="subJudul4">
                                  Nasabah dengan ini memahami dan menyetujui
                                  bahwa UR berhak untuk menginformasikan nama,
                                  nomor rekening, dan/atau data transaksi
                                  Nasabah yang dilakukan melalui blu kepada
                                  parner UR yang terkait dengan transaksi
                                  Nasabah.
                                </li>
                              </ol>
                            </ol>
                          </ol>
                          <li class="subJudul1">BIAYA</li>
                          <ol>
                            <li class="subJudul2">
                              Pengunduhan perangkat lunak dan Penggunaan
                              Aplikasi UR adalah bebas biaya. Namun, PT. RAHMAT
                              TUHAN LESTARI dapat mengenakan biaya untuk
                              Penggunaan Aplikasi UR maupun Penggunaan fitur
                              tertentu yang disediakan oleh PT. RAHMAT TUHAN
                              LESTARI di kemudian hari dengan memberikan
                              pemberitahuan secara tertulis kepada Partner.
                            </li>
                            <li class="subJudul2">
                              Layanan yang tersedia pada Aplikasi UR dikenakan
                              biaya yang dapat Partner temukan pada Formulir
                              Partner dan/atau Aplikasi UR sebelum Partner
                              memesan Layanan tersebut. Penyedia Layanan dapat
                              mengubah atau memperbaharui biaya dari waktu ke
                              waktu berdasarkan faktor tertentu, antara lain
                              lokasi, waktu, jenis Layanan dan peraturan
                              perundang-undangan yang berlaku, dimana atas
                              perubahan tersebut akan mengikuti syarat dan
                              ketentuan yang berlaku terhadap masing-masing
                              Layanan.
                            </li>
                            <li class="subJudul2">
                              Partner dapat melakukan pembayaran terhadap akses
                              ke Akun UR (jika berlaku), Layanan, Konten Pihak
                              Ketiga atau Promosi yang Partner akses dengan
                              menggunakan salah satu Metode Pembayaran yang
                              disediakan oleh Penyedia Metode Pembayaran yang
                              dapat berubah sewaktu-waktu berdasarkan kebijakan
                              PT. RAHMAT TUHAN LESTARI sepenuhnya.
                            </li>
                            <li class="subJudul2">
                              Untuk dapat melakukan pembayaran melalui Metode
                              Pembayaran, Partner harus terdaftar secara resmi
                              pada Penyedia Metode Pembayaran yang Partner pilih
                              dan menggunakan kredensial pembayaran Partner
                              sendiri. Apabila Partner menggunakan kredensial
                              terdaftar pihak lain, Partner bertanggung jawab
                              secara penuh atas semua perizinan yang diperlukan
                              dan semua kerugian atau sengketa yang timbul
                              antara Partner dan pihak lain tersebut, baik
                              karena kelalaian atau kesalahan Partner, PT.
                              RAHMAT TUHAN LESTARI, Penyedia Layanan, Penyedia
                              Konten Pihak Ketiga, Penyedia Promo atau Penyedia
                              Metode Pembayaran.
                            </li>
                            <li class="subJudul2">
                              PT. RAHMAT TUHAN LESTARI berhak menolak atau
                              menunda untuk meneruskan permintaan pembayaran
                              Partner melalui Metode Pembayaran karena alasan
                              tertentu, termasuk namun tidak terbatas pada
                              adanya indikasi atau PT. RAHMAT TUHAN LESTARI,
                              Penyedia Layanan, Penyedia Konten Pihak Ketiga,
                              Penyedia Promo atau Penyedia Metode Pembayaran
                              mempunyai alasan yang cukup untuk menduga adanya
                              kecurangan, penipuan, pelanggaran Ketentuan
                              Penggunaan UR, Syarat dan Ketentuan Khusus, atau
                              syarat dan ketentuan lainnya yang terkait dengan
                              Layanan serta atas pelanggaran atas peraturan
                              perundang-undangan yang berlaku termasuk yang
                              terkait dengan alat pembayaran menggunakan kartu,
                              uang elektronik, pemrosesan transaksi pembayaran,
                              anti pencucian uang, korupsi dan pencegahan
                              pendanaan terorisme, atau tindakan lain yang tidak
                              wajar atau mencurigakan, termasuk belum
                              dipenuhinya kewajiban Partner kepada PT. RAHMAT
                              TUHAN LESTARI dan/ atau Penyedia Layanan, Penyedia
                              Konten Pihak Ketiga.
                            </li>
                            <li class="subJudul2">
                              Ketentuan lebih lanjut tentang biaya yang berlaku
                              terhadap Layanan tertentu, Konten Pihak Ketiga,
                              Promosi dan/atau Metode Pembayaran dapat ditemukan
                              dalam Syarat dan Ketentuan UR tambahan dari
                              Layanan yang Partner pesan dan syarat dan
                              ketentuan dari Penyedia Layanan, Penyedia Konten
                              Pihak Ketiga. Penyedia Promosi dan/atau Penyedia
                              Metode Pembayaran.
                            </li>
                            <li class="subJudul2">
                              UR tidak akan mengenakan biaya apapun kepada
                              Partner atas Penggunaan Platform UR dan/atau
                              Layanan selama Masa Percobaan.
                            </li>
                            <li class="subJudul2">
                              Setelah berakhirnya Masa Percobaan, Partner dapat
                              melanjutkan Penggunaan Platform UR dan/atau
                              Layanan dengan cara membayar biaya berlangganan
                              melalui Platform UR. Partner memahami bahwa UR
                              dapat memblokir, memperlambat dan/atau
                              memberhentikan akses Partner atas Penggunaan
                              Platform UR dan/atau Layanan apabila Partner tidak
                              melakukan pembayaran biaya berlangganan sesuai
                              dengan jangka waktu pembayaran yang ditetapkan.
                            </li>
                            <li class="subJudul2">
                              Harga biaya berlangganan yang tertera di Platform
                              UR adalah harga untuk setiap Gerai yang
                              menggunakan Platform UR ditambah dengan harga
                              Fitur Modular apabila Partner mengaktifkannya.
                              Harga biaya berlangganan dan Fitur Modular yang
                              tertera pada Platform UR dapat berubah
                              sewaktu-waktu dengan pemberitahuan terlebih dahulu
                              kepada Partner melalui sarana apapun yang
                              ditetapkan oleh UR.
                            </li>
                            <li class="subJudul2">
                              Partner memahami bahwa pembayaran atas total harga
                              biaya berlangganan dan Fitur Modular dilakukan
                              dengan mengikuti proses pembayaran yang terdapat
                              di dalam Platform UR. Partner wajib melakukan
                              pembayaran dalam jangka waktu yang ditentukan di
                              dalam Platform UR.
                            </li>
                            <li class="subJudul2">
                              Partner memahami dan menyetujui bahwa seluruh
                              biaya Penggunaan UR tidak termasuk PPN (Pajak
                              Pertambahan Nilai) yang mana akan dibebankan
                              secara terpisah. Pajak Penghasilan Partner akan
                              dilaporkan dan diurus sendiri oleh Partner sesuai
                              dengan ketentuan pajak yang berlaku di peraturan
                              perundang-undangan di Indonesia.
                            </li>
                            <li class="subJudul2">Biaya Layanan</li>
                            <ol>
                              <li class="subJudul3">
                                Biaya Layanan adalah biaya yang dikenakan kepada
                                Pembeli untuk tujuan pemeliharaan sistem dan
                                peningkatan layanan dalam bertransaksi melalui
                                Situs UR dan ketentuan lebih lanjut mengenai
                                biaya layanan dapat dilihat DISINI.
                              </li>
                            </ol>
                          </ol>
                          <li class="subJudul1">PEMBAHARUAN</li>
                          <ol>
                            <li class="subJudul2">Pembaharuan</li>
                            <ol>
                              <li class="subJudul3">
                                Syarat & ketentuan mungkin diubah dan/atau
                                diperbaharui dari waktu ke waktu tanpa
                                pemberitahuan sebelumnya. UR menyarankan agar
                                anda membaca secara seksama dan memeriksa
                                halaman Syarat & ketentuan ini dari waktu ke
                                waktu untuk mengetahui perubahan apapun. Dengan
                                tetap mengakses dan menggunakan layanan UR, maka
                                Pembeli dianggap menyetujui perubahan-perubahan
                                dalam Syarat & Ketentuan.
                              </li>
                            </ol>
                          </ol>
                          <li class="subJudul1">PENARIKAN DANA</li>
                          <ol>
                            <li class="subJudul2">
                              Penarikan dana sesama bank akan diproses dalam
                              waktu 3x24 jam hari kerja, sedangkan penarikan
                              dana antar bank akan diproses dalam waktu 5x24 jam
                              hari kerja.( tergantung dari pihak ketiga yang
                              mengelola arus transaksi ( payment gate way yang
                              ditunjuk pihak UR)
                            </li>
                            <li class="subJudul2">
                              Untuk penarikan dana dengan tujuan nomor rekening
                              di luar bank BCA. Mandiri, dan BNI apabila ada
                              biaya tambahan yang dibebankan akan menjadi
                              tanggungan dari Pembeli.
                            </li>
                            <li class="subJudul2">
                              Dalam hal ditemukan adanya dugaan pelanggaran
                              terhadap Syarat dan Ketentuan UR. kecurangan,
                              manipulasi atau kejahatan, Pembeli memahami dan
                              menyetujui bahwa UR berhak melakukan tindakan
                              pemeriksaan, pembekuan, penundaan dan/atau
                              pembatalan terhadap penarikan dana yang dilakukan
                              oleh Pembeli.
                            </li>
                            <li class="subJudul2">
                              Pemeriksaan, pembekuan atau penundaan penarikan
                              dana sebagaimana dimaksud dalam Poin H.1 dapat
                              dilakukan dalam jangka waktu selama yang
                              diperlukan oleh pihak UR.
                            </li>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenChoiceOfLaw(!openChoiceOfLaw)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Lain-lain" />
                  {openChoiceOfLaw ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openChoiceOfLaw} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">
                            Jika, sewaktu-waktu, suatu ketentuan dari Ketentuan
                            Penggunaan ini menjadi tidak sah, tidak berlaku,
                            atau tidak dapat dilaksanakan berdasarkan hukum dari
                            yurisdiksi manapun, maka keabsahan, keberlakuan atau
                            pelaksanaan dari ketentuan lainnya maupun keabsahan,
                            keberlakuan atau pelaksanaan dari ketentuan tersebut
                            berdasarkan hukum dari yurisdiksi lain tidak akan
                            menjadi terpengaruh atau terganggu.
                          </li>
                          <li class="subJudul1">
                            Partner tidak memiliki hak untuk mengalihkan hak dan
                            kewajiban Partner, baik sebagian maupun keseluruhan
                            berdasarkan Ketentuan Penggunaan ini, tanpa
                            persetujuan terlebih dahulu dari UR. Namun, UR dapat
                            mengalihkan hak UR berdasarkan Ketentuan Penggunaan
                            ini setiap saat kepada Afiliasi UR dan/atau pihak
                            lain yang UR tunjuk tanpa perlu mendapatkan
                            persetujuan terlebih dahulu dari Partner atau
                            memberikan pemberitahuan sebelumnya kepada Partner.
                          </li>
                          <li class="subJudul1">
                            UR tidak menjanjikan adanya eksklufitas dan
                            karenanya UR memiliki hak untuk memberikan Layanan
                            kepada setiap pesaing Partner. Apabila diperlukan,
                            UR juga memiliki hak untuk meminta dokumen legalitas
                            tambahan, surat pernyataan dan/atau dokumen
                            pendukung lainnya yang berhubungan dengan Partner
                            dan/atau kegiatan usaha Partner.
                          </li>
                          <li class="subJudul1">
                            Partner setuju dan sepakat bahwa segala perubahan,
                            amandemen atas Ketentuan Penggunaan ini dapat
                            dilakukan oleh UR atas dasar pertimbangan UR
                            sendiri. Perubahan atas Ketentuan Penggunaan ini
                            akan berlaku setelah UR mengumumkan perubahan atas
                            Ketentuan Penggunaan tersebut melalui sarana apapun
                            yang dipilih oleh UR (termasuk dengan mengirimkan
                            pemberitahuan tertulis secara langsung kepada
                            Partner). Dengan melanjutkan akses dan Penggunaan
                            Platform UR dan/atau Layanan setelah diterimanya
                            pemberitahuan tersebut maka Partner akan dianggap
                            telah menyetujui perubahan atau amandemen tersebut
                            dan setuju untuk terikat dengan Ketentuan Penggunaan
                            yang sebagaimana telah diubah atau ditambahkan.
                          </li>
                          <li class="subJudul1">
                            Dengan tunduk pada ketentuan yang mengatur mengenai
                            Kontrak Elektronik, Partner setuju dan sepakat bahwa
                            Ketentuan Penggunaan ini merupakan perjanjian dalam
                            bentuk elektronik dan tindakan Partner menekan
                            tombol “sign up” pada saat pembukaan Akun atau
                            tombol “sign in” pada saat akan mengakses Akun
                            merupakan persetujuan aktif Partner untuk
                            mengikatkan diri dalam perjanjian dengan UR sehingga
                            keberlakuan Ketentuan Penggunaan ini dan Kebijakan
                            Privasi adalah sah dan mengikat secara hukum dan
                            terus berlaku sepanjang Penggunaan Platform UR
                            dan/atau Layanan oleh Partner.
                          </li>
                          <li class="subJudul1">
                            Partner setuju bahwa Partner tidak akan memulai atau
                            melakukan tuntutan atau keberatan apapun sehubungan
                            dibuatnya maupun keabsahan Ketentuan Penggunaan ini
                            dalam bentuk Kontrak Elektronik.
                          </li>
                          <li class="subJudul1">
                            Bila Partner tidak mematuhi atau melanggar ketentuan
                            dalam Ketentuan Penggunaan ini, dan UR tidak
                            mengambil tindakan secara langsung, bukan berarti UR
                            mengesampingkan hak UR untuk mengambil tindakan yang
                            diperlukan di kemudian hari.
                          </li>
                          <li class="subJudul1">
                            Ketentuan Penggunaan ini diatur berdasarkan hukum
                            Republik Indonesia.
                          </li>
                          <li class="subJudul1">Live Chat Parner UR</li>
                          <ol>
                            <li class="subJudul2">
                              Live Chat Parner UR adalah mekanisme yang
                              disediakan oleh UR untuk memfasilitasi
                              penyelesaian kendala yang dialami oleh Parner UR.
                            </li>
                            <li class="subJudul2">
                              Ketika Pembeli menggunakan Live Chat Parner UR,
                              Parner UR hanya melakukan komunikasi dengan UR
                              sehingga tidak ada komunikasi antar Pembeli.
                            </li>
                            <li class="subJudul2">
                              Komunikasi yang dilakukan melalui Live Chat Parner
                              UR menggunakan fitur Live Chat yang disediakan
                              oleh UR.
                            </li>
                            <li class="subJudul2">
                              Penyelesaian kendala melalui Live Chat Parner UR
                              dapat berupa solusi yang dihasilkan berdasarkan
                              kesepakatan bersama antara UR, Partner dan Parner
                              UR.
                            </li>
                            <li class="subJudul2">
                              Pembeli setuju untuk tidak memberikan informasi
                              sensitif, seperti password, OTP, maupun informasi
                              lainnya yang terkait dengan mekanisme autentikasi.
                            </li>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc5(!tnc5)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hak Intelektual" />
                  {tnc5 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc5} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">
                            UR hanya menyediakan Aplikasi UR yang resmi pada
                            pasar digital resmi dimana UR adalah pengembang dari
                            aplikasi tersebut, termasuk namun tidak terbatas
                            pada pasar digital seperti Google Play Store atau
                            Apple App Store, dan untuk digunakan hanya pada
                            perangkat telepon genggam atau tablet. Mengunduh
                            Aplikasi UR dari tempat lain selain pasar digital
                            resmi dan/atau ke dalam perangkat lain selain
                            telepon genggam atau tablet merupakan pelanggaran
                            terhadap Ketentuan Penggunaan ini dan terhadap Hak
                            Kekayaan Intelektual UR.
                          </li>
                          <li class="subJudul1">
                            Platform UR dan Layanan, termasuk namun tidak
                            terbatas pada, nama, logo, kode program, desain,
                            merek dagang, teknologi, basis data, proses dan
                            model bisnis, dilindungi oleh hak cipta, merek,
                            paten dan Hak Kekayaan Intelektual lainnya yang
                            tersedia berdasarkan hukum Republik Indonesia yang
                            terdaftar baik atas nama UR ataupun Afiliasi UR. UR
                            (dan pemberi lisensi UR) memiliki seluruh hak dan
                            kepentingan atas Platform UR dan Layanan, termasuk
                            seluruh Hak Kekayaan Intelektual terkait dengan
                            seluruh fitur yang terdapat didalamnya dan Hak
                            Kekayaan Intelektual terkait.
                          </li>
                          <li class="subJudul1">
                            Tunduk pada Ketentuan Penggunaan ini, UR memberikan
                            Partner lisensi terbatas yang tidak eksklusif, dapat
                            ditarik kembali, tidak dapat dialihkan (tanpa hak
                            sublisensi) untuk mengunduh dan/atau mengakses serta
                            menggunakan Platform UR, sebagaimana adanya,
                            terbatas hanya untuk keperluan Penggunaan Platform
                            UR dan Layanan baik melalui aplikasi maupun
                            web-dashboard. Hak istimewa lainnya yang tidak
                            secara tegas diberikan dalam Ketentuan Penggunaan
                            ini, adalah hak UR atau pemberi lisensi UR.
                          </li>
                          <li class="subJudul1">
                            UR dapat menggunakan nama, logo, lambang, simbol,
                            merek dagang milik Partner untuk tujuan promosi atau
                            publikasi terkait Penggunaan Platform UR dan
                            Layanan, tanpa adanya pengalihan Hak Kekayaan
                            Intelektual dari Partner kepada UR.
                          </li>
                          <li class="subJudul1">
                            Setiap penggandaan, distribusi, pembuatan karya
                            turunan, penjualan atau penawaran untuk menjual,
                            penampilan baik sebagian atau seluruhnya, serta
                            Penggunaan Platform UR dan/atau Layanan, baik secara
                            digital atau lainnya, termasuk namun tidak terbatas
                            pada Penggunaan aplikasi tambahan seperti aplikasi
                            modifikasi, emulator, dan lain-lain, yang menyimpang
                            dari Ketentuan Penggunaan ini, maupun tujuan
                            Penggunaan yang ditentukan oleh UR, merupakan
                            pelanggaran terhadap Hak Kekayaan Intelektual UR.
                          </li>
                          <li class="subJudul1">Partner tidak boleh:</li>
                          <ol>
                            <li class="subJudul2">
                              menghapus setiap pemberitahuan hak cipta, merek
                              dagang atau pemberitahuan hak milik lainnya yang
                              terkandung dalam Platform UR;
                            </li>
                            <li class="subJudul2">
                              menyalin, memodifikasi, mengadaptasi,
                              menerjemahkan, membuat karya turunan dari,
                              mendistribusikan, memberikan lisensi, menjual,
                              mengalihkan, menampilkan di muka umum baik
                              sebagian maupun seluruhnya, merekayasa balik
                              (reverse engineer), mentransmisikan, memindahkan,
                              menyiarkan, menguraikan, atau membongkar bagian
                              manapun dari atau dengan cara lain mengeksploitasi
                              Platform UR (termasuk perangkat lunak, fitur dan
                              Layanan di dalamnya);
                            </li>
                            <li class="subJudul2">
                              memberikan lisensi, mensublisensikan, menjual,
                              menjual kembali, memindahkan, mengalihkan,
                              mendistribusikan atau mengeksploitasi secara
                              komersial atau membuat tersedia kepada pihak lain
                              Platform UR dan/atau perangkat lunak dengan cara
                              menciptakan tautan (link) internet ke Platform UR
                              atau "frame" atau "mirror" setiap perangkat lunak
                              pada server lain atau perangkat nirkabel atau yang
                              berbasis internet;
                            </li>
                            <li class="subJudul2">
                              meluncurkan program otomatis atau script,
                              termasuk, namun tidak terbatas pada, web spiders,
                              web crawlers, web robots, web ants, web indexers,
                              bots, virus atau worm, atau program apapun yang
                              mungkin membuat beberapa permintaan server per
                              detik, menciptakan beban berat atau menghambat
                              operasi dan/atau kinerja Platform UR;
                            </li>
                            <li class="subJudul2">
                              menggunakan aplikasi pencarian atau pengambilan
                              kembali situs, perangkat manual atau otomatis
                              lainnya untuk mengambil (scraping), indeks
                              (indexing), survei (surveying), tambang data (data
                              mining), atau dengan cara apapun memperbanyak atau
                              menghindari struktur navigasi atau presentasi dari
                              Platform UR atau isinya;
                            </li>
                            <li class="subJudul2">
                              menerbitkan, mendistribusikan atau memperbanyak
                              dengan cara apapun materi yang dilindungi hak
                              cipta, merek dagang, atau informasi lain yang UR
                              miliki tanpa persetujuan tertulis terlebih dahulu
                              dari UR atau pemilik hak yang melisensikan hak-nya
                              kepada UR; dan
                            </li>
                            <li class="subJudul2">
                              menggunakan dan/atau mengakses secara tidak resmi
                              Platform UR untuk (i) merusak, melemahkan atau
                              membahayakan setiap aspek dari Platform UR,
                              Layanan atau sistem dan jaringan terkait; dan/atau
                              (ii) membuat produk atau layanan tandingan serupa
                              menggunakan ide, fitur, fungsi atau grafik
                              menyerupai Platform UR.
                            </li>
                          </ol>
                          <li class="subJudul1">
                            Tindakan yang UR Anggap Perlu
                          </li>
                          <ol>
                            <li class="subJudul2">
                              Apabila UR mengetahui atau mempunyai alasan yang
                              cukup untuk menduga bahwa Partner telah melakukan
                              tindakan pelanggaran, kejahatan atau tindakan lain
                              yang bertentangan dengan Ketentuan Penggunaan ini
                              dan/atau peraturan perundang-undangan yang
                              berlaku, baik yang dirujuk dalam Ketentuan
                              Penggunaan ini atau tidak, maka UR berhak untuk
                              dan dapat membekukan Akun, baik sementara atau
                              permanen, atau menghentikan akses Partner terhadap
                              Platform UR, termasuk Layanan dan/atau Penawaran
                              yang terdapat di dalamnya, melakukan pemeriksaan,
                              menuntut ganti kerugian, melaporkan kepada pihak
                              berwenang dan/atau mengambil tindakan lain yang UR
                              anggap perlu, termasuk tindakan hukum pidana
                              maupun perdata.
                            </li>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc6(!tnc6)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Penyelesaian Masalah" />
                  {tnc6 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc6} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">Pelaporan</li>
                          <ol>
                            <li class="subJudul2">
                              Apabila Partner mengalami gangguan sistem,
                              mengetahui atau menduga bahwa Akun Partner
                              diretas, digunakan atau disalahgunakan oleh pihak
                              lain yang tidak terotorisasi, atau apabila
                              perangkat telepon genggam atau tablet pribadi
                              Partner hilang, dicuri, diretas atau terkena
                              virus, segera laporkan kepada UR sehingga UR dapat
                              segera mengambil tindakan yang diperlukan untuk
                              menghindari Penggunaan, penyalahgunaan, atau
                              kerugian yang timbul atau mungkin timbul lebih
                              lanjut.
                            </li>
                            <li class="subJudul2">
                              Apabila Partner mengalami kendala atau masalah
                              terkait Layanan atau Penawaran maka Partner dapat
                              menyampaikan keluhan Partner melalui fitur yang UR
                              sediakan atau dengan menghubungi UR secara
                              langsung ke kontak yang tertera di bagian akhir
                              Ketentuan Penggunaan ini.
                            </li>
                            <li class="subJudul2">
                              Untuk menyampaikan keluhan, pertanyaan, sanggahan,
                              dan lain-lain (“Laporan”), Partner perlu
                              memberikan informasi yang cukup, termasuk namun
                              tidak terbatas pada, ringkasan fakta yang terjadi,
                              bukti-bukti yang Partner miliki, dan informasi
                              pribadi, seperti alamat surat elektronik dan nomor
                              telepon genggam terdaftar.
                            </li>
                            <li class="subJudul2">
                              Untuk menanggapi setiap Laporan yang Partner
                              sampaikan, UR akan melakukan verifikasi terlebih
                              dahulu dengan mencocokan informasi yang Partner
                              berikan dan informasi terkait Partner yang
                              terdapat dalam sistem UR. Jika diperlukan, UR
                              dapat secara langsung meminta Partner memberikan
                              informasi yang diperlukan untuk tujuan verifikasi.
                            </li>
                            <li class="subJudul2">
                              UR dapat menolak untuk menindaklanjuti Laporan
                              Partner jika informasi yang Partner berikan tidak
                              cocok dengan informasi terkait Partner yang
                              terdapat dalam sistem UR atau apabila Laporan
                              disampaikan terkait, terhadap, atas nama atau oleh
                              pihak lain yang berbeda dengan pemilik Akun yang
                              bersangkutan yang terdaftar secara resmi pada
                              sistem UR. UR dapat memberhentikan tindak lanjut
                              terhadap Laporan Partner jika UR, dengan kebijakan
                              UR sepenuhnya, menganggap bahwa Laporan Partner
                              tidak didukung oleh fakta-fakta yang cukup dan
                              jelas, atau telah selesai.
                            </li>
                            <li class="subJudul2">
                              UR juga dapat meneruskan Laporan Partner kepada
                              Parner Pihak Ketiga Penyedia Layanan, atau
                              Penyedia Penawaran untuk diselesaikan secara
                              langsung oleh Partner dan Parner Pihak Ketiga
                              Penyedia Layanan , atau Penyedia Penawaran.
                            </li>
                          </ol>
                          <li class="subJudul1">Penyelesaian Sengketa</li>
                          <ol>
                            <li class="subJudul2">
                              Setiap dan seluruh perselisihan yang timbul dari
                              Penggunaan Platform UR atau Layanan ("Sengketa")
                              akan diselesaikan secara musyawarah untuk mufakat
                              terlebih dahulu dalam jangka waktu 30 (tiga puluh)
                              Hari Kalender sejak adanya pemberitahuan mengenai
                              Sengketa tersebut.
                            </li>
                            <li class="subJudul2">
                              Apabila Sengketa tidak dapat diselesaikan melalui
                              musyawarah mufakat dalam kurun waktu 30 (tiga
                              puluh) Hari Kalender sejak Sengketa diberitahukan,
                              Para Pihak sepakat untuk menyelesaikan Sengketa
                              secara final melalui arbitrase yang
                              diselenggarakan oleh Badan Arbitrase Nasional
                              Indonesia (BANI) sesuai dengan ketentuan-ketentuan
                              Badan Arbitrase Nasional Indonesia (BANI) yang
                              berlaku pada saat itu. Arbitrase akan dipimpin
                              oleh 1 (satu) orang arbiter yang ditunjuk oleh
                              Ketua Badan Arbitrase Nasional Indonesia (BANI)
                              dan dilaksanakan di Jakarta, Indonesia,
                              menggunakan Bahasa Indonesia. Putusan arbitrase
                              akan dianggap sebagai putusan yang berkekuatan
                              hukum tetap, mengikat dan tidak dapat digugat
                              kembali dan karenanya Partner atau UR tidak dapat
                              memulai suatu persidangan atau memasukan suatu
                              gugatan di pengadilan manapun sehubungan dengan
                              Sengketa yang timbul dari dan sehubungan dengan
                              Ketentuan Penggunaan ini.
                            </li>
                            <li class="subJudul2">
                              Setiap dan seluruh perselisihan yang timbul akibat
                              Penggunaan Layanan Parner Pihak Ketiga Penyedia
                              Layanan dan/atau Penyedia Penawaran diselesaikan
                              dengan jalur penyelesaian Sengketa yang disepakati
                              secara langsung oleh Partner dan Parner Pihak
                              Ketiga Penyedia Layanan dan Penyedia Penawaran
                              tersebut.
                            </li>
                          </ol>
                          <li class="subJudul1">Tindakan Kecurangan</li>
                          <ol>
                            <li class="subJudul2">
                              “Tindakan Kecurangan" adalah adanya indikasi
                              dan/atau terbukti terjadinya salah satu dari
                              hal-hal berikut ini:
                            </li>
                            <li class="subJudul2">
                              adanya transaksi mencurigakan;
                            </li>
                            <li class="subJudul2">
                              penyalahgunaan (abuse) dan/atau transaksi fiktif,
                              baik yang dilakukan oleh Partner dan/atau
                              karyawannya;
                            </li>
                            <li class="subJudul2">
                              bekerja sama dengan penipu (fraudster); dan/atau
                            </li>
                            <li class="subJudul2">
                              penipuan (fraud) yang dilakukan oleh Partner
                              dan/atau karyawannya.
                            </li>
                          </ol>
                          <li class="subJudul1">
                            Adanya indikasi dan/atau terbukti terjadinya
                            Tindakan Kecurangan, memberikan hak bagi PT. RAHMAT
                            TUHAN LESTARI, berdasarkan pertimbangan dan
                            kebijakannya sendiri dengan atau tanpa pemberitahuan
                            tertulis terlebih dahulu kepada Partner, untuk:
                          </li>
                          <ol>
                            <li class="subJudul2">
                              menghentikan setiap kegiatan promosi dan/atau
                              pemasaran dengan Partner;
                            </li>
                            <li class="subJudul2">
                              menghentikan sementara Layanan yang diberikan
                              berdasarkan Syarat dan Ketentuan Umum ini kepada
                              Partner;
                            </li>
                            <li class="subJudul2">
                              menahan dan/atau menangguhkan Dana Settlement;
                            </li>
                            <li class="subJudul2">
                              menolak pembayaran dan/atau memotong bagian dari
                              Dana Settlement sebagai pemulihan atas kerugian
                              yang dialami oleh PT. RAHMAT TUHAN LESTARI
                              dan/atau pihak ketiga lainnya;
                            </li>
                            <li class="subJudul2">
                              memasukkan Partner ke dalam daftar hitarn PT.
                              RAHMAT TUHAN LESTARI; dan/atau
                            </li>
                            <li class="subJudul2">
                              mengajukan klaim. gugatan dan/atau tuntutan hukum
                              dalam bentuk apapun atas setiap kerugian yang
                              dialami oleh PT. RAHMAT TUHAN LESTARI.
                            </li>
                          </ol>
                          <li class="subJudul1">
                            PT. RAHMAT TUHAN LESTARI dapat meminta Partner untuk
                            menyerahkan bukti pendukung sebagai bagian dari
                            proses banding Partner terhadap pelaksanaan dari
                            hak-hak PT. RAHMAT TUHAN LESTARI tersebut, dimana
                            bukti pendukung tersebut harus diserahkan oleh
                            Partner paling lambat 7 (tujuh) hari kalender
                            setelah diwajibkan oleh PT. RAHMAT TUHAN LESTARI.
                            PT. RAHMAT TUHAN LESTARI akan menilai bukti
                            pendukung dan semata-mata atas kebijakannya sendiri
                            memutuskan untuk melakukan Settlement atau terus
                            menggunakan haknya sebagaimana disebutkan dalam ayat
                            (2) Pasal ini.
                          </li>
                          <li class="subJudul1">Pilihan Hukum</li>
                          <ol>
                            <li class="subJudul2">
                              Perjanjian ini akan diatur oleh dan ditafsirkan
                              sesuai dengan hukum Republik Indonesia, tanpa
                              memperhatikan pertentangan aturan hukum. Anda
                              setuju bahwa tindakan hukum apapun atau sengketa
                              yang mungkin timbul dari, berhubungan dengan, atau
                              berada dalam cara apapun berhubungan dengan situs
                              dan/atau Perjanjian ini akan diselesaikan secara
                              eksklusif dalam yurisdiksi pengadilan Republik
                              Indonesia.
                            </li>
                          </ol>
                          <li class="subJudul1">UR solution</li>
                          <ol>
                            <li class="subJudul2">
                              Ur solution adalah fitur yang disediakan oleh UR
                              untuk memfasilitasi penyelesaian masalah transaksi
                              antara Pembeli dan Penjual.
                            </li>
                            <li class="subJudul2">
                              Dalam menggunakan fitur ini, Pembeli dan Penjual
                              diharapkan memberikan bukti-bukti transaksi jual
                              beli berupa foto barang, nota Penggunaan, slip
                              resi pengiriman dan bukti-bukti penunjang lainnya
                              yang dapat menjadi dasar pembenar atas setiap
                              argumen yang dikeluarkan oleh masing-masing pihak.
                            </li>
                            <li class="subJudul2">
                              Penyelesaian permasalahan melalui Ur solution
                              dapat berupa solusi yang dihasilkan berdasarkan
                              kesepakatan bersama antara Pembeli dan Penjual.
                            </li>
                            <li class="subJudul2">
                              Jika Penjual tidak memberikan jawaban dalam jangka
                              waktu 2 x 24 jam sejak kendala dibuat, maka
                              kendala secara otomatis akan terselesaikan
                              berdasarkan solusi yang diajukan oleh Pembeli.
                            </li>
                            <li class="subJudul2">
                              Jika tidak ditemukan kesepakatan antara Pembeli
                              dan Penjual dalam jangka waktu 2 x 24 jam, maka UR
                              memiliki hak untuk membantu menyelesaikan kendala.
                            </li>
                            <li class="subJudul2">
                              Dalam hal UR membantu menyelesaikan kendala, maka
                              sesuai dengan Syarat dan Ketentuan Poin C. 16 dan
                              Poin D. 11, Pembeli dan Penjual menyetujui bahwa
                              UR berwenang untuk mengambil keputusan atas
                              permasalahan tersebut dengan melihat bukti-bukti
                              yang ada dan/atau bukti-bukti baru yang harus
                              dilengkapi masing-masing pihak.
                            </li>
                            <li class="subJudul2">
                              UR berwenang untuk melakukan mediasi dan/atau
                              mengambil keputusan untuk penyelesaian masalah
                              didalam diskusi Ur solution, meskipun salah satu
                              pihak (Pembeli atau Penjual) belum memberikan
                              tanggapan atas solusi yang diberikan Ur solution
                              dalarn jangka waktu 2 x 24 jam, dalam hal: (i)
                              Adanya informasi dari pihak ketiga yang berkaitan
                              dalam jalannya transaksi, yakni antara lain kurir
                              pengiriman yang memberikan informasi terkait
                              status terkini dari resi pengiriman paket: (ii)
                              Bukti dari Pembeli dan/atau Penjual yang
                              dikirimkan melalui jalur komunikasi lain (email
                              UR, Layanan Pembeli. dan lainnya) yang perlu
                              diteruskan ke Ur solution dengan dasar
                              transparansi masalah: fili) Laporan dari salah
                              satu pihak disebabkan adanya kelalaian
                            </li>
                            <li class="subJudul2">
                              Pembeli atau Penjual: (iv) Salah satu pihak
                              (Pembeli atau Penjual) terindikasi melakukan
                              tindakan kecurangan: atau (v) Kondisi tertentu
                              yang menyebabkan UR perlu segera melakukan
                              mediasi.
                            </li>
                            <li class="subJudul2">
                              Atas keputusan pertama UR diatas, baik Pembeli dan
                              Penjual memiliki hak untuk banding, dengan
                              persyaratan bahwa pihak yang mengajukan upaya
                              banding harus mengajukan bukti baru di luar dari
                              bukti-bukti sebelumnya.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual dengan ini menyetujui bahwa
                              Keputusan UR atas upaya banding adalah keputusan
                              akhir yang tidak dapat diganggu gugat dan mengikat
                              pihak Penjual dan Pembeli untuk mematuhinya.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami bahwa Ur solution tidak berlaku
                              untuk produk pakaian dalam bekas, kendala yang
                              berkaitan dengan rasa, aroma dan/atau tekstur
                              produk, dan semua produk dalam daftar jenis barang
                              yang dilarang diperjual-belikan di UR seperti
                            </li>
                            <li class="subJudul2">
                              yang ada di halaman Syarat dan Ketentuan.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual memahami dan menyetujui bahwa
                              dalam penyelesaian kendala di Ur solution, Pembeli
                              dan Penjual wajib memberikan tanggapan atas
                              kendala yang ada hingga selesai dengan mematuhi
                              ketentuan dan batas waktu sebagaimana yang telah
                              ditentukan pihak UR pada diskusi terkait.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual memahami dan menyetujui bahwa
                              penanggungan ongkos kirim yang timbul selama
                              proses penyelesaian kendala pada Ur solution
                              didasarkan oleh bukti-bukti dan/atau kesepakatan
                              yang diberikan oleh kedua belah pihak. Apabila
                              Pembeli dan/atau Penjual tidak bersedia untuk
                              menanggung ongkos kirim tersebut, maka UR
                              berwenang memberikan keputusan penanggungan ongkos
                              kirim berdasarkan perkembangan diskusi dan bukti
                              yang ada.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami dan menyetujui bahwa setelah
                              adanya kesepakatan untuk pengiriman Barang kembali
                              ke Penjual. Apabila dalam waktu maksimal 2 x 24
                              jam (tidak termasuk hari libur nasional, Sabtu dan
                              Minggu) terhitung sejak Penjual memberikan alamat,
                              Pembeli tidak kunjung melakukan pengiriman barang,
                              maka UR berwenang untuk mengambil keputusan
                              berdasarkan perkembangan diskusi dan bukti yang
                              ada.
                            </li>
                            <li class="subJudul2">
                              Penjual memahami dan menyetujui bahwa pengiriman
                              Barang pengganti untuk Pembeli dapat dilakukan
                              maksimal 2 (dua) hari kerja (tidak termasuk hari
                              libur nasional, sabtu dan minggu) terhitung sejak
                              terdapat status pengiriman dari kurir logistik
                              bahwa Barang yang dikembalikan oleh Pembeli sudah
                              diterima oleh Penjual. Apabila didapati bahwa
                              Penjual tidak memenuhi ketentuan ini, maka UR
                              berwenang untuk mengambil keputusan berdasarkan
                              perkembangan diskusi dan bukti yang ada.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual memahami dan menyetujui bahwa
                              batas waktu untuk pemberian bukti adalah maksimal
                              2 x 24 jam terhitung sejak UR melakukan permintaan
                              bukti kepada Pembeli dan Penjual atas transaksi
                              terkait.
                            </li>
                            <li class="subJudul2">
                              Tata cara menggunakan Ur solution selengkapnya
                              dapat dilihat pada Halaman Bantuan Ur solution.
                            </li>
                            <li class="subJudul2">Force Majeure</li>
                            <ol>
                              <li class="subJudul3">
                                Dalam hal UR tidak dapat melaksanakan instruksi
                                dari Nasabah. baik sebagian maupun seluruhnya,
                                karena kejadian-kejadian atau hal-hal di luar
                                kekuasaan atau ke PT. Rahmat Tuhan Lestaripuan
                                UR, termasuk namun tidak terbatas pada bencana
                                alam, perang, huru-hara,
                                peralatan/sistem/transmisi dalam keadaan tidak
                                berfungsi, terjadinya gangguan listrik, gangguan
                                telekomunikasi, adanya kebijakan pemerintah yang
                                melarang UR mengoperasikan blu, serta
                                kejadian-kejadian atau hal-hal lain di luar
                                kekuasaan atau kePT. Rahmat Tuhan Lestaripuan
                                UR, Nasabah dengan ini membebaskan UR dari
                                segala macam tuntutan dalam bentuk apapun
                                terkait dengan hal tersebut.
                              </li>
                            </ol>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc7(!tnc7)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kondisi Kahar" />
                  {tnc7 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc7} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">
                            Aplikasi dapat diinterupsi oleh kejadian di luar
                            kewenangan atau kontrol Kami (“Keadaan Kahar”),
                            termasuk namun tidak terbatas pada bencana alam,
                            gangguan listrik, gangguan telekomunikasi, kebijakan
                            pemerintah, dan lain-lain. Anda setuju untuk
                            membebaskan Kami dari setiap tuntutan dan tanggung
                            jawab, jika Kami tidak dapat memfasilitasi Layanan,
                            termasuk memenuhi instruksi yang Anda berikan
                            melalui Aplikasi, baik sebagian maupun seluruhnya,
                            karena suatu Keadaan Kahar.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc8(!tnc8)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Persetujuan Terhadap Syarat dan Ketentuan Partner" />
                  {tnc8 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc8} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li class="subJudul1">
                            PENGGUNA dengan ini menyatakan telah memahami
                            sepenuhnya dan menyetujui seluruh Syarat dan
                            Ketentuan sebagaimana tersebut di atas.
                          </li>
                          <li class="subJudul1">
                            PENGGUNA menyatakan bahwa dengan mencentang (tick
                            mark) bagian dari Syarat dan Ketentuan ini yang
                            berbunyi “Saya telah membaca, memahami, dan
                            menyetujui Syarat dan Ketentuan di atas" maka User
                            telah memahami dan menyetujui Syarat dan Ketentuan
                            dan oleh karenanya persetujuan tersebut merupakan
                            bukti yang sah dan memiliki kekuatan pembuktian yang
                            sempurna sebagaimana layaknya persetujuan yang
                            diberikan dengan menandatangani Syarat dan
                            Ketentuan.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default SyaratDanKetentuanPartner;
