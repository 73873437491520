import Axios from 'axios';
const API_KEY = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL

export const getCareerCategory = async () => {

  const result = await Axios.get(
    API_KEY + 'v2/career/category/');

  return result.data;
};

export const getCareerPagination = async (page, idCategory) => {
  // v2/news/getAllNewsPagination.php?load=18&page=` + page + `&category=` + category,
  const result = await Axios.get(
    API_KEY + 'v2/career/getCareerByidCategory.php?load=10&page=' + page + `&category=` + idCategory);
    
    
  return result.data;
};

export const getCareerSlug = async (slug) => {
  // v2/news/getAllNewsPagination.php?load=18&page=` + page + `&category=` + category,
  const result = await Axios.get(
    API_KEY + 'v2/career/slug.php?slug=' + slug);

    // console.log(API_KEY + 'v2/career/slug.php?slug=' + slug)
    
    
  return result.data;
};