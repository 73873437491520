import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 250,
    margin: theme.spacing(2),
    textAlign: "center",
  },
  icon: {
    maxWidth: 120,
    textAlign: "center",
  },
  box: {
    height: "100vh",
  },
  hero: {
    backgroundColor: theme.palette.primary.main,
  },
  hero_secondary: {
    backgroundColor: theme.palette.secondary.main,
  },

  link: {
    color: "#000000",
    backgroundColor: "transparent",
    textAlign: "left",
    fontWeight: "600",
    textDecoration: "none",
    "&:hover": {
      color: "#de148c",
      textAlign: "left",
      fontWeight: "600",
      textDecoration: "none",
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const [ChangeColor, setChangeColor] = React.useState(classes.hero);
  let location = useLocation();

  useEffect(() => {
    // console.log(location.pathname);
    switch (location.pathname) {
      case "/pusat-bantuan/partner":
        return setChangeColor(classes.hero_secondary);
      case "/partner":
        return setChangeColor(classes.hero_secondary);
      // case '/agent': return setChangeColor(classes.paper_third)
      default:
        return setChangeColor(classes.hero);
    }
  }, [location]);

  return (
    <Box py={8} style={{ backgroundColor: "#E9E9E9" }}>
      <Container>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={3}>
            <a href="/">
              <img
                className={classes.logo}
                alt="icon"
                src="https://ik.imagekit.io/urhub/assets/misc/logo-footer.png"
              />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 10,
            }}
          >
            <Button variant="text" className={classes.link} href="/">
              Beranda
            </Button>

            <Button variant="text" className={classes.link} href="/blog">
              Blog &nbsp;&nbsp;&nbsp;
            </Button>

            <Button
              variant="text"
              className={classes.link}
              href="/pusat-bantuan/user"
            >
              Pusat Bantuan Pengguna
            </Button>

            <Button
              variant="text"
              className={classes.link}
              href="/pusat-bantuan/partner"
            >
              Pusat Bantuan Partner
            </Button>

            <Button variant="text" className={classes.link} href="/contact">
              Kontak
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 10,
            }}
          >
            <Button variant="text" className={classes.link} href="/partner">
              Partner
            </Button>

            <Link
              style={{ textDecoration: "none", marginLeft: "-2%" }}
              to="/agent"
            >
              <Button variant="text" className={classes.link}>
                Agent
              </Button>
            </Link>

            <Link style={{ textDecoration: "none" }} to="/karir">
              <Button variant="text" className={classes.link}>
                Bekerja di UR
              </Button>
            </Link>

            <Link style={{ textDecoration: "none" }} to="/kebijakan-privasi">
              <Button variant="text" className={classes.link}>
                Kebijakan Privasi
              </Button>
            </Link>

            <Link style={{ textDecoration: "none" }} to="/syarat-dan-ketentuan">
              <Button variant="text" className={classes.link}>
                Syarat & Ketentuan
              </Button>
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to="/syarat-dan-ketentuan-partner"
            >
              <Button variant="text" className={classes.link}>
                Syarat & Ketentuan Partner
              </Button>
            </Link>

            <Link style={{ textDecoration: "none" }} to="/faqs">
              <Button variant="text" className={classes.link}>
                FAQ &nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </Link>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom className={classes.link}>
              Kontak
            </Typography>
            <Grid item>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/6289650077771"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.link}
                >
                  <CallIcon
                    className={classes.link}
                    fontSize="small"
                    style={{ marginBottom: -5 }}
                  />{" "}
                  089-6500-7777-1
                </Typography>
              </a>
            </Grid>
            <Grid item>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=support@ur-hub.com&su=Pertanyaan%20Customer&body=Saya%20ingin%20bertanya&tf=1"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.link}
                >
                  <EmailIcon
                    className={classes.link}
                    fontSize="small"
                    style={{ marginBottom: -5 }}
                  />{" "}
                  support@ur-hub.com
                </Typography>
              </a>
            </Grid>
            {/* <Grid item>
                            <Typography variant="subtitle1" gutterBottom className={classes.link}>
                                <LocationOnIcon className={classes.link} fontSize="small" style={{ marginBottom: -5 }} />Jl. Jend. Sudirman No. 496
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className={classes.link}>
                                Ciroyom, Kec. Andir, Kota Bandung, Jawa Barat 40182
                            </Typography>
                        </Grid> */}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="h6" gutterBottom className={classes.link}>
              Download Aplikasi di
            </Typography>
            <Grid item>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/id/app/ur-partner-pos-system/id1572223994"
              >
                <img
                  className={classes.icon}
                  alt="icon"
                  src="https://ik.imagekit.io/urhub/assets/misc/app-store-icon.png"
                />
              </a>
              <br />
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.urpartner"
              >
                <img
                  className={classes.icon}
                  alt="icon"
                  src="https://ik.imagekit.io/urhub/assets/misc/google-play-icon.png"
                />
              </a>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.link}
              >
                Ikuti kami
              </Typography>
            </Grid>
            <Grid item>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/UR-Self-Order-App-101245531496672/"
              >
                <FacebookIcon
                  style={{ marginRight: 10 }}
                  className={classes.link}
                  href="https://www.facebook.com/UR-Self-Order-App-101245531496672/"
                  target="_blank"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/ur.hub/"
              >
                <InstagramIcon
                  style={{ marginRight: 10 }}
                  className={classes.link}
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/ur-hub/"
              >
                <LinkedInIcon className={classes.link} />
              </a>
            </Grid>
          </Grid>
        </Grid>

        <hr style={{ border: "1px solid #fffff", marginTop: 20 }} />
        <Grid
          container
          justify="center"
          style={{
            marginTop: 30,
          }}
        >
          <Grid item xs={12} md={6} align="center">
            <Typography
              noWrap={true}
              variant="subtitle2"
              style={{ color: "white" }}
              gutterBottom
            >
              <span style={{ color: "black" }}>
                {" "}
                Copyright © 2021{" "}
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://ur-hub.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PT. Rahmat Tuhan Lestari.
                </a>{" "}
                All rights reserved.{" "}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
