import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import MetaTags from "react-meta-tags";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "250px",
    zIndex: "1",
  },
  // imgGuide: {
  //   border: '1px solid #9e9e9e'
  // },
  card_active: {
    backgroundColor: theme.palette.primary.main,
    color: "#f5f5f5",
  },
  avatar_active: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
  },
  avatar: {
    // color: theme.palette.text.secondary,
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
  },
  scrollBox: {
    height: "455px",
    overflowY: "scroll",
  },
}));

function PusatBantuanUser() {
  const classes = useStyles();

  const [currentDaftarPengguna, setCurrentDaftarPengguna] = React.useState(0);
  const [currentEditDataDiri, setCurrentEditDataDiri] = React.useState(0);
  const [currentOrderPengguna, setCurrentOrderPengguna] = React.useState(0);
  const [currentStatusPesanan, setCurrentStatusPesanan] = React.useState(0);
  const [currentDetailPesanan, setCurrentDetailPesanan] = React.useState(0);
  const [currentLoyaltyCard, setCurrentLoyaltyCard] = React.useState(0);
  const [currentMapPengguna, setCurrentMapPengguna] = React.useState(0);

  const daftarPengguna = [
    {
      text: "Unduh aplikasi UR, pada Playstore (Android) atau App Store (iOS) dengan mengetik kata kunci “UR Self Order App”.",
      image: "../../asset/images/PusatBantuanUser/user-1.png",
    },
    {
      text: "Buka aplikasi UR yang sudah di unduh sebelumnya pada perangkat keras anda.",
      image: "../../asset/images/PusatBantuanUser/user-2.png",
    },
    {
      text: "Masukan nomor telepon yang ingin anda daftarkan. Pastikan nomor telepon yang anda gunakan sama dengan nomor telepon pada akun e-wallet anda.",
      image: "../../asset/images/PusatBantuanUser/user-3.png",
    },
    {
      text: " Isi data sesuai form tersebut.",
      image: "../../asset/images/PusatBantuanUser/user-4.png",
    },
    {
      text: "Selamat! Anda telah menjadi pengguna UR.",
      image: "../../asset/images/PusatBantuanUser/user-5.png",
    },
  ];

  const editDataDiri = [
    {
      text: "Pada halaman utama, silahkan pilih icon profile.",
      image: "../../asset/images/PusatBantuanUser/user-6.png",
    },
    {
      text: "Pilih menu edit data diri untuk melakukan perubahan data diri.",
      image: "../../asset/images/PusatBantuanUser/user-7.png",
    },
    {
      text: 'Masukan data diri yang akan diubah. Setelah melakukan perubahan data diri, pastikan menekan tombol "SIMPAN".',
      image: "../../asset/images/PusatBantuanUser/user-8.png",
    },
    {
      text: "Setelah selesai ubah data diri maka sistem akan menampilkan pemberitahuan.",
      image: "../../asset/images/PusatBantuanUser/user-9.png",
    },
  ];

  const orderPengguna = [
    {
      text: 'Pada halaman utama, silahkan pilih tombol "Pesan".',
      image: "../../asset/images/PusatBantuanUser/user-10.png",
    },
    {
      text: 'Pastikan kamu memilih "Allow" untuk memberi akses kamera pada aplikasi UR untuk melakukan pemesanan dengan cara scan kode QR.',
      image: "../../asset/images/PusatBantuanUser/user-11.png",
    },
    {
      text: "Pastikan kamu melakukan scan kode QR dengan benar agar kode QR yang ada dapat terbaca.",
      image: "../../asset/images/PusatBantuanUser/user-12.png",
    },
    {
      text: "Setelah berhasil melakukan scan kode QR, maka sistem akan menampilkan daftar menu pada restoran tersebut.",
      image: "../../asset/images/PusatBantuanUser/user-13.png",
    },
    {
      text: "Pilih menu yang akan dipesan sesuai dengan keinginan kamu. Setelah memilih menu tersebut maka sistem akan menampilkan pilihan banyaknya menu yang akan dipesan serta catatan untuk menu yang dipesan, silahkan pilih tombol simpan untuk melanjutkan pemesanan.",
      image: "../../asset/images/PusatBantuanUser/user-14.png",
    },
    {
      text: "Setelah memilih tombol simpan, maka sistem akan menampilkan detail transaksi seperti nama menu, banyak pesanan, harga, PPN, service, sub total, promo dan total",
      image: "../../asset/images/PusatBantuanUser/user-15.png",
    },
    {
      text: 'Untuk melanjutkan pemesanan, pengguna dapat memilih "Bayar".',
      image: "../../asset/images/PusatBantuanUser/user-16.png",
    },
    {
      text: 'Setelah memilih tombol "Bayar", maka pengguna dapat memilih metode pembayaran yang akan digunakan. Jika menggunakan metode pembayaran dengan e-wallet, pastikan nomor yang digunakan pada aplikasi UR sama dengan nomor pada uang elektronik.',
      image: "../../asset/images/PusatBantuanUser/user-17.png",
    },
    {
      text: 'Setelah memilih metode pembayaran yang akan digunakan, silahkan pilih tombol "Bayar" untuk melanjutkan pemesanan.',
      image: "../../asset/images/PusatBantuanUser/user-18.png",
    },
    {
      text: "Setelah melakukan pembayaran, maka pihak restoran akan mengkonfirmasi pesanan yang anda pesan, maka sistem akan menampilkan pesan bahwa anda akan melakukan pembayaran",
      image: "../../asset/images/PusatBantuanUser/user-19.png",
    },
    {
      text: "Setelah pesanan diterima, maka sistem akan menampilkan pesan",
      image: "../../asset/images/PusatBantuanUser/user-20.png",
    },
  ];

  const statusPesanan = [
    {
      text: 'Pada halaman utama, silahkan pilih icon "Pesanan".',
      image: "../../asset/images/PusatBantuanUser/user-21.png",
    },
    {
      text: 'Setelah memilih icon "Pesanan" maka sistem akan menampilkan halaman.',
      image: "../../asset/images/PusatBantuanUser/user-22.png",
    },
    {
      text: "Jika icon pada riwayat order masih seperti gambar berikut maka, pesanan sedang masih dalam proses.",
      image: "../../asset/images/PusatBantuanUser/user-23.png",
    },
    {
      text: "Jika icon pada riwayat order sudah seperti gambar berikut, maka pesanan sudah selesai.",
      image: "../../asset/images/PusatBantuanUser/user-24.png",
    },
    {
      text: "Jika pesanan anda sudah selesai di proses, maka sistem akan menampilkan pesan.",
      image: "../../asset/images/PusatBantuanUser/user-25.png",
    },
  ];

  const detailPesanan = [
    {
      text: 'Pada halaman utama, silahkan pilih icon "Pesanan"',
      image: "../../asset/images/PusatBantuanUser/user-21.png",
    },
    {
      text: 'Setelah memilih icon "Pesanan" tampilan kamu akan akan berubah',
      image: "../../asset/images/PusatBantuanUser/user-22.png",
    },
    {
      text: "Lalu pilih pesanan yang ingin dilihat detail pesanan",
      image: "../../asset/images/PusatBantuanUser/user-23.png",
    },
    {
      text: "Setelah memilih pesanan yang ingin dilihat, maka sistem akan menampilkan menampilkan detail pesanan",
      image: "../../asset/images/PusatBantuanUser/user-27.png",
    },
  ];

  const loyaltyCard = [
    {
      text: "Pada halaman utama, silahkan pilih icon profile.",
      image: "../../asset/images/PusatBantuanUser/user-6.png",
    },
    {
      text: 'Pilih menu "Loyalty" untuk melihat loyalty point anda.',
      image: "../../asset/images/PusatBantuanUser/user-28.png",
    },
    {
      text: "Selamat halaman Loyalty Card berhasil di akses.",
      image: "../../asset/images/PusatBantuanUser/user-29.png",
    },
  ];

  const mapPengguna = [
    {
      text: 'Pada halaman utama, silahkan pilih icon "MAPS".',
      image: "../../asset/images/PusatBantuanUser/user-30.png",
    },
    {
      text: 'Pastikan kamu memlih "Allow" untuk memberi akses lokasi pada aplikasi UR untuk mengetahui restoran yang dapat digunakan degan apliakasi UR.',
      image: "../../asset/images/PusatBantuanUser/user-31.png",
    },
    {
      text: "Kamu bisa melihat partner UR di sekitar kamu.",
      image: "../../asset/images/PusatBantuanUser/user-32.png",
    },
  ];
  // document.title = "Pusat Bantuan Pengguna | UR - Self Order & POS App"

  return (
    <div>
      <MetaTags>
        <title>Pusat Bantuan Pengguna | UR - Self Order & POS App</title>
        <meta name="title" content="Pusat Bantuan Pengguna" />
        <meta name="description" content="Males antri? Kami solusinya" />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Pusat Bantuan Pengguna" />
        <meta property="og:description" content="Males antri? Kami solusinya" />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Pusat Bantuan Pengguna" />
        <meta
          property="twitter:description"
          content="Males antri? Kami solusinya"
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Pusat Bantuan Pengguna
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/pb_user.png"
                  alt="icon user ur"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Daftar Pengguna
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={daftarPengguna[currentDaftarPengguna].image}
                alt={daftarPengguna[currentDaftarPengguna].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {daftarPengguna.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentDaftarPengguna === index
                          ? classes.card_active
                          : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentDaftarPengguna(index)}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentDaftarPengguna === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Ubah Data Diri
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={editDataDiri[currentEditDataDiri].image}
                alt={editDataDiri[currentEditDataDiri].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {editDataDiri.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentEditDataDiri === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentEditDataDiri(index)}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentEditDataDiri === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Cara Memesan dengan Aplikasi UR
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={orderPengguna[currentOrderPengguna].image}
                alt={orderPengguna[currentOrderPengguna].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              <div className={classes.scrollBox}>
                {orderPengguna.map((data, index) => {
                  return (
                    <Grid item key={index}>
                      <Card
                        variant="outlined"
                        className={
                          currentOrderPengguna === index
                            ? classes.card_active
                            : ""
                        }
                        style={{ marginBottom: "15px" }}
                      >
                        <CardActionArea
                          onClick={() => setCurrentOrderPengguna(index)}
                        >
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item xs={2} align="center">
                                <Avatar
                                  className={
                                    currentOrderPengguna === index
                                      ? classes.avatar_active
                                      : classes.avatar
                                  }
                                >
                                  {index + 1}
                                </Avatar>
                              </Grid>
                              <Grid item xs={10}>
                                <Typography variant="body1">
                                  {data.text}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Cek Status Pesanan pada Aplikasi UR
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={statusPesanan[currentStatusPesanan].image}
                alt={statusPesanan[currentStatusPesanan].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {/* <div className={classes.scrollBox}> */}
              {statusPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentStatusPesanan === index
                          ? classes.card_active
                          : ""
                      }
                      style={{ marginBottom: "15px" }}
                    >
                      <CardActionArea
                        onClick={() => setCurrentStatusPesanan(index)}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentStatusPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
              {/* </div> */}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Cek Detail Pesanan pada Aplikasi UR
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={detailPesanan[currentDetailPesanan].image}
                alt={detailPesanan[currentDetailPesanan].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {detailPesanan.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentDetailPesanan === index
                          ? classes.card_active
                          : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentDetailPesanan(index)}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentDetailPesanan === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Menuju Halaman Loyalty Card Pengguna pada Aplikasi UR
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={loyaltyCard[currentLoyaltyCard].image}
                alt={loyaltyCard[currentLoyaltyCard].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {loyaltyCard.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentLoyaltyCard === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentLoyaltyCard(index)}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentLoyaltyCard === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={5}>
          <Grid
            container
            justify="space-between"
            className={classes.box}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                gutterBottom
                align="center"
              >
                Menuju Halaman Maps Pengguna pada Aplikasi UR
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <img
                className={classes.imgGuide}
                src={mapPengguna[currentMapPengguna].image}
                alt={mapPengguna[currentMapPengguna].text}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6} container spacing={1} direction="column">
              {mapPengguna.map((data, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      variant="outlined"
                      className={
                        currentMapPengguna === index ? classes.card_active : ""
                      }
                    >
                      <CardActionArea
                        onClick={() => setCurrentMapPengguna(index)}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={2} align="center">
                              <Avatar
                                className={
                                  currentMapPengguna === index
                                    ? classes.avatar_active
                                    : classes.avatar
                                }
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">
                                {data.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default PusatBantuanUser;
