import React, { useState, useEffect } from 'react'
import { makeStyles, Hidden, useTheme } from '@material-ui/core'
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    LineShareButton,
    LineIcon,
    TwitterShareButton,
    TwitterIcon

} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import parse from 'html-react-parser'
import MetaTags from 'react-meta-tags'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Fab from '@material-ui/core/Fab';
import { getNewsBySlug } from '../../action/news'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
var dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime')
var advancedFormat = require('dayjs/plugin/advancedFormat')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles((theme) => ({
    hero: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            borderBottomLeftRadius: '50%',
            borderBottomRightRadius: '50%',
        },
    },
    box: {
        height: 'auto'
    },
    image: {
        position: 'absolute',
        right: '144px',
        top: '100px',
        width: '300px',
        zIndex: '1'
    },
    cardSize: {
        maxWidth: 345,

    },

    fabFix: {
        display: 'block',
        position: 'fixed',
        marginLeft: '35px'
    }

}))

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 600,
    })

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};



export default function Berita(props) {
    const classes = useStyles()
    const theme = useTheme()
    const location = useLocation()
    const str = location.pathname;
    const slug = str.slice(6);
    const [open, setOpen] = useState(false);
    const [news, setNews] = useState({});
    const [metaDesc, setMetaDesc] = useState('');
    const [images, setImages] = useState({});

    const shareUrl = `https://ur-hub.com` + location.pathname;
    const shareUrl2 = `https://ur-hub.com/` + location.pathname;

    useEffect(() => {

        getNewsBySlug(slug).then(result => {
            if (result.success === 1) {
                setNews(result.news[0])
                console.log(result.news)
                const imagesJSON = JSON.parse(result.news[0].image)
                setImages(imagesJSON)
                const strippedContent = result.news[0].content.replace(/<\/?[^>]+(>|$)/g, "")
                setMetaDesc(strippedContent)
                // console.log(result.news.image)
                // console.log(result)


            } else {
                const timer = setTimeout(() => {
                    // setIsLoading(false)
                    // setNoData(true)
                }, 2000);
                return () => clearTimeout(timer);
            }
        })

    }, [slug])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [activeStep, setActiveStep] = useState(0);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const metaslice = parse(metaDesc).slice(0,200)
    // console.log(metaslice)
    return (
        <>
            <MetaTags>

                <title>{news.title} | UR</title>
                <meta name="title" content={news.title} />
                <meta name="description" content={metaslice} />
                {/* facebook */}
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://ika-unpar.com" />
                <meta property="og:title" content={news.title} />
                <meta property="og:description" content={metaslice} />
                <meta property="og:image" content={news.image} />

                {/* twiter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ika-unpar.com" />
                <meta property="twitter:title" content={news.title} />
                <meta property="twitter:description" content={metaslice} />
                <meta property="twitter:image" content={news.image} />

            </MetaTags>

            <Container>
                <Box pt={5}>
                    <HideOnScroll {...props}>
                        <Grid item xs={12} md={2} align="left" >
                            <div className={classes.fabFix} style={{ marginTop: '40px' }}>
                                <h2>Share</h2>
                            </div>
                            <div className={classes.fabFix} style={{ marginTop: '100px' }}>
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={news.title}

                                >
                                    <FacebookIcon size={50} round />
                                </FacebookShareButton>
                            </div>

                            <div className={classes.fabFix} style={{ marginTop: '160px' }}>
                                <TwitterShareButton
                                    url={shareUrl2}
                                    title={news.title}


                                >
                                    <TwitterIcon size={50} round />
                                </TwitterShareButton>
                            </div>

                            <div className={classes.fabFix} style={{ marginTop: '220px' }}>
                                <WhatsappShareButton
                                    url={shareUrl}
                                    title={news.title}
                                    separator=": "

                                >
                                    <WhatsappIcon size={50} round />
                                </WhatsappShareButton>
                            </div>

                            <div className={classes.fabFix} style={{ marginTop: '280px' }}>
                                <LineShareButton
                                    url={shareUrl}
                                    title={news.title}

                                >
                                    <LineIcon size={50} round />
                                </LineShareButton>
                            </div>

                            <Fab color="primary" className={classes.fabFix} style={{ marginTop: '340px' }}>
                                <CopyToClipboard text={shareUrl}>
                                    {/* <CopyToClipboard text={"sad"}> */}
                                    <IconButton

                                        onClick={handleClickOpen}
                                        size="small"

                                    >
                                        <FileCopyIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </CopyToClipboard>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Link sudah di copy...
                     </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            ok
                    </Button>
                                    </DialogActions>
                                </Dialog>
                            </Fab>
                        </Grid>
                    </HideOnScroll>

                    <Grid container justify="center" className={classes.box} spacing={1} style={{marginTop:"6vh"}}>
                        <Grid item xs={12} md={8} align="center">
                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                style={{ zIndex: -1 }}>
                                {
                                    Object.entries(images).map((data) => {
                                        return <img src={data[1]} width="80%" alt="ur" />

                                    })

                                }
                            </AutoPlaySwipeableViews>
                        </Grid>

                        <Grid item xs={12} md={8} align="left">

                            <Typography variant="h3" fontWeight="fontWeightBold" gutterBottom>
                                {news.title ?? 'This'}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={8} align="left">
                            <Typography variant="caption" display="inline" color="textSecondary" gutterBottom >
                                <hr />
                                {dayjs(news.jam).format('LLL') ?? 'Loading...'}
                                  ({dayjs(news.jam).fromNow() ?? 'Loading...'})
                                <hr />
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={8} align="left">
                            <Typography variant="subtitle1" fontWeight="400" color="textPrimary" gutterBottom >
                                {/* {news.jam} */}
                                {ReactHtmlParser(news.content ?? 'Loading..')}
                            </Typography>
                            <div>
                                <h2>Share</h2>

                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={news.title}
                                    style={{ marginRight: '20px' }}
                                >
                                    <FacebookIcon size={30} />
                                </FacebookShareButton>



                                <TwitterShareButton
                                    url={shareUrl2}
                                    title={news.title}
                                    style={{ marginRight: '20px' }}

                                >
                                    <TwitterIcon size={30} />
                                </TwitterShareButton>



                                <WhatsappShareButton
                                    url={shareUrl}
                                    title={news.title}
                                    separator=": "
                                    style={{ marginRight: '20px' }}
                                >
                                    <WhatsappIcon size={30} />
                                </WhatsappShareButton>


                                <LineShareButton
                                    url={shareUrl}
                                    title={news.title}
                                    style={{ marginRight: '20px' }}

                                >
                                    <LineIcon size={30} />
                                </LineShareButton>


                            </div>
                        </Grid>

                    </Grid>

                </Box>
            </Container>
        </>
    )
}




