import { Box, Button, Card, CardContent, Grid, Snackbar, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { applyDeleteEmployee, applyDeleteUser, getEmployeeByToken, getUserByToken } from "../../../action/account";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Alert } from "@material-ui/lab";

export default function ConfirmDelete() {
  const params = useParams();
  const [partnerId] = useState(params.partnerId ?? 0)
  const [token] = useState(params.token ?? '')

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const alertDuration = 5000

  function handleSubmit() {
    if (partnerId == 0) {
      applyDeleteUser(token).then(response => {
        setOpenAlert(true)
        setAlertMessage(response.msg)
        setAlertSeverity(response.success == 1 ? 'success' : 'error')
      })
    } else {
      applyDeleteEmployee(token, partnerId).then(response => {
        setOpenAlert(true)
        setAlertMessage(response.msg)
        setAlertSeverity(response.success == 1 ? 'success' : 'error')
      })
    }
  }

  function handleCloseAlert() {
    setOpenAlert(false)
  }

  return (
    <div>
      <Snackbar open={openAlert} autoHideDuration={alertDuration} onClose={handleCloseAlert}>
        <Alert severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box mt={10} alignContent={'center'}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{
            height: '70vh'
          }}
        >
          <Grid
            item
          >
            <Card
              variant="outlined"
              style={{
                minWidth: 300,
                margin: 15
              }}
            >
              <CardContent>
                <Typography gutterBottom>
                  {`Hapus Akun ini?`}
                </Typography>
                { partnerId == 0 ? 
                    <UserDetail token={token}/>
                  :
                    <EmployeeDetail token={token} partnerId={partnerId}/>
                }
                <form>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmit}
                  >
                    <Typography>
                      {`Hapus Akun`}
                    </Typography>
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

function UserDetail({ token }) {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  function fetchUser(token) {
    getUserByToken(token).then(response => {
      const user = response.user
      setEmail(user.email)
      setPhone(user.phone)
    })
  }

  useEffect(() => {
    fetchUser(token)
  })

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} className="mb-2">
        <Typography>
          {`Nomor HP :`}
        </Typography>
        <Typography>
          {phone}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} className="mb-2">
        <Typography>
          {`Email :`}
        </Typography>
        <Typography>
          {email}
        </Typography>
      </Box>
    </Box>
  )
}

function EmployeeDetail({token, partnerId}){
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [partnerName, setPartnerName] = useState();

  function fetchEmployee(token, partnerId) {
    getEmployeeByToken(token, partnerId).then(response => {
      console.log(response)
      setEmail(response.user?.email)
      setPartnerName(response.user?.partnerName)
      setName(response.user?.name)
    })
  }

  useEffect(() => {
    fetchEmployee(token, partnerId)
  }, [])

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} className="mb-2">
        <Typography>
          {`Nama :`}
        </Typography>
        <Typography>
          {name}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} className="mb-2">
        <Typography>
          {`Partner :`}
        </Typography>
        <Typography>
          {partnerName}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} className="mb-2">
        <Typography>
          {`Email :`}
        </Typography>
        <Typography>
          {email}
        </Typography>
      </Box>
    </Box>
  )
}