import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import FooterGeneral from './FooterComponent/FooterGeneral'
// import FooterMobile from './FooterMobile'
import useMediaQuery from '@material-ui/core/useMediaQuery'





function Footer() {

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md', 'lg'), {
    defaultMatches: true
  })

  // if (isDesktop === true) {
    return <FooterGeneral />
  // } else {
    // console.log("mobile")
    // return <FooterMobile />
  // }


}

export default Footer