import Axios from 'axios';
const API_KEY = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL

export const deleteUser = async (email) => {

  const result = await Axios.post(
    API_KEY + 'v2/account/Delete.php', {email});

  return result.data;
};

export const deleteEmployee = async (email, partnerID) => {

  const result = await Axios.post(
    API_KEY + 'v2/account/DeleteEmployee.php', {email, partnerID});

  return result.data;
};

export const applyDeleteUser = async (token) => {

  const result = await Axios.post(
    API_KEY + 'v2/account/ApplyDeleteUser.php', {token});

  return result.data;
};

export const applyDeleteEmployee = async (token, partnerID) => {

  const result = await Axios.post(
    API_KEY + 'v2/account/ApplyDeleteEmployee.php', {token, partnerID});

  return result.data;
};

export const getUserByToken = async (token) => {
  const result = await Axios.get(
    API_KEY + 'v2/account/GetFromToken.php?token=' + token);
    
  return result.data;
};

export const getEmployeeByToken = async (token, partnerID) => {
  const result = await Axios.get(
    API_KEY + `v2/account/GetEmployeeFromToken.php?token=${token}&partnerId=${partnerID}`);
    
  return result.data;
};