import Axios from 'axios';
const API_KEY = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL

export const postEmailToAdmin = async (formData) => {

  const result = await Axios.post(API_KEY + `v2/contact/sendEmailToSupport.php`, formData);

  return result.data;
};

export const sendEmailToSender = async (formData) => {

  const result = await Axios.post(API_KEY + `v2/contact/sendEmailToSender.php`, formData);

  return result.data;
};

export const postApplicantToAdmin = async (formData) => {

  const result = await Axios.post(API_KEY + `v2/career/applicant/createSend.php`, formData);

  return result.data;
};

export const sendEmailApplicant = async (formData) => {

  const result = await Axios.post(API_KEY + `v2/career/applicant/sendToAdmin.php`, formData);

  return result.data;
};

