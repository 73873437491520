import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { useForm } from "react-hook-form";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MetaTags from "react-meta-tags";
import Fade from "react-reveal/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { postEmailToAdmin, sendEmailToSender } from "../../action/mailing";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "150px",
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
      padding: "100px 0",
    },
  },
  link: {
    color: "black",
    textAlign: "left",
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "250px",
    zIndex: "1",
  },
}));

export default function Contact() {
  const classes = useStyles();
  const { register, handleSubmit, errors, clearError, setError } = useForm();
  const [active, setActive] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [openTime, setOpenTime] = React.useState(false);
  const [namaActive, setNamaActive] = useState(true);
  const [emailActive, setEmailActive] = useState(true);
  const [notesActive, setNotesActive] = useState(true);
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleChangeNama = (event) => {
    const value = event.target.value;

    if (value) {
      clearError("name_sender");
      setNamaActive(false);

      handleEnableButton(false, emailActive, notesActive);
    } else {
      setError("name_sender", "required", "this field can be empty");
      setNamaActive(true);
      handleEnableButton(true, emailActive, notesActive);
    }
  };

  const handleChangeEmail = (event) => {
    const value = event.target.value;
    // console.log(value)
    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      clearError("email");
      setEmailActive(false);

      handleEnableButton(namaActive, false, notesActive);
    } else {
      setError("email", "required", "Invalid email format");
      setEmailActive(true);
      handleEnableButton(namaActive, true, notesActive);
    }
  };

  const handleChangeNotes = (event) => {
    const value = event.target.value;
    // console.log(value)
    if (value) {
      clearError("pesan");
      setNotesActive(false);

      handleEnableButton(namaActive, emailActive, false);
    } else {
      // console.log("else")
      setError("pesan", "required", "this field can be empty");
      setNotesActive(true);
      handleEnableButton(namaActive, emailActive, true);
    }
  };

  const handleEnableButton = (namaActive, emailActive, notesActive) => {
    if (
      namaActive === false &&
      emailActive === false &&
      notesActive === false
    ) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  const onSubmit = (data, e) => {
    var formData = new FormData();
    formData.append("name", data.name_sender);
    formData.append("email", data.email);
    formData.append("message", data.pesan);
    setOpenTime(true);
    postEmailToAdmin(formData).then((result) => {
      if (result.success === 1) {
        // console.log(result)
        setOpenTime(false);

        sendEmailToSender(formData).then((result) => {
          // console.log(result)

          setOpen(true);
          setEmailActive(true);
          setNamaActive(true);
          setNotesActive(true);
        });
      } else {
        alert(result.msg);
      }
    });

    e.target.reset();
    setActive(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseTime = () => {
    setOpenTime(false);
  };

  return (
    <div>
      <MetaTags>
        <title>Hubungi Sales UR Partner Self Order & POS System</title>
        <meta name="title" content="Hubungi Kami" />
        <meta name="description" content="Hubungi Kami , Kami siap membantu." />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Hubungi Kami" />
        <meta
          property="og:description"
          content="Hubungi Kami , Kami siap membantu."
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Hubungi Kami" />
        <meta
          property="twitter:description"
          content="Hubungi Kami , Kami siap membantu."
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box
        className={classes.hero}
        style={{
          marginBottom: isDesktop ? "150px" : "50px",
          // margiTop:
        }}
      >
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5", marginTop: isDesktop ? "0" : "15%" }}
              >
                Apa yang bisa kami bantu?
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/contactUs.png"
                  alt="icon kontak ur"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Fade>
        <Box mt={10}>
          <Container>
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={5}
              className={classes.box}
            >
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  marginBottom: 15,
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    inputRef={register({ required: "This is required" })}
                    onChange={handleChangeNama}
                    label="Nama"
                    name="name_sender"
                  />
                  {errors.name_sender && (
                    <small className="text-danger">
                      {errors.name_sender.message}
                    </small>
                  )}
                  <TextField
                    variant="outlined"
                    fullWidth
                    inputRef={register({ required: "This is required" })}
                    onChange={handleChangeEmail}
                    label="E-Mail"
                    name="email"
                    margin="normal"
                  />
                  {errors.email && (
                    <small className="text-danger">
                      {errors.email.message}
                    </small>
                  )}
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    inputRef={register({ required: "This is required" })}
                    onChange={handleChangeNotes}
                    label="Pesan"
                    name="pesan"
                    multiline
                    rows={4}
                  />
                  {errors.pesan && (
                    <small className="text-danger">
                      {errors.pesan.message}
                    </small>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // style={{ color: "#9c9789" }}
                    disabled={active}
                    style={{
                      borderRadius: 25,
                      marginBottom: 20,
                    }}
                  >
                    <div style={{ color: "white", padding: "5px" }}>Kirim</div>
                  </Button>
                </form>
              </Grid>
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom color="primary">
                  Hubungi Kami
                </Typography>
                <Typography gutterBottom>
                  Kami akan langsung membalas pesan anda maksimal dalam 24 jam.
                </Typography>
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/6289650077771"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={classes.link}
                  >
                    <CallIcon
                      className={classes.link}
                      fontSize="small"
                      style={{ marginBottom: -5 }}
                    />{" "}
                    089-6500-7777-1
                  </Typography>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=support@ur-hub.com&su=Pertanyaan%20Customer&body=Saya%20ingin%20bertanya&tf=1"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={classes.link}
                  >
                    <EmailIcon
                      className={classes.link}
                      fontSize="small"
                      style={{ marginBottom: -5 }}
                    />{" "}
                    support@ur-hub.com
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Fade>
      <Dialog
        open={openTime}
        onClose={handleCloseTime}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Mohon menunggu Pesan sedang di kirim...
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Terima kasih telah mengirim pesan kepada kami. Kami akan menghubung
            lewat e-mail.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
